import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../service/alert.service';
import { DialogComponent } from '../../service/dialog';
import { subscriberService } from '../../service/subscriber.service';

@Component({
  selector: 'app-invite-subscriber',
  templateUrl: './invite-subscriber.component.html',
  styleUrls: ['./invite-subscriber.component.scss'],
})
export class InviteSubscriberComponent implements OnInit {
  dialogRef: DialogComponent;
  data: any;
  subscriberForm: any;
  title: string = 'Add Subscriber';

  constructor(
    private viewContainer: ViewContainerRef,
    private subscriberService: subscriberService,
    private alertService: AlertService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.data = this.dialogRef.context?.data;
    this.title = this.dialogRef.context?.title;
    this.inviteSubscriberForm();
  }

  /**
   * @description Initiate invite subscriber form
   */
  inviteSubscriberForm() {
    this.subscriberForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      customerId: new UntypedFormControl(localStorage.getItem('customerId')),
    });
  }

  /**
   * @description Invite subscriber
   */
  submitForm() {
    this.subscriberService.inviteSubscriber(this.subscriberForm.value).subscribe({
      next: (res: any) => {
        this.dialogRef.close.emit(res);
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
    });
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit(this.subscriberForm.value);
  }
}
