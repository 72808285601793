import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appTooltipIfTruncated]',
})
export class TooltipIfTruncatedDirective implements AfterViewInit {
  @Input('appTooltipIfTruncated') tooltipObj!: any;
  @Input() placement!: string;
  @Input() delay!: string;
  @Input() offset: string = '0';
  @Input() svgPath!: string;
  tooltip!: HTMLElement | undefined;
  tooltipTitle!: string;
  isTruncated: boolean = false;
  private resizeTimeout!: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.checkTruncation();
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip && this.isTruncated) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  @HostListener('click') onClick() {
    if (this.tooltip?.innerText === 'Copy') {
      this.tooltip.innerText = 'Copied';
    }
    if (this.tooltip && this.tooltip?.innerText !== 'Copied') {
      this.hide();
    }
  }

  // Debounced resize handling
  @HostListener('window:resize')
  onResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.checkTruncation();
    }, 200);
  }

  private isTextTruncated(): boolean {
    const element = this.el.nativeElement.querySelector('span') || this.el.nativeElement;
    return element.scrollWidth > element.clientWidth;
  }

  private checkTruncation() {
    this.isTruncated = this.isTextTruncated();
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
    if (this.tooltipObj?.isCustomerSelected === false) {
      this.hide();
    }
  }

  hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    const self = this;
    window.setTimeout(() => {
      self.renderer.removeChild(document.body, self.tooltip);
      self.tooltip = undefined;
    }, parseInt(self.delay));
  }

  create() {
    this.tooltip = this.renderer.createElement('span');

    if (this.svgPath) {
      const img = this.renderer.createElement('img');
      img.setAttribute('src', this.svgPath);
      this.renderer.appendChild(this.tooltip, img);
      this.renderer.addClass(this.tooltip, 'ng-tooltip-light');
    }

    const tooltipTitlesMap: any = {
      androidApp: 'Android App',
      api: 'API',
      iosApp: 'iOS App',
      mobile: 'Mobile App',
      sdk: 'SDK',
      shopifyApp: 'Shopify Application',
      trs: 'TRS',
      webApp: 'Web Application',
    };

    this.tooltipTitle =
      tooltipTitlesMap[this.tooltipObj] || this.tooltipObj?.title || this.tooltipObj;

    this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipTitle));

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip?.getBoundingClientRect();
    const scrollPos =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;

    if (this.placement === 'top' && tooltipPos) {
      top = hostPos.top - tooltipPos.height - parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    } else if (this.placement === 'bottom' && tooltipPos) {
      top = hostPos.bottom + parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    } else if (this.placement === 'left' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - parseInt(this.offset);
    } else if (this.placement === 'right' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + parseInt(this.offset);
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
