import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RegionsService } from '../../service';

@Component({
  selector: 'app-mobile-number-input',
  templateUrl: './mobile-number-input.component.html',
  styleUrls: ['./mobile-number-input.component.scss'],
})
export class MobileNumberInputComponent implements OnInit, OnChanges {
  countryList: Array<any> = [];

  country: FormControl = new FormControl('');
  phoneNumber: FormControl = new FormControl('');
  countryDialCode: string = '+91';
  @Input() inputControl!: any;
  @Input() disabled: boolean = false;
  @Input() isDetailsUpdated!: boolean;

  constructor(private regionService: RegionsService) {}

  /**
   * On change enable disable mode
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('disabled') && changes['disabled']?.currentValue) {
      this.country.disable();
      this.phoneNumber.disable();
    } else {
      this.country.enable();
      this.phoneNumber.enable();
    }
  }

  ngOnInit(): void {
    // Get country list
    this.regionService.getCountries().subscribe((result: any) => {
      if (result?.data?.length > 0) {
        this.countryList = result.data;
      }
    });

    this.inputControl?.valueChanges.subscribe((value: string) => {
      this.setPhoneNumber(value);
    });
    this.setPhoneNumber(this.inputControl?.value);
  }

  /**
   * Split phone number into country code and phone number to save it in db
   * @param value
   */
  setPhoneNumber(value: string) {
    if (value && value?.trim() != '') {
      const phoneNumber = Array.isArray(value)
        ? value[0]?.trim()?.split(' ')
        : value?.trim()?.split(' ');
      if (phoneNumber.length == 2) {
        this.countryDialCode = phoneNumber[0];
        this.phoneNumber.setValue(phoneNumber[1]);
      } else {
        this.phoneNumber.setValue(value);
      }
    }
  }

  /**
   * return selected country index
   *
   * @param index
   * @param item
   * @returns
   */
  trackCountries(index: any, item: any) {
    return index;
  }

  /**
   * Set country code
   * @param country
   */
  setDialCode(country: any) {
    this.countryDialCode = country.dial_code;
    if (this.phoneNumber?.value && this.phoneNumber?.value?.trim() != '') {
      this.inputControl.setValue(`${this.countryDialCode} ${this.phoneNumber.value}`);
    }
  }

  /**
   * On change value events called
   *
   * @param event
   */
  handleEvent(event: any) {
    this.inputControl.markAsTouched();
    if (this.phoneNumber?.value && this.phoneNumber?.value?.trim() != '') {
      this.inputControl.setValue(`${this.countryDialCode} ${this.phoneNumber.value}`);
    } else {
      this.inputControl.setValue('');
    }

    if (this.phoneNumber.dirty) {
      this.inputControl.markAsDirty();
    }
  }
}
