import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EncryptDescryptService } from './encrypt-descrypt.service';

const TOKEN = 'authToken';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  clientConfig$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _router: Router,
    private encryptDecryptService: EncryptDescryptService
  ) {}

  setConfigObservable(config: any) {
    this.clientConfig$.next(config);
  }

  getConfigObservable() {
    return this.clientConfig$.asObservable();
  }

  getToken() {
    return localStorage.getItem(TOKEN);
  }

  setToken(token: string) {
    localStorage.setItem(TOKEN, token);
  }

  removeToken() {
    localStorage.removeItem(TOKEN);
    this._router.navigate(['/signin']);
  }

  setTheme(isDarkTheme: boolean) {
    localStorage.setItem('screenMode', isDarkTheme ? 'dark' : 'light');
  }

  getTheme() {
    return localStorage.getItem('screenMode');
  }

  setCurrency(value: string) {
    localStorage.setItem('currency', value);
  }

  getCurrency() {
    return localStorage.getItem('currency');
  }

  setCacheConfig(value: string) {
    localStorage.setItem('config', value);
  }

  getCacheConfig() {
    let config = localStorage.getItem('config')!;
    let decryptedConfig;
    
    config = JSON.parse(config);

    if (config && typeof config != 'object') {
      decryptedConfig = this.encryptDecryptService.decryptData(config);
      return decryptedConfig;
    }
    return undefined;
  }

  setRegion(value: string) {
    localStorage.setItem('region', value);
  }

  getRegion() {
    return localStorage.getItem('region');
  }

  removeCacheConfig() {
    return localStorage.removeItem('config');
  }

  setCacheId(value: string) {
    localStorage.setItem('cacheId', value);
  }

  getCacheId() {
    return localStorage.getItem('cacheId');
  }

  setSelectedParent(value: string) {
    localStorage.setItem('selectedParent', value);
  }

  getSelectedParent() {
    return localStorage.getItem('selectedParent');
  }

  clearStorage() {
    localStorage.clear();
    this._router.navigate(['/signin']);
  }

  setSelectedCustomer(value: string) {
    localStorage.setItem('isCustomerSelected', value);
  }

  getSelectedCustomer() {
    return localStorage.getItem('isCustomerSelected');
  }

  setCustomerTree(value: any) {
    localStorage.setItem('customerTree', value);
  }

  getCustomerTree() {
    return localStorage.getItem('customerTree');
  }

  clearCustomerTree() {
    localStorage.removeItem('customerTree');
  }

  setSelectedCustomerTree(value: any) {
    localStorage.setItem('selectedCustomerTree', value);
  }

  getSelectedCustomerTree() {
    return localStorage.getItem('selectedCustomerTree');
  }

  clearSelectedCustomerTree() {
    localStorage.removeItem('selectedCustomerTree');
  }

  setCustomerParentId(value: any) {
    localStorage.setItem('customerParentId', value);
  }

  getCustomerParentId() {
    return localStorage.getItem('customerParentId');
  }

  clearCustomerParentId() {
    localStorage.removeItem('customerParentId');
  }
}
