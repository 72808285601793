<div class="es-modal info-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 d-flex">
        <img class="m-auto" src="/assets/icons/info.svg" alt="info-icon" />
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>
  <div>
    <p class="modal-header-text mb-0 d-flex align-items-start">
      <span class="max-w80 word-wrap text-overflow-ellipsis">
        <span
          class="max-w80 word-wrap subscriber-details-text text-overflow-ellipsis"
          *ngIf="planDetails?.name.length > 20"
          [tooltip]="planDetails?.name"
          placement="top"
          delay="100"
          offset="0"
        >
          {{ planDetails?.name }}
        </span>
        <span
          class="max-w80 word-wrap subscriber-details-text"
          *ngIf="planDetails?.name.length <= 20"
        >
          {{ planDetails?.name }}
        </span> </span
      >&nbsp;-&nbsp;<span>{{
        planDetails?.priceBundle | currency: planDetails?.currency || currencyType
      }}</span>
    </p>
  </div>
  <div class="es-modal-body mb-5">
    <div class="row mt-4">
      <div class="col-4">
        <label class="modal-label-text">Total Data</label>
        <div class="modal-details-text">{{ planDetails?.data }}</div>
      </div>
      <div class="col-4">
        <label class="modal-label-text">Countries</label>
        <div class="modal-details-text" *ngIf="countryList && countryList.length > 0">
          <span>{{ countryList[0]?.name | titlecase }}</span>
          <span *ngIf="countryList.length > 1">, </span>
          <div
            *ngIf="countryList.length > 1"
            class="more-countries"
            [tooltip]="displayContryList()"
            placement="top"
            delay="100"
            offset="5"
          >
            &plus;{{ countryList.length - 1 }} more
          </div>
        </div>
      </div>
      <div class="col-4">
        <label class="modal-label-text">Validity</label>
        <div class="modal-details-text">{{ planDetails?.validity }} Days</div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <label class="modal-label-text">Plan Label</label>
        <div class="modal-details-text">{{ planDetails?.productCategory }}</div>
      </div>
      <div class="col-4">
        <label class="modal-label-text">IMSI Type</label>
        <div class="modal-details-text">{{ planDetails?.preferredImsiId }}</div>
      </div>
      <div class="col-4">
        <label class="modal-label-text">Activation Type</label>
        <div class="modal-details-text">{{ planDetails?.activationType }}</div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <label class="modal-label-text">Region</label>
        <div class="modal-details-text">{{ planDetails?.region ?? 'N/A' }}</div>
      </div>
      <div class="col-4">
        <label class="modal-label-text">Earliest Activation</label>
        <div class="modal-details-text">
          {{ planDetails?.dateEarliestActivation | date: 'dd-MM-yyyy' }}
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <label class="modal-label-text">Earliest Available</label>
        <div class="modal-details-text">
          {{ planDetails?.dateEarliestAvailable | date: 'dd-MM-yyyy' }}
        </div>
      </div>
      <div class="col-4">
        <label class="modal-label-text">Latest Available</label>
        <div class="modal-details-text">
          {{ planDetails?.dateLatestAvailable | date: 'dd-MM-yyyy' }}
        </div>
      </div>
    </div>
  </div>

  <div class="es-modal-footer mt-4">
    <button
      [ngClass]="planDetails?.isActive ? 'btn-secondary' : 'btn-primary'"
      class="btn btn-full-width"
      (click)="updatePlanStatus(planDetails)"
    >
      {{ planDetails?.isActive ? 'Disable Plan' : 'Enable Plan' }}
    </button>
  </div>
</div>
