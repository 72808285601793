import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const requestTypeMessages: { [key: string]: string } = {
  CUSTOMER_ENABLE: 'Customer Enabled Successfully',
  CUSTOMER_DISABLE: 'Customer Disabled Successfully',
  CUSTOMER_PRODUCT_ENABLE: 'Product Enabled Successfully',
  CUSTOMER_PRODUCT_DISABLE: 'Product Disabled Successfully',
  CUSTOMER_FEATURE_ENABLE: 'Feature Enabled Successfully',
  CUSTOMER_FEATURE_DISABLE: 'Feature Disabled Successfully',
  AGENT: 'Agent Invited Successfully',
  USER_CREATED: 'User Created',
  USER_UPDATED: 'User Updated',
  USER_DELETED: 'User Deleted',
  USER_INVITE: 'User Invited Successfully',
  CUSTOMER_CREATED: 'New Customer is Created',
  CUSTOMER_UPDATED: 'Customer Updated',
  CUSTOMER_UPDATE: 'Customer Edited',
  CUSTOMER_DISABLED: 'Customer Disabled',
  CUSTOMER_ENABLED: 'Customer Enabled',
  CUSTOMER_PRODUCT_ENABLED: 'Product Enabled',
  CUSTOMER_PRODUCT_DISABLED: 'Product Disabled',
  CUSTOMER_FEATURE_ENABLED: 'Features Enabled',
  CUSTOMER_FEATURE_DISABLED: 'Features Disabled',
  USER_SETTING_CHANGES: 'Saved Changes!',
  USER_PASSWORD_UPDATE: 'Password Updated!',
  TRS_AGENT_ENABLE: 'Agent Enabled Successfully',
  TRS_AGENT_DISABLE: 'Agent Disabled Successfully',
  EMAIL_FORWARDING: 'Email Forwarding',
  CURRENCY: 'Currency',
  PLAN_REMINDER: 'Plan Reminder',
  INVENTORY_USAGE_REMINDER: 'Inventory Usage Reminder',
  INVENTORY_UPDATED: 'Inventory Updated',
  LANGUAGE: 'Language',
};

@Pipe({
  name: 'keyToDisplay',
})
export class KeyToDisplayPipe implements PipeTransform {
  transform(key: any): string {
    switch (key) {
      case 'total_profile_sale':
        return 'Total Profiles Sales';
      case 'total_subscriber':
        return 'Total Subscribers';
      case 'total_active_customer':
        return 'Active Customers';
      case 'total_sales_of_trs':
        return 'Sales of TRS';
      case 'total_sales_of_webapp':
        return 'Sales of Web App';
      case 'total_sales_of_mobileapp':
        return 'Sales of Mobile App';
      default:
        return '';
    }
  }
}

@Pipe({
  name: 'currencyByMatchingkey',
})
export class CurrencyByMatchingkeyPipe implements PipeTransform {
  transform(value: any, obj: any): any {
    if (!obj?.('value')) {
      return obj;
    }

    if (obj?.('value')) {
      return `${getCurrencySymbol(obj.currency, 'wide')} ${obj.value.toFixed(2)}`;
    }
  }
}

@Pipe({
  name: 'messageByRequestType',
})
export class MessageByRequestTypekeyPipe implements PipeTransform {
  transform(requestType: any): any {
    return requestTypeMessages[requestType] || requestType;
  }
}

@Pipe({
  name: 'productNameByKey',
})
export class ProductNameByKeyPipe implements PipeTransform {
  transform(productKey: any): any {
    // Mapping tooltip titles for Products SVG elements
    const titleMap: any = {
      androidApp: 'Android Application',
      api: 'API',
      iosApp: 'iOS Application',
      mobile: 'Mobile Application',
      sdk: 'SDK',
      shopifyApp: 'Shopify Application',
      trs: 'TRS',
      webApp: 'Web Application',
    };

    return titleMap[productKey];
  }
}
