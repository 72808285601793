<div class="not-found-parent-section dark-content">
  <div class="auth-main-body">
    <div class="row">
      <div class="col-lg-12 pe-0">
        <div class="auth-left-section">
          <div class="d-flex mb-4">
            <img class="img-fluid main-logo z-index-2" src="/assets/icons/logo.svg" alt="" />
          </div>
          <div class="my-4">
            <div class="text-center">
              <div class="d-flex mb-4 justify-content-center">
                <img class="img-fluid z-index-2" src="/assets/icons/404.svg" alt="" />
              </div>
              <h3 class="welcome-heading mb-0 z-index-2 text-center">
                Don’t restart your WiFi just yet!
              </h3>
              <h3 class="welcome-heading mb-0 z-index-2 text-center">
                Your internet is working fine, the page does not exist.
              </h3>
              <div class="z-index-2">
                <button type="button" class="btn back-button z-index-2" (click)="goBack()">
                  Go Back
                </button>
              </div>
            </div>
            <div class="ripple-position d-none-tab">
              <div>
                <div class="circles-parent">
                  <div class="solid-circle solid-circle-grey"></div>
                  <div class="circle3 common-404-circle common-circle-grey common-circle-1"></div>
                  <div class="circle2 common-404-circle common-circle-grey common-circle-1"></div>
                  <div class="circle1 common-404-circle common-circle-grey common-circle-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
