import { LocationStrategy } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureHeader } from '../../models/feature-header.model';
import { AlertService, DialogService, LocalStorageService } from '../../service';
import { TitleService } from '../../service/title/title.service';

@Component({
  selector: 'app-feature-header',
  templateUrl: './feature-header.component.html',
  styleUrls: ['./feature-header.component.scss'],
})
export class FeatureHeaderComponent implements OnInit {
  @Input() type!: string;
  @Input() customerId!: string;
  @Input() btnTitle!: string;
  @Input() dialogData!: any;
  @Input() customerDetails: any;
  @Input() userDetails: any;
  @Input() selectedCustomer: any;
  @Input() isSuperAdmin!: boolean;
  @Input() showAddButton!: boolean;
  @Input() isDefault!: boolean;
  @Input() refreshPlansMasterEnabled!: boolean;
  @Output() responseEmit: EventEmitter<any> = new EventEmitter();
  @Output() refreshPlans = new EventEmitter<void>();
  featureHeader!: FeatureHeader;
  STATE_KEY_CUSTOMER_ID = makeStateKey('customerId');

  constructor(
    private titleService: TitleService,
    private dialogService: DialogService,
    private router: Router,
    private alertService: AlertService,
    private location: LocationStrategy,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private state: TransferState
  ) {}

  ngOnInit(): void {
    this.featureHeader = this.titleService.getFeatureHeader(this.type);

    if (this.featureHeader) {
      this.featureHeader.featureButtonText = this.btnTitle;
    }
  }

  /**
   * Common Add Button
   *
   * @param name
   */
  onAddButtonClick(name: string) {
    if (name == 'customer') {
      const queryParamsPid = this.route.snapshot.queryParams['pid'];
      const customerIdFromLocalStorage = this.localStorage.getSelectedCustomer();

      if (customerIdFromLocalStorage && customerIdFromLocalStorage != 'false' && !queryParamsPid) {
        this.state.set(this.STATE_KEY_CUSTOMER_ID, <any>customerIdFromLocalStorage);
      } else if (
        customerIdFromLocalStorage &&
        customerIdFromLocalStorage != 'false' &&
        queryParamsPid
      ) {
        this.state.set(this.STATE_KEY_CUSTOMER_ID, <any>JSON.stringify(queryParamsPid));
      } else {
        this.state.remove(this.STATE_KEY_CUSTOMER_ID);
      }
      this.router.navigate(['add'], { relativeTo: this.route });
    } else {
      this.dialogService
        .openModal(this.dialogData.component, this.dialogData.dialogConfig)
        .instance.close.subscribe({
          next: (data: any) => {
            if (data) {
              this.responseEmit.emit(data);
            }
          },
          error: (err: any) => {
            this.alertService.error(err.error.message, err.status);
          },
        });
    }
  }

  /**
   * Loyalty Enable/Disable button
   */
  changeMode() {
    this.isDefault = !this.isDefault;
    this.responseEmit.emit(this.isDefault);
  }

  /**
   * Go back to Previous Page: All Agent Back Button
   */
  goToProducts() {
    this.location.back();
  }

  onRefreshButtonClick() {
    this.refreshPlans.emit();
  }
}
