import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILoyaltyPointsWidgetResponse } from 'src/app/modules/loyalty-point-program/models/loyalty-points-widget-response.model';
import { environment } from 'src/environments/environment';
import { ClientConfig } from '../models/client-config.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * PUT Method: Loyalty points configuration
   * @param loyaltyData
   */
  loyaltyPoints(loyaltyData: ClientConfig) {
    return this.http.put(`${this.serverUrl}/customers/setting/configuration`, loyaltyData);
  }

  /**
   * GET Method: Loyalty Points report
   */
  getLoyaltyWidgets() {
    return this.http.get<ILoyaltyPointsWidgetResponse>(`${this.serverUrl}/loyalty-points/report`);
  }
}
