<div class="es-modal add-edit-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 confirmation-icon" [ngClass]="{ 'trash-icon': type === 'delete-success' }">
        <svg
          *ngIf="title === 'Error' || title === 'Something went wrong!'"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
          <mask
            id="mask0_2349_15915"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="16"
            y="16"
            width="24"
            height="24"
          >
            <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2349_15915)">
            <path
              d="M17 37L28 18L39 37H17ZM20.45 35H35.55L28 22L20.45 35ZM28 34C28.2833 34 28.5208 33.9042 28.7125 33.7125C28.9042 33.5208 29 33.2833 29 33C29 32.7167 28.9042 32.4792 28.7125 32.2875C28.5208 32.0958 28.2833 32 28 32C27.7167 32 27.4792 32.0958 27.2875 32.2875C27.0958 32.4792 27 32.7167 27 33C27 33.2833 27.0958 33.5208 27.2875 33.7125C27.4792 33.9042 27.7167 34 28 34ZM27 31H29V26H27V31Z"
              fill="#D92D20"
            />
          </g>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8" />
        </svg>

        <svg
          *ngIf="title === 'Successful!'"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#9FFFD1" />
          <mask
            id="mask0_2349_15930"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="16"
            y="16"
            width="24"
            height="24"
          >
            <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2349_15930)">
            <path
              d="M25.5496 34.0001L19.8496 28.3001L21.2746 26.8751L25.5496 31.1501L34.7246 21.9751L36.1496 23.4001L25.5496 34.0001Z"
              fill="#20925B"
            />
          </g>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#2D9563" stroke-width="8" />
        </svg>

        <svg
          *ngIf="title === 'Info'"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#ADCEFF" />
          <mask
            id="mask0_2483_16745"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="16"
            y="16"
            width="24"
            height="24"
          >
            <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2483_16745)">
            <path
              d="M27 33H29V27H27V33ZM28 25C28.2833 25 28.5208 24.9042 28.7125 24.7125C28.9042 24.5208 29 24.2833 29 24C29 23.7167 28.9042 23.4792 28.7125 23.2875C28.5208 23.0958 28.2833 23 28 23C27.7167 23 27.4792 23.0958 27.2875 23.2875C27.0958 23.4792 27 23.7167 27 24C27 24.2833 27.0958 24.5208 27.2875 24.7125C27.4792 24.9042 27.7167 25 28 25ZM28 38C26.6167 38 25.3167 37.7375 24.1 37.2125C22.8833 36.6875 21.825 35.975 20.925 35.075C20.025 34.175 19.3125 33.1167 18.7875 31.9C18.2625 30.6833 18 29.3833 18 28C18 26.6167 18.2625 25.3167 18.7875 24.1C19.3125 22.8833 20.025 21.825 20.925 20.925C21.825 20.025 22.8833 19.3125 24.1 18.7875C25.3167 18.2625 26.6167 18 28 18C29.3833 18 30.6833 18.2625 31.9 18.7875C33.1167 19.3125 34.175 20.025 35.075 20.925C35.975 21.825 36.6875 22.8833 37.2125 24.1C37.7375 25.3167 38 26.6167 38 28C38 29.3833 37.7375 30.6833 37.2125 31.9C36.6875 33.1167 35.975 34.175 35.075 35.075C34.175 35.975 33.1167 36.6875 31.9 37.2125C30.6833 37.7375 29.3833 38 28 38ZM28 36C30.2333 36 32.125 35.225 33.675 33.675C35.225 32.125 36 30.2333 36 28C36 25.7667 35.225 23.875 33.675 22.325C32.125 20.775 30.2333 20 28 20C25.7667 20 23.875 20.775 22.325 22.325C20.775 23.875 20 25.7667 20 28C20 30.2333 20.775 32.125 22.325 33.675C23.875 35.225 25.7667 36 28 36Z"
              fill="#428EFF"
            />
          </g>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#428EFF" stroke-width="8" />
        </svg>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="type === 'delete-success'"
        >
          <rect class="rect1" x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
          <path
            d="M32 22V21.2C32 20.0799 32 19.5198 31.782 19.092C31.5903 18.7157 31.2843 18.4097 30.908 18.218C30.4802 18 29.9201 18 28.8 18H27.2C26.0799 18 25.5198 18 25.092 18.218C24.7157 18.4097 24.4097 18.7157 24.218 19.092C24 19.5198 24 20.0799 24 21.2V22M26 27.5V32.5M30 27.5V32.5M19 22H37M35 22V33.2C35 34.8802 35 35.7202 34.673 36.362C34.3854 36.9265 33.9265 37.3854 33.362 37.673C32.7202 38 31.8802 38 30.2 38H25.8C24.1198 38 23.2798 38 22.638 37.673C22.0735 37.3854 21.6146 36.9265 21.327 36.362C21 35.7202 21 34.8802 21 33.2V22"
            stroke="#D92D20"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            class="rect2"
            x="4"
            y="4"
            width="48"
            height="48"
            rx="24"
            stroke="#FEF3F2"
            stroke-width="8"
          />
        </svg>

        <svg
          *ngIf="title === 'Warning!'"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FFF2C6" />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FF9C00" stroke-width="8" />
          <mask
            id="mask0_3052_31113"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="18"
            y="18"
            width="20"
            height="20"
          >
            <rect x="18" y="18" width="20" height="20" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_3052_31113)">
            <path
              d="M28.0404 34.6663C26.1793 34.6663 24.5959 34.0205 23.2904 32.7288C21.9848 31.4372 21.332 29.8608 21.332 27.9997V27.8538L19.9987 29.1872L18.832 28.0205L22.1654 24.6872L25.4987 28.0205L24.332 29.1872L22.9987 27.8538V27.9997C22.9987 29.3886 23.4883 30.5691 24.4674 31.5413C25.4466 32.5136 26.6376 32.9997 28.0404 32.9997C28.4015 32.9997 28.7556 32.958 29.1029 32.8747C29.4501 32.7913 29.7904 32.6663 30.1237 32.4997L31.3737 33.7497C30.8459 34.0552 30.3043 34.2844 29.7487 34.4372C29.1931 34.59 28.6237 34.6663 28.0404 34.6663ZM33.832 31.3122L30.4987 27.9788L31.6654 26.8122L32.9987 28.1455V27.9997C32.9987 26.6108 32.5091 25.4302 31.5299 24.458C30.5508 23.4858 29.3598 22.9997 27.957 22.9997C27.5959 22.9997 27.2418 23.0413 26.8945 23.1247C26.5473 23.208 26.207 23.333 25.8737 23.4997L24.6237 22.2497C25.1515 21.9441 25.6931 21.715 26.2487 21.5622C26.8043 21.4094 27.3737 21.333 27.957 21.333C29.8181 21.333 31.4015 21.9788 32.707 23.2705C34.0126 24.5622 34.6654 26.1386 34.6654 27.9997V28.1455L35.9987 26.8122L37.1654 27.9788L33.832 31.3122Z"
              fill="#FF9C00"
            />
          </g>
        </svg>

        <svg
          *ngIf="title === 'Error! No Data!'"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FFF2C6" />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FF9C00" stroke-width="8" />
          <path
            d="M28 18L17 37H39M28 22L35.53 35H20.47M27 26V30H29V26M27 32V34H29V32"
            fill="#FF9C00"
          />
        </svg>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3" *ngIf="title !== 'Successful!'">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>

  <div *ngIf="!typeBasedArrays.includes(type)">
    <p class="modal-header-text mb-0">
      {{
        title === 'Info'
          ? statusCode === 401
            ? 'Session Expired!'
            : 'Alert'
          : customTitle
            ? customTitle
            : title
      }}
    </p>
    <p
      class="modal-sub-header-text mb-0 mt-2"
      [ngClass]="{ 'msg-color-dark': customTitle }"
      *ngIf="type !== 'CUSTOMER_ADDED'"
    >
      {{ body }}
    </p>
    <ng-container *ngIf="type === 'CUSTOMER_ADDED'">
      <p class="modal-sub-header-text mb-0 mt-2">
        You have successfully added a customer "{{ customObj.name }}"
        <span>with the following products</span>
      </p>
      <div class="row">
        <div class="col-6">
          <p class="modal-sub-header-text mb-0 mt-2 custom-product-text">Products</p>
        </div>
        <div class="col-6">
          <ng-container *ngIf="isAnyProductTrue(); else noProducts">
            <ng-container *ngFor="let product of customObj.products | keyvalue">
              <ng-container *ngIf="product.value">
                <p class="mb-0 mt-2 custom-product-text">
                  {{ product.key | productNameByKey }}
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #noProducts>
            <p class="mt-2 no-product">No products added</p>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="typeBasedArrays.includes(type)">
    <p class="modal-header-text mb-0">{{ type | messageByRequestType }}</p>
    <p class="modal-sub-header-text mb-0 mt-2">{{ body }}</p>
  </div>

  <div class="refresh-plan-parent" *ngIf="customTitle === 'Refreshing Plans'">
    <label class="upload-csv-label" for="uploadCsv">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 36 36"
        tooltip="Upload Excel Sheet"
        placement="top"
        delay="100"
        offset="15"
        fill="none"
        class="upload-svg cursor-pointer"
        ng-reflect-tooltip-title="Upload Excel Sheet"
        ng-reflect-placement="top"
        ng-reflect-delay="100"
        ng-reflect-offset="15"
      >
        <rect width="36" height="36" rx="18" fill="#6365EF" fill-opacity="0.3"></rect>
        <mask
          id="mask0_156_5739"
          maskUnits="userSpaceOnUse"
          x="6"
          y="6"
          width="24"
          height="24"
          style="mask-type: alpha"
        >
          <rect x="6" y="6" width="24" height="24" fill="#D9D9D9"></rect>
        </mask>
        <g mask="url(#mask0_156_5739)">
          <path
            d="M17 22V13.85L14.4 16.45L13 15L18 10L23 15L21.6 16.45L19 13.85V22H17ZM12 26C11.45 26 10.9792 25.8042 10.5875 25.4125C10.1958 25.0208 10 24.55 10 24V21H12V24H24V21H26V24C26 24.55 25.8042 25.0208 25.4125 25.4125C25.0208 25.8042 24.55 26 24 26H12Z"
            fill="#6365EF"
          ></path>
        </g>
      </svg>
      <span class="upload-csv-span">Upload CSV</span>
    </label>
    <input
      class="upload-csv-input"
      type="file"
      name=""
      #uploadCsv
      id="uploadCsv"
      (change)="onUploadCSV($event)"
    />
  </div>

  <div *ngIf="customTitle === 'Refreshing Plans'" class="modal-sub-header-text">
    Note: Upload CSV is optional.
  </div>

  <div
    class="es-modal-footer d-flex justify-content-between mt-5"
    *ngIf="
      (title === 'Error' || title === 'Something went wrong!' || type === 'Inventory') &&
        buttonGroup.length > 0;
      else showDefault
    "
  >
    <button
      type="button"
      *ngFor="let btn of buttonGroup"
      class="btn"
      [ngClass]="{
        'btn-danger': btn.title === 'Try Again',
        'btn-secondary': btn.title === 'Back' || btn.title === 'Continue',
        'btn-success': btn.title === 'Upload Another'
      }"
      (click)="btn.title === 'Upload Another' ? close('uploadAnother') : close()"
    >
      {{ btn.title }}
    </button>
  </div>

  <ng-template #showDefault>
    <div class="es-modal-footer">
      <button
        type="button"
        class="btn mt-4"
        [ngClass]="{
          'btn-success': title === 'Successful!' || title === 'Info',
          w100: title === 'Successful!' || title === 'Info' || title === 'Error! No Data!',
          'btn-danger w-100 cancel': type === 'delete-success',
          'btn-no-data': title === 'Error! No Data!',
          'warning-btn w-100': title === 'Warning!'
        }"
        (click)="customTitle === 'Refreshing Plans' ? submitRefreshPlan() : close()"
      >
        {{ customTitle === 'Refreshing Plans' ? 'Submit' : 'Close' }}
      </button>
    </div>
  </ng-template>
</div>
