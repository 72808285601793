import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventSpace]',
})
export class PreventSpaceDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space' || event.key === ' ') {
      event.preventDefault();
    }
  }
}
