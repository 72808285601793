<div class="es-modal add-edit-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 add-user-icon">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect class="rect1" x="4" y="4" width="48" height="48" rx="24" fill="#33A85B" />
          <mask
            id="mask0_2207_15956"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="16"
            y="16"
            width="24"
            height="24"
          >
            <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2207_15956)">
            <mask
              id="mask1_2207_15956"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="16"
              y="16"
              width="24"
              height="24"
            >
              <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask1_2207_15956)">
              <path
                d="M22 36C21.45 36 20.9792 35.8042 20.5875 35.4125C20.1958 35.0208 20 34.55 20 34V31H22V34H34V31H36V34C36 34.55 35.8042 35.0208 35.4125 35.4125C35.0208 35.8042 34.55 36 34 36H22ZM28 32L23 27L24.4 25.55L27 28.15V20H29V28.15L31.6 25.55L33 27L28 32Z"
                fill="#FDFDFD"
              />
            </g>
          </g>
          <rect
            class="rect2"
            x="4"
            y="4"
            width="48"
            height="48"
            rx="24"
            stroke="#A3FFD3"
            stroke-width="8"
          />
        </svg>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>

  <div>
    <p class="modal-header-text mb-0">{{ title }}</p>
    <p class="modal-sub-header-text mb-0 mt-2">{{ description }}</p>
  </div>
  <div class="es-modal-body mt-2">
    <div class="file-download-body" *ngFor="let file of files">
      <div class="file-icon-name">
        <div class="file-icon-border">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <mask
              id="mask0_2349_15854"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="18"
              height="18"
            >
              <rect width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2349_15854)">
              <path
                class="file-icon-fill"
                d="M3.75 14.25H14.25V7.36875L10.6313 3.75H3.75V14.25ZM3.75 15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39688 2.98438 2.69063 2.69063C2.98438 2.39688 3.3375 2.25 3.75 2.25H11.25L15.75 6.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H3.75ZM5.25 12.75H12.75V11.25H5.25V12.75ZM5.25 9.75H12.75V8.25H5.25V9.75ZM5.25 6.75H10.5V5.25H5.25V6.75Z"
                fill="#949494"
              />
            </g>
          </svg>
        </div>
        <div>{{ file.name }}</div>
      </div>

      <div class="download-file">
        <a [href]="file.path" target="_blank" rel="noopener noreferrer" [download]="file.name">
          <svg
            class="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_2207_16012"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="white" />
            </mask>
            <g mask="url(#mask0_2207_16012)">
              <path
                d="M6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6ZM12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16Z"
                fill="white"
              />
            </g>
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>
