import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * GET Method: Transaction Report with filter
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @returns
   */
  getDownloadReport(dateRangeValue?: string, fromDate?: any, toDate?: any) {
    // Check for @param dateRangeValue
    if (dateRangeValue) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/transaction/report?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        // return data from api response
        return this.http.get(`${this.serverUrl}/transaction/report?dateRange=${dateRangeValue}`);
      }
    } else {
      // return data from api response
      return this.http.get(`${this.serverUrl}/transaction/report?dateRange=year`);
    }
  }

  /**
   * GET Method: Transaction and Revenue Report over an email
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @param customerId
   * @returns
   */
  sendTransactionAndRevenueReportMail(
    dateRangeValue?: string,
    fromDate?: any,
    toDate?: any,
    customerId?: any
  ) {
    // Null check for dateRangeValue & customerId
    if (dateRangeValue && customerId) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/transaction/report/email?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/transaction/report/email?dateRange=${dateRangeValue}&customerId=${customerId}`
        );
      }
    } // else Null check for dateRangeValue
    else if (dateRangeValue) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/transaction/report/email?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/transaction/report/email?dateRange=${dateRangeValue}`
        );
      }
    } // else Null check for customerId
    else if (customerId) {
      // return data from api response
      return this.http.get(
        `${this.serverUrl}/transaction/report/email?dateRange=year&customerId=${customerId}`
      );
    } else {
      // return data from api response
      return this.http.get(`${this.serverUrl}/transaction/report/email?dateRange=year`);
    }
  }

  /**
   * GET Method: Data usage report with filter
   * @param fromDate
   * @param toDate
   * @param body
   * @param itemsPerPage
   * @param currentPage
   * @returns
   */
  getDataUsageReport(
    fromDate?: any,
    toDate?: any,
    body?: any,
    itemsPerPage?: any,
    currentPage?: any
  ) {
    // return data from api response
    return this.http.post(
      `${this.serverUrl}/data-Usage/report?fromDate=${fromDate}&toDate=${toDate}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`,
      body
    );
  }

  /**
   * POST Method: Download Data usage report with filter
   * @param fromDate
   * @param toDate
   * @param body
   * @param itemsPerPage
   * @param currentPage
   * @returns
   */
  downloadDataUsageReport(
    fromDate?: any,
    toDate?: any,
    body?: any,
    itemsPerPage?: any,
    currentPage?: any
  ) {
    // return data from api response
    return this.http.post(
      `${this.serverUrl}/data-Usage/download?fromDate=${fromDate}&toDate=${toDate}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`,
      body
    );
  }

  /**
   * GET Method: Country and Plan List for dropdown
   * @returns
   */
  getCountryAndPlanList() {
    // return data from api response
    return [
      this.http.get(`${this.serverUrl}/subscription/countries`),
      this.http.get(`${this.serverUrl}/subscription/planDropdown`),
    ];
  }

  /**
   * GET Method: Subscriber Report for Graph with filter
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @param customerId
   * @returns
   */
  getSubscriberGraphReport(
    dateRangeValue?: string,
    fromDate?: any,
    toDate?: any,
    customerId?: any
  ) {
    // Null check for dateRangeValue & customerId
    if (dateRangeValue && customerId) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/graph?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/graph?dateRange=${dateRangeValue}&customerId=${customerId}`
        );
      }
    } // Null check for dateRangeValue
    else if (dateRangeValue) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/graph?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/graph?dateRange=${dateRangeValue}`
        );
      }
    } // Null check for customerId
    else if (customerId) {
      // return data from api response
      return this.http.get(
        `${this.serverUrl}/subscribers/report/graph?dateRange=year&customerId=${customerId}`
      );
    } else {
      // return data from api response
      return this.http.get(`${this.serverUrl}/subscribers/report/graph?dateRange=year`);
    }
  }

  /**
   * GET Method: Download Subscriber Report with filter
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @returns
   */
  getSubscriberDownloadReport(dateRangeValue?: string, fromDate?: any, toDate?: any) {
    // Null check for dateRangeValue
    if (dateRangeValue) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/data?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/data?dateRange=${dateRangeValue}`
        );
      }
    } else {
      // return data from api response
      return this.http.get(`${this.serverUrl}/subscribers/report/data?dateRange=year`);
    }
  }

  /**
   * GET Method: Subscriber Report over an email
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @param customerId
   * @returns
   */
  sendSubscriberReportEmail(
    dateRangeValue?: string,
    fromDate?: any,
    toDate?: any,
    customerId?: any
  ) {
    // Null check for dateRangeValue & customerId
    if (dateRangeValue && customerId) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/email?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/email?dateRange=${dateRangeValue}&customerId=${customerId}`
        );
      }
    } // Null check for dateRangeValue
    else if (dateRangeValue) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/email?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscribers/report/email?dateRange=${dateRangeValue}`
        );
      }
    } // Null check for customerId
    else if (customerId) {
      // return data from api response
      return this.http.get(
        `${this.serverUrl}/subscribers/report/email?dateRange=year&customerId=${customerId}`
      );
    } else {
      // return data from api response
      return this.http.get(`${this.serverUrl}/subscribers/report/email?dateRange=year`);
    }
  }

  /**
   * GET Method: Subscription Report for Graph with filter
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @param customerId
   * @returns
   */
  getSubscriptionGraphReport(
    dateRangeValue?: string,
    fromDate?: any,
    toDate?: any,
    customerId?: any
  ) {
    // Null check for dateRangeValue & customerId
    if (dateRangeValue && customerId) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/graph?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/graph?dateRange=${dateRangeValue}&customerId=${customerId}`
        );
      }
    } // Null check for dateRangeValue
    else if (dateRangeValue) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/graph?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/graph?dateRange=${dateRangeValue}`
        );
      }
    } // Null check for customerId
    else if (customerId) {
      // return data from api response
      return this.http.get(
        `${this.serverUrl}/subscriptions/report/graph?dateRange=year&customerId=${customerId}`
      );
    } else {
      // return data from api response
      return this.http.get(`${this.serverUrl}/subscriptions/report/graph?dateRange=year`);
    }
  }

  /**
   * GET Method: Download Subscription Report with filter
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @returns
   */
  getSubscriptionDownloadReport(dateRangeValue?: string, fromDate?: any, toDate?: any) {
    // Null check for dateRangeValue
    if (dateRangeValue) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/data?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/data?dateRange=${dateRangeValue}`
        );
      }
    } else {
      // return data from api response
      return this.http.get(`${this.serverUrl}/subscriptions/report/data?dateRange=year`);
    }
  }

  /**
   * GET Method: Subscription report over an email
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @param customerId
   * @returns
   */
  sendSubscriptionReportEmail(
    dateRangeValue?: string,
    fromDate?: any,
    toDate?: any,
    customerId?: any
  ) {
    // Null check for dateRangeValue & customerId
    if (dateRangeValue && customerId) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/email?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/email?dateRange=${dateRangeValue}&customerId=${customerId}`
        );
      }
    } // Null check for dateRangeValue
    else if (dateRangeValue) {
      // Check for custome daterange ang null check for from and to date
      if (dateRangeValue === 'custom' && fromDate && toDate) {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/email?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        // return data from api response
        return this.http.get(
          `${this.serverUrl}/subscriptions/report/email?dateRange=${dateRangeValue}`
        );
      }
    } // Null check for customerId
    else if (customerId) {
      // return data from api response
      return this.http.get(
        `${this.serverUrl}/subscriptions/report/email?dateRange=year&customerId=${customerId}`
      );
    } else {
      // return data from api response
      return this.http.get(`${this.serverUrl}/subscriptions/report/email?dateRange=year`);
    }
  }
}
