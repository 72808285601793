import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EnvironmentInjector,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  @Output() close = new EventEmitter();
  @Input() template: any;
  @Input() context: any;
  @Input() cssClass: any;
  @Input() hasBackdrop: boolean | undefined = true;

  @ViewChild('backdrop', { read: ViewContainerRef, static: false }) backdrop!: ViewContainerRef;
  @ViewChild('dialogContainer', { read: ViewContainerRef, static: false })
  container!: ViewContainerRef;
  @ViewChild('dialog', { read: ViewContainerRef, static: false }) dialog!: ViewContainerRef;

  componentRef!: ComponentRef<any>;

  constructor(
    private environmentInjector: EnvironmentInjector,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.componentRef = this.container.createComponent(this.template, {
      environmentInjector: this.environmentInjector,
    });
    if (this.context && Object.keys(this.context).length > 0) {
      Object.keys(this.context).forEach((key) => {
        this.componentRef.instance[key] = this.context[key];
      });
    }
    if (this.cssClass) {
      this.dialog.element.nativeElement.className += ' ' + this.cssClass;
    }

    this.cdr.detectChanges();

    if (!this.hasBackdrop) {
      this.backdrop.element.nativeElement.remove();
      if (this.backdrop.element?.nativeElement?.parentElement?.className) {
        this.backdrop.element.nativeElement.parentElement.className = `${this.backdrop.element.nativeElement.parentElement.className} top-spacing`;
      }
    } else if (
      !this.cssClass?.includes('top-spacing') &&
      this.backdrop.element?.nativeElement?.parentElement?.className
    ) {
      this.backdrop.element.nativeElement.parentElement.className = `${this.backdrop.element.nativeElement.parentElement.className} top-spacing`;
    }
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
