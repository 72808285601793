<div class="es-modal info-modal" *ngIf="!inProgress; else spinner">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 d-flex">
        <img class="m-auto" src="/assets/icons/info.svg" alt="info-icon" />
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>
  <div>
    <p class="modal-header-text mb-0">{{ title }}</p>
  </div>
  <div class="es-modal-body">
    <div
      class="row rowHeight custom-border-bottom d-flex align-items-center"
      *ngIf="title === 'Support Info'"
    >
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">Ticket Number</label>
      </div>
      <div class="col-7">
        <label class="subscriber-details-text" for="">
          {{ requestDetails.ticket }}
        </label>
      </div>
    </div>

    <div class="row rowHeight custom-border-bottom d-flex align-items-center">
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">Name</label>
      </div>
      <div class="col-7">
        <label
          class="subscriber-details-text text-overflow-ellipsis mt-2"
          for=""
          *ngIf="requestDetails?.name?.length > 20"
          [tooltip]="requestDetails?.name"
          placement="top"
          delay="100"
          offset="0"
        >
          {{ requestDetails?.name ?? 'N/A' }}
        </label>
        <label class="subscriber-details-text" for="" *ngIf="requestDetails?.name?.length <= 20">
          {{ requestDetails?.name ?? 'N/A' }}
        </label>
      </div>
    </div>

    <div
      class="row rowHeight custom-border-bottom d-flex align-items-center"
      *ngIf="requestDetails?.companyName"
    >
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">Company Name</label>
      </div>
      <div class="col-7">
        <label
          class="subscriber-details-text text-overflow-ellipsis mt-2"
          for=""
          *ngIf="requestDetails?.companyName?.length > 20"
          [tooltip]="requestDetails.companyName"
          placement="top"
          delay="100"
          offset="0"
        >
          {{ requestDetails?.companyName ?? 'N/A' }}
        </label>
        <label
          class="subscriber-details-text"
          for=""
          *ngIf="requestDetails?.companyName?.length <= 20"
        >
          {{ requestDetails?.companyName ?? 'N/A' }}
        </label>
      </div>
    </div>

    <div class="row rowHeight custom-border-bottom d-flex align-items-center">
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">Email Address</label>
      </div>
      <div class="col-7">
        <p class="modal-header-text mb-0 d-flex align-items-start">
          <span
            class="max-w80 word-wrap text-overflow-ellipsis"
            [ngClass]="requestDetails?.email ? 'email-lable' : ''"
          >
            <span
              class="max-w80 word-wrap subscriber-details-text text-overflow-ellipsis"
              *ngIf="requestDetails?.email?.length > 20"
              placement="top"
              delay="100"
              offset="0"
            >
              {{ requestDetails?.email ?? 'N/A' }}
            </span>
            <span
              class="max-w80 word-wrap subscriber-details-text"
              *ngIf="requestDetails?.email?.length <= 20"
            >
              {{ requestDetails?.email ?? 'N/A' }}
            </span>
          </span>
          <span
            class="icon"
            (click)="copyToClipboard($event, requestDetails['email'])"
            [tooltip]="copyText"
            placement="top"
            delay="100"
            offset="5"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_2161_5534"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2161_5534)">
                <path
                  d="M4.16667 18.3332C3.70833 18.3332 3.31583 18.1701 2.98917 17.844C2.66306 17.5173 2.5 17.1248 2.5 16.6665V4.99984H4.16667V16.6665H13.3333V18.3332H4.16667ZM7.5 14.9998C7.04167 14.9998 6.64944 14.8368 6.32333 14.5107C5.99667 14.184 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.64944 1.82956 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8508 1.82956 16.1775 2.15567C16.5036 2.48234 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5036 14.184 16.1775 14.5107C15.8508 14.8368 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
          </span>
        </p>
      </div>
    </div>

    <div
      class="row rowHeight custom-border-bottom d-flex align-items-center"
      *ngIf="title === 'Support Info' || title === 'Affiliate Program Info'"
    >
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">Phone Number</label>
      </div>
      <div class="col-7">
        <label class="subscriber-details-text" for="">
          {{
            requestDetails.countryCode
              ? requestDetails.countryCode + ' ' + requestDetails.number ?? 'N/A'
              : requestDetails.number ?? 'N/A'
          }}
        </label>
      </div>
    </div>

    <div
      class="row rowHeight custom-border-bottom d-flex align-items-center"
      *ngIf="title === 'Support Info'"
    >
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">Device Information</label>
      </div>
      <div class="col-7">
        <label
          class="subscriber-details-text text-overflow-ellipsis"
          for=""
          *ngIf="requestDetails?.deviceInfo?.length > 20"
          [tooltip]="requestDetails.deviceInfo"
          placement="top"
          delay="100"
          offset="0"
        >
          {{ requestDetails.deviceInfo ?? 'N/A' }}
        </label>
        <label
          class="subscriber-details-text"
          for=""
          *ngIf="requestDetails?.deviceInfo?.length <= 20"
        >
          {{ requestDetails.deviceInfo ?? 'N/A' }}
        </label>
      </div>
    </div>

    <div class="row custom-border-bottom d-flex align-items-center">
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">{{
          title === 'Affiliate Program Info' ? 'Enquiry Message' : 'Message'
        }}</label>
      </div>
      <div class="col-7">
        <label
          class="subscriber-details-text text-overflow-ellipsis"
          for=""
          *ngIf="requestDetails?.message?.length > 20"
          [tooltip]="requestDetails?.message"
          placement="top"
          delay="100"
          offset="0"
        >
          {{ requestDetails?.message ?? 'N/A' }}
        </label>
        <label class="subscriber-details-text" for="" *ngIf="requestDetails?.message?.length <= 20">
          {{ requestDetails?.message ?? 'N/A' }}
        </label>
      </div>
    </div>

    <div
      class="row rowHeight custom-border-bottom d-flex align-items-center"
      *ngIf="title !== 'Affiliate Program Info'"
    >
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">Status</label>
      </div>
      <div class="col-7 mt-2">
        <label class="subscriber-details-text word-break" for="">
          <div class="filter-div d-flex">
            <div class="d-flex w-100 div">
              <a
                class="lang-dropdown sg-font-small-regular d-flex w-100 align-items-center"
                role="button"
                id="dropdownMenuLink-lang"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span id="languageTootltip" [appStatusColor]="requestDetails?.status">{{
                  requestDetails?.status
                }}</span>
                <span class="ms-auto">
                  <svg
                    class="down-arrow"
                    width="13"
                    height="7"
                    viewBox="0 0 13 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.211534 0.223544C0.493579 -0.0745154 0.950865 -0.0745154 1.23291 0.223544L5.98931 5.25C6.27136 5.54806 6.72864 5.54806 7.01069 5.25L11.7671 0.223545C12.0491 -0.0745139 12.5064 -0.0745139 12.7885 0.223546C13.0705 0.521604 13.0705 1.00485 12.7885 1.30291L8.03206 6.32937C7.18593 7.22354 5.81407 7.22355 4.96794 6.32937L0.211533 1.30291C-0.0705119 1.00485 -0.0705118 0.521603 0.211534 0.223544Z"
                      fill="#1C1C1C"
                    />
                  </svg>
                </span>
              </a>

              <div
                class="dropdown-menu lang-dropdown-option border-secondary custom-padding"
                aria-labelledby="dropdownMenuLink-lang"
              >
                <a
                  class="dropdown-item lang-dropdown-item sg-font-regular status-item"
                  (click)="onSelect('Resolved', requestDetails)"
                  >Resolved</a
                >
                <a
                  class="dropdown-item lang-dropdown-item sg-font-regular status-item"
                  (click)="onSelect('In Progress', requestDetails)"
                  >In Progress</a
                >
                <a
                  class="dropdown-item lang-dropdown-item sg-font-regular status-item"
                  (click)="onSelect('Pending', requestDetails)"
                  >Pending</a
                >
                <a
                  class="dropdown-item lang-dropdown-item sg-font-regular status-item"
                  (click)="onSelect('Unresolved', requestDetails)"
                  >Unresolved</a
                >
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div
      class="row rowHeight custom-border-bottom d-flex align-items-center"
      *ngIf="title === 'Affiliate Program Info'"
    >
      <div class="col-5 pl-0">
        <label class="subscriber-label-text" for="">Connection Preference</label>
      </div>
      <div class="col-7">
        <label
          for=""
          class="subscriber-details-text text-overflow-ellipsis mt-2"
          *ngIf="requestDetails?.data?.viaEmail"
        >
          Via Email
        </label>
        <label class="subscriber-details-text" for="" *ngIf="requestDetails?.data?.viaPhone">
          Via Phone
        </label>
      </div>
    </div>
  </div>
  <div class="es-modal-footer d-flex pt-4">
    <button class="btn btn-primary ms-auto w-100" (click)="close()">OK</button>
  </div>
</div>

<!-- Loading Spinner - Start -->
<ng-template #spinner class="loader-align">
  <app-loading-spinner></app-loading-spinner>
</ng-template>
<!-- Loading Spinner - End -->
