<div class="auth-parent-section dark-content">
  <div class="auth-main-body">
    <div class="row">
      <div class="col-lg-7 pe-0">
        <div class="auth-left-section">
          <div class="d-flex mb-4">
            <img class="img-fluid main-logo z-index-2" src="/assets/icons/logo.svg" alt="" />
          </div>
          <h1 class="welcome-heading mb-0 z-index-2">We're almost done!</h1>
          <div class="my-4 py-2">
            <h4 class="label-heading mt-auto z-index-2">Create your new password!</h4>
            <div class="ripple-position d-none-tab">
              <div>
                <div class="circles-parent">
                  <div class="solid-circle solid-circle-grey"></div>
                  <div class="circle3 common-circle common-circle-grey common-circle-1"></div>
                  <div class="circle2 common-circle common-circle-grey common-circle-1"></div>
                  <div class="circle1 common-circle common-circle-grey common-circle-1"></div>
                </div>
              </div>
            </div>
            <div class="d-flex text-end express-img-div">
              <img class="img-fluid m-auto" src="/assets/images/auth/reset-password.svg" alt="" />
            </div>
          </div>
          <div class="my-4 py-2">
            <h6 class="label-bonus-tip mt-auto mb-2 z-index-2">Bonus Tip:</h6>
            <h6 class="label-bonus-tip mt-auto mb-2 z-index-2 d-flex">
              <span class="mt-auto">write your password down somewhere safe!</span>
              <img class="img-fluid ms-2" src="/assets/images/auth/notepad.svg" alt="" />
            </h6>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ps-0 z-index-3">
        <div class="auth-right-section">
          <div class="mt-5">
            <p class="signin-text mb-0">Create a new password</p>
            <p class="signin-label-text mb-0" [ngClass]="{ 'text-danger': passwordErr }">
              {{
                passwordErr
                  ? passwordErr
                  : 'Make sure you enter a different password from your previous one'
              }}
            </p>
            <form
              class="signin-form-parent"
              [formGroup]="resetPasswordForm"
              novalidate
              (ngSubmit)="submit()"
            >
              <div class="form-inner-div">
                <label for="exampleInputPassword1" class="form-label">Enter a new password</label>
                <div class="position-relative">
                  <input
                    [type]="showPassword ? 'text' : 'password'"
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Must be at least 8 characters"
                    formControlName="password"
                    (input)="onPasswordInput()"
                  />
                  <p class="show-hide-password mb-0">
                    <img
                      [src]="'/assets/images/auth/' + (showPassword ? 'eye.svg' : 'eye-slash.svg')"
                      (click)="showPassword = !showPassword"
                      alt=""
                    />
                  </p>
                </div>
                <password-strength-meter
                  class="w-75 d-block mx-auto"
                  [password]="f.password.value"
                  [minPasswordLength]="8"
                  [numberOfProgressBarItems]="4"
                  [colors]="passwordStrengthColors"
                  (strengthChange)="onChangePasswordStrength($event)"
                ></password-strength-meter>
                <div class="d-flex">
                  <span
                    class="help-block ms-auto me-4"
                    style="color: #757575"
                    [style.color]="
                      passwordStrengthIndex === 0
                        ? passwordStrengthColorsText[0]
                        : passwordStrengthColorsText[passwordStrengthIndex - 1]
                    "
                  >
                    {{ passwordStrength }}
                  </span>
                </div>
                <div>
                  <span
                    class="help-block ng-invalid text-danger"
                    *ngIf="
                      (!f.password.valid && !f.password.pristine) ||
                      (f.password.errors && submitted)
                    "
                  >
                    <span class="small" *ngIf="f.password.errors.required">
                      Password is required!
                    </span>
                    <span class="small" *ngIf="resetPasswordForm?.get('password')?.hasError('pattern')">
                      The password must contain at least one lowercase letter, one uppercase letter, one special character, one digit, and be at least 8 characters long!
                    </span>
                  </span>
                </div>
              </div>
              <div class="form-inner-div">
                <label for="exampleInputPassword1" class="form-label">Re-enter password</label>
                <div class="position-relative">
                  <input
                    [type]="showConfirmPassword ? 'text' : 'password'"
                    class="form-control"
                    id="exampleInputPassword2"
                    placeholder="Re-enter your password"
                    formControlName="confirmPassword"
                    (input)="onPasswordInput()"
                  />
                  <p class="show-hide-password mb-0">
                    <img
                      [src]="
                        '/assets/images/auth/' + (showConfirmPassword ? 'eye.svg' : 'eye-slash.svg')
                      "
                      (click)="showConfirmPassword = !showConfirmPassword"
                      alt=""
                    />
                  </p>
                </div>
                <div class="w-75 d-block mx-auto mt-2" *ngIf="!f.confirmPassword.pristine">
                  <div class="progress-bar">
                    <div class="progress-bar-item" style="width: 100%"></div>
                    <div
                      class="progress-bar-overlay"
                      style="color: #757575"
                      [style.width.%]="
                        f.confirmPassword?.value === f.password?.value
                          ? 100
                          : passwordMismatchLength * 10
                      "
                      [style.backgroundColor]="
                        f.confirmPassword.errors ||
                        passwordMatchError ||
                        f.confirmPassword?.value != f.password?.value
                          ? passwordStrengthColors[0]
                          : passwordStrengthColors[3]
                      "
                    ></div>
                  </div>
                </div>
                <div class="d-flex" *ngIf="!f.confirmPassword.pristine">
                  <span
                    class="help-block ms-auto me-4"
                    [style.color]="
                      f.confirmPassword.errors ||
                      passwordMatchError ||
                      f.confirmPassword?.value != f.password?.value
                        ? passwordStrengthColorsText[0]
                        : passwordStrengthColorsText[3]
                    "
                  >
                    {{
                      f.confirmPassword.errors ||
                      passwordMatchError ||
                      f.confirmPassword?.value != f.password?.value
                        ? "Password's not match"
                        : "Password's Match"
                    }}
                  </span>
                </div>
                <div class="position-relative">
                  <p
                    class="help-block ng-invalid text-danger mb-0"
                    *ngIf="
                      (!f.confirmPassword.valid && !f.confirmPassword.pristine) ||
                      (f.password.errors && submitted)
                    "
                  >
                    <span class="small" *ngIf="f.password.errors?.required">
                      Confirm password is required!
                    </span>
                  </p>
                </div>
              </div>
              <button type="submit" class="btn auth-btn common-btn">Reset Password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
