import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';

import { DialogService } from './service/dialog';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDateRangePicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxGaugeModule } from 'ngx-gauge';

import {
  BreadcrumbsComponent,
  LoadingSpinnerComponent,
  MobileNumberInputComponent,
  NavbarComponent,
  NotFoundComponent,
  SidebarComponent,
} from './component';

import {
  AlertComponent,
  ConfirmComponent,
  ContactSupportInfoComponent,
  CustomerComponent,
  DownloadSampleFileComponent,
  InventoryInfoComponent,
  InviteSubscriberComponent,
  PlanDialogComponent,
  PlanInfoComponent,
  ReportAlertComponent,
  SubscriberInfoComponent,
  SubscriberMgmtComponent,
  SubscriptionDialogComponent,
  SubscriptionInfoComponent,
  SubscriptionPlanInfoComponent,
  SubscriptionRefundComponent,
  UploadInventoryComponent,
  UserInfoComponent,
  UserMgmtComponent,
} from './dialog';

import {
  DecimalInputDirective,
  EventBlockerDirective,
  NumberOnlyDirective,
  StatusColorDirective,
  TooltipDirective,
} from './directive';

import { ColorPickerModule } from 'ngx-color-picker';
import { AngularOtpLibModule } from 'src/lib/angular-otp-box';
import { BrandColorsComponent } from './component/brand-colors/brand-colors.component';
import { FeatureHeaderComponent } from './component/feature-header/feature-header.component';
import { PreviewDashboardComponent } from './component/preview-dashboard/preview-dashboard.component';
import { PreviewNavbarComponent } from './component/preview-navbar/preview-navbar.component';
import { InviteAgentComponent } from './dialog/invite-agent/invite-agent.component';
import { OtpVerificationComponent } from './dialog/otp-verification/otp-verification.component';
import { PlanSuccessInfoComponent } from './dialog/plan-success-info/plan-success-info.component';
import { PreventSpaceDirective } from './directive/prevent-space.directive';
import { TooltipIfTruncatedDirective } from './directive/tooltip-if-truncated.directive';
import { AlphabetOnlyDirective, TrimSpacesDirective } from './directive/trim-spaces.directive';
import {
  CurrencyByMatchingkeyPipe,
  CurrencySymbolPipe,
  DayOrDatePipe,
  FilesizePipe,
  KeyToDisplayPipe,
  MessageByRequestTypekeyPipe,
  PhoneNumberMaskPipe,
  ProductNameByKeyPipe,
  RoleCheckPipe,
  SearchFilterPipe,
  ShowentriesPipe,
} from './pipe';
import { DecimalFormatPipe } from './pipe/decimal-format.pipe';

@NgModule({
  declarations: [
    SubscriptionDialogComponent,
    ConfirmComponent,
    SidebarComponent,
    NavbarComponent,
    BrandColorsComponent,
    ConfirmComponent,
    UserMgmtComponent,
    PlanDialogComponent,
    CustomerComponent,
    InviteSubscriberComponent,
    SubscriberMgmtComponent,
    AlertComponent,
    PlanInfoComponent,
    SubscriptionInfoComponent,
    UserInfoComponent,
    SubscriberInfoComponent,
    UploadInventoryComponent,
    DownloadSampleFileComponent,
    LoadingSpinnerComponent,
    InventoryInfoComponent,
    NotFoundComponent,
    ContactSupportInfoComponent,
    SubscriptionRefundComponent,
    TooltipDirective,
    EventBlockerDirective,
    StatusColorDirective,
    ReportAlertComponent,
    NumberOnlyDirective,
    FilesizePipe,
    PhoneNumberMaskPipe,
    CurrencySymbolPipe,
    OtpVerificationComponent,
    SearchFilterPipe,
    MobileNumberInputComponent,
    ShowentriesPipe,
    SearchFilterPipe,
    DayOrDatePipe,
    KeyToDisplayPipe,
    CurrencyByMatchingkeyPipe,
    RoleCheckPipe,
    SubscriptionPlanInfoComponent,
    InviteAgentComponent,
    MessageByRequestTypekeyPipe,
    PlanSuccessInfoComponent,
    ProductNameByKeyPipe,
    BreadcrumbsComponent,
    TrimSpacesDirective,
    AlphabetOnlyDirective,
    FeatureHeaderComponent,
    DecimalInputDirective,
    PreventSpaceDirective,
    PreviewNavbarComponent,
    PreviewDashboardComponent,
    TooltipIfTruncatedDirective,
    DecimalFormatPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    QRCodeModule,
    RouterModule,
    NgSelectModule,
    NgxGaugeModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    AngularOtpLibModule,
    ColorPickerModule,
  ],
  exports: [
    SubscriptionDialogComponent,
    SidebarComponent,
    NavbarComponent,
    BrandColorsComponent,
    ConfirmComponent,
    UserMgmtComponent,
    TooltipDirective,
    LoadingSpinnerComponent,
    FormsModule,
    ReactiveFormsModule,
    StatusColorDirective,
    CurrencySymbolPipe,
    NumberOnlyDirective,
    ShowentriesPipe,
    MobileNumberInputComponent,
    DayOrDatePipe,
    KeyToDisplayPipe,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatDateRangePicker,
    CurrencyByMatchingkeyPipe,
    MessageByRequestTypekeyPipe,
    ProductNameByKeyPipe,
    BreadcrumbsComponent,
    TrimSpacesDirective,
    NgSelectModule,
    AlphabetOnlyDirective,
    FeatureHeaderComponent,
    DecimalInputDirective,
    PreventSpaceDirective,
    PreviewNavbarComponent,
    PreviewDashboardComponent,
    TooltipIfTruncatedDirective,
    DecimalFormatPipe,
  ],
  providers: [CurrencyPipe, DatePipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [DialogService, CurrencyPipe],
    };
  }
}
