<div class="es-modal info-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 d-flex">
        <img class="m-auto" src="/assets/icons/info.svg" alt="info-icon" />
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>
  <div>
    <p class="modal-header-text mb-0">Inventory Info</p>
  </div>
  <div class="es-modal-body">
    <div class="row rowHeight custom-border-bottom d-flex align-items-center">
      <div class="col-4 pl-0">
        <label class="subscriber-label-text">ICCID</label>
      </div>
      <div class="col-8">
        <label class="subscriber-details-text word-break">
          {{ inventoryDetails?.iccid }}
        </label>
      </div>
    </div>

    <div class="row rowHeight custom-border-bottom d-flex align-items-center">
      <div class="col-4 pl-0">
        <label class="subscriber-label-text">SMDP Address</label>
      </div>
      <div class="col-8">
        <label class="subscriber-details-text word-break">
          {{ inventoryDetails?.smdp }}
        </label>
      </div>
    </div>

    <div class="row rowHeight custom-border-bottom d-flex align-items-center">
      <div class="col-4 pl-0">
        <label class="subscriber-label-text">Matching ID</label>
      </div>
      <div class="col-8">
        <label class="subscriber-details-text word-break">
          {{ inventoryDetails?.matchingId }}
        </label>
      </div>
    </div>

    <div class="row rowHeight custom-border-bottom d-flex align-items-center">
      <div class="col-4 pl-0">
        <label class="subscriber-label-text">LPA</label>
      </div>
      <div class="col-8">
        <label class="subscriber-details-text word-break">
          {{ inventoryDetails?.lpa }}
        </label>
      </div>
    </div>

    <div class="row rowHeight custom-border-bottom d-flex align-items-center">
      <div class="col-4 pl-0">
        <label class="subscriber-label-text">Status</label>
      </div>
      <div class="col-8">
        <label class="subscriber-details-text">
          {{ inventoryDetails?.status | titlecase }}
        </label>
      </div>
    </div>

    <div class="row rowHeight d-flex align-items-center">
      <div class="col-4 pl-0">
        <label class="subscriber-label-text">Assigned To</label>
      </div>
      <div class="col-8 d-flex align-items-center">
        <label class="subscriber-details-text text-truncate">
          {{ inventoryDetails?.subscriberEmail }}
        </label>
        <span
          class="icon copy"
          *ngIf="inventoryDetails?.subscriberEmail"
          (click)="copyToClipboard($event, inventoryDetails?.subscriberEmail)"
          [tooltip]="copyText"
          placement="top"
          delay="100"
          offset="5"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_2161_5534"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="20"
              height="20"
            >
              <rect width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2161_5534)">
              <path
                d="M4.16667 18.3332C3.70833 18.3332 3.31583 18.1701 2.98917 17.844C2.66306 17.5173 2.5 17.1248 2.5 16.6665V4.99984H4.16667V16.6665H13.3333V18.3332H4.16667ZM7.5 14.9998C7.04167 14.9998 6.64944 14.8368 6.32333 14.5107C5.99667 14.184 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.64944 1.82956 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8508 1.82956 16.1775 2.15567C16.5036 2.48234 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5036 14.184 16.1775 14.5107C15.8508 14.8368 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332Z"
                fill="white"
              />
            </g>
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div class="es-modal-footer d-flex pt-4">
    <button class="btn btn-primary ms-auto w-100" (click)="close()">OK</button>
  </div>
</div>
