import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * GET Method: Test Email
   * @param email
   * @param type
   * @returns
   */
  testMail(email: any, type: string) {
    return this.http.get(
      `${this.serverUrl}/customers/setting/testemail?email=${email}&type=${type}`
    );
  }

  /**
   * PUT Method: SMTP Setup
   * @param data
   * @returns
   */
  saveSMTP(data: any) {
    return this.http.put(`${this.serverUrl}/customer/smtp/setup`, data);
  }

  /**
   * GET Method: SMTP Details
   * @returns
   */
  getSMTP() {
    return this.http.get(`${this.serverUrl}/customer/smtp/setup`);
  }

  /**
   * GET Method: Customer Currency
   * @returns
   */
  getCurrencySettings() {
    return this.http.get(`${this.serverUrl}/customers/setting/currency`);
  }

  /**
   * POST Method: Test SMTP Email
   * @param data
   * @returns
   */
  testSMTP(data: any) {
    return this.http.post(`${this.serverUrl}/customer/smtp/setup`, data);
  }

  /*
   ****************************************************
   ***Newly created functions based on modified APIs***
   ****************************************************
   */

  /**
   * GET Method: All Settings
   * @param customerId
   * @param cacheId
   * @returns
   */
  getAllSettings(customerId: string, cacheId: string) {
    let configurationUrl: string;

    let endpointUrl;
    if (customerId) endpointUrl = `customers/settings?customerId=${customerId}`;
    else endpointUrl = `customers/settings`;

    if (cacheId) {
      configurationUrl = `customers/setting/configuration?cacheId=${cacheId}`;
    } else {
      configurationUrl = `customers/setting/configuration`;
    }
    return [
      this.http.get(`${this.serverUrl}/${endpointUrl}`),
      this.http.get(`${this.serverUrl}/${configurationUrl}`),
      this.http.get(`${this.serverUrl}/currencies`),
      this.http.get(`${this.serverUrl}/languages`),
    ];
  }

  /**
   * POST Method: Intentory Usage Reminder Setting
   * @param param0
   * @returns
   */
  saveIntentoryUsageReminderSetting({
    customerId,
    inventoryUsageReminder,
  }: {
    customerId: string;
    inventoryUsageReminder: any;
  }) {
    let endpointUrl;
    if (customerId)
      endpointUrl = `customers/settings/inventory-usage-reminder?customerId=${customerId}`;
    else endpointUrl = `customers/settings/inventory-usage-reminder`;
    return this.http.post(`${this.serverUrl}/${endpointUrl}`, {
      inventoryUsageReminder: inventoryUsageReminder,
    });
  }

  /**
   * POST Method: Email Forwarding Settings (Support Email & Contact Email)
   * @param customerId
   * @param emailForwardingObj
   * @returns
   */
  saveEmailForwardingSetting(customerId: string, emailForwardingObj: any) {
    let endpointUrl;
    if (customerId) endpointUrl = `customers/settings/email-forwarding?customerId=${customerId}`;
    else endpointUrl = `customers/settings/email-forwarding`;
    return this.http.post(`${this.serverUrl}/${endpointUrl}`, emailForwardingObj);
  }

  /**
   * POST Method: Currency Setup
   * @param customerId
   * @param currencyObj
   * @returns
   */
  saveCurrencySetup(customerId: string, currencyObj: any) {
    let endpointUrl;
    if (customerId) endpointUrl = `customers/settings/currency?customerId=${customerId}`;
    else endpointUrl = `customers/settings/currency`;
    return this.http.post(`${this.serverUrl}/${endpointUrl}`, currencyObj);
  }

  /**
   * POST Method: Plan reminder
   * @param customerId
   * @param planReminderObj
   * @returns
   */
  savePlanReminder(customerId: string, planReminderObj: any) {
    const { planReminder } = planReminderObj;
    let endpointUrl;
    if (customerId)
      endpointUrl = `customers/settings/plan-expiry-reminder?customerId=${customerId}`;
    else endpointUrl = `customers/settings/plan-expiry-reminder`;
    return this.http.post(`${this.serverUrl}/${endpointUrl}`, { planReminder: planReminder });
  }

  /**
   * PUT Method: Language
   * @param customerId
   * @param languageObj
   * @returns
   */
  saveLanguageSetup(customerId: string, languageObj: any) {
    let endpointUrl;
    if (customerId) endpointUrl = `customers/setting/languages?customerId=${customerId}`;
    else endpointUrl = `customers/setting/languages`;
    return this.http.put(`${this.serverUrl}/${endpointUrl}`, languageObj);
  }
}
