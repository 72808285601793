import { Directive, ElementRef, HostListener, Input } from '@angular/core';

type AllowedTypes =
  | 'alphabetsNoSpace'
  | 'numbersNoSpace'
  | 'alphabets'
  | 'numbers'
  | 'alphanumerics'
  | 'alphanumericWithHyphens'
  | 'noSpace';

type RegexMap = {
  [key in AllowedTypes]: RegExp;
};

@Directive({
  selector: '[appCustomInputValidator]',
})
export class CustomInputValidatorDirective {
  @Input('appCustomInputValidator') allowedType: AllowedTypes = 'alphabetsNoSpace';

  private regexMap: RegexMap = {
    alphabetsNoSpace: /^[a-zA-Z]*$/, // Alphabets without spaces
    numbersNoSpace: /^[\d]*$/, // Numbers without spaces
    alphabets: /^[a-zA-Z\s]*$/, // Alphabets with spaces
    numbers: /^[\d\s]*$/, // Numbers with spaces
    alphanumerics: /^[a-zA-Z\d\s]*$/, // Alphanumeric with spaces
    alphanumericWithHyphens: /^[a-zA-Z\d-]*$/, // Alphanumeric with hyphens
    noSpace: /^[^\s]*$/, // Everything except spaces
  };

  private specialKeys: string[] = [
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Delete',
    'Home',
    'End',
    'Tab',
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.specialKeys.includes(event.key)) {
      return; // Allow special keys
    }

    const regex = this.regexMap[this.allowedType];
    if (!regex) {
      console.warn(`Invalid allowedType: ${this.allowedType}`);
      return;
    }

    if (!regex.test(event.key)) {
      event.preventDefault(); // Block invalid keys
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const regex = this.regexMap[this.allowedType];
    if (!regex) {
      console.warn(`Invalid allowedType: ${this.allowedType}`);
      return;
    }

    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text') || '';

    if (!regex.test(pastedText)) {
      event.preventDefault(); // Block invalid pasted text
    }
  }
}
