<div class="es-modal info-modal" *ngIf="!inProgress; else spinner">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 d-flex">
        <img class="m-auto" src="/assets/icons/info.svg" alt="info-icon" />
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>
  <div>
    <p class="modal-header-text mb-0">Subscriber Info</p>
  </div>
  <div class="es-modal-body">
    <ng-container *ngFor="let details of detailsRow; let i = index; last as isLast">
      <div
        class="row rowHeight d-flex align-items-center gap-1"
        [ngClass]="{ 'custom-border-bottom': !isLast }"
        *ngIf="details.display"
      >
        <div class="col-4 pl-0">
          <label
            class="subscriber-label-text"
            [ngClass]="details.title === 'Contact Preferences' ? 'text-wrap' : ''"
          >
            {{ details?.title }}
          </label>
        </div>
        <div
          class="col-7 ml-2 d-flex align-items-center"
          [ngClass]="details?.title === 'Activation Code' ? 'activation-code-scroll' : ''"
        >
          <label
            class="subscriber-details-text"
            [ngClass]="details?.title === 'Email Address' ? 'email-lable' : ''"
            *ngIf="subscriberDetails && details && details?.title !== 'Contact Preferences'"
          >
            <span
              class="icon copy"
              *ngIf="
                details.title === 'Available Reward Points' ||
                details.title === 'Used Reward Points'
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M3.9375 2.25C3.48995 2.25 3.06072 2.42779 2.74426 2.74426C2.42779 3.06072 2.25 3.48995 2.25 3.9375V5.27962C2.25 5.86462 2.55375 6.40687 3.04987 6.714L7.39913 9.40275C6.73759 9.75918 6.21393 10.3262 5.91113 11.0139C5.60833 11.7017 5.54371 12.4708 5.72752 13.1994C5.91132 13.928 6.33302 14.5745 6.92581 15.0363C7.5186 15.4981 8.24855 15.7489 9 15.7489C9.75145 15.7489 10.4814 15.4981 11.0742 15.0363C11.667 14.5745 12.0887 13.928 12.2725 13.1994C12.4563 12.4708 12.3917 11.7017 12.0889 11.0139C11.7861 10.3262 11.2624 9.75918 10.6009 9.40275L14.9501 6.714C15.1946 6.56282 15.3963 6.35171 15.5363 6.10068C15.6763 5.84966 15.7499 5.56704 15.75 5.27962V3.9375C15.75 3.48995 15.5722 3.06072 15.2557 2.74426C14.9393 2.42779 14.5101 2.25 14.0625 2.25H3.9375ZM6.75 7.67925V3.375H11.25V7.67925L9.29587 8.8875C9.20698 8.94248 9.10452 8.9716 9 8.9716C8.89548 8.9716 8.79302 8.94248 8.70412 8.8875L6.75 7.67925ZM9 10.125C9.59674 10.125 10.169 10.3621 10.591 10.784C11.0129 11.206 11.25 11.7783 11.25 12.375C11.25 12.9717 11.0129 13.544 10.591 13.966C10.169 14.3879 9.59674 14.625 9 14.625C8.40326 14.625 7.83097 14.3879 7.40901 13.966C6.98705 13.544 6.75 12.9717 6.75 12.375C6.75 11.7783 6.98705 11.206 7.40901 10.784C7.83097 10.3621 8.40326 10.125 9 10.125Z"
                  fill="white"
                />
              </svg>
            </span>
            {{
              !subscriberDetails[details.key]
                ? 'NA'
                : details.isDate
                  ? (subscriberDetails[details.key] | date: 'dd/MM/yyyy Thh:mm:ss')
                  : subscriberDetails[details.key]
            }}
            <span
              class="icon"
              *ngIf="details?.key === 'email'"
              (click)="copyToClipboard($event, subscriberDetails['email'])"
              [tooltip]="copyText"
              placement="top"
              delay="100"
              offset="5"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_2161_5534"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <rect width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2161_5534)">
                  <path
                    d="M4.16667 18.3332C3.70833 18.3332 3.31583 18.1701 2.98917 17.844C2.66306 17.5173 2.5 17.1248 2.5 16.6665V4.99984H4.16667V16.6665H13.3333V18.3332H4.16667ZM7.5 14.9998C7.04167 14.9998 6.64944 14.8368 6.32333 14.5107C5.99667 14.184 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.64944 1.82956 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8508 1.82956 16.1775 2.15567C16.5036 2.48234 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5036 14.184 16.1775 14.5107C15.8508 14.8368 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332Z"
                    fill="#1C1B1F"
                  />
                </g>
              </svg>
            </span>
            <span class="icon copy" *ngIf="details?.title === 'Available Reward Points'">
              (${{ this.subscriberDetails?.cashValueOfRewardsPoints }})
            </span>

            <span class="icon copy" *ngIf="details?.title === 'Used Reward Points'">
              (${{ this.subscriberDetails?.cashValueOfRedeemedPoints }})
            </span>
          </label>

          <label
            class="subscriber-details-text ms-auto"
            style="width: unset"
            *ngIf="
              details?.title === 'Display Name' &&
              (subscriberDetails?.availableRewardPoints > 0 ||
                subscriberDetails?.usedRewardPoints > 0)
            "
          >
            <div class="small-text" *ngIf="subscriberDetails && subscriberDetails?.displayName">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.5 3.74953V13.1245C17.4995 13.2135 17.48 13.3014 17.4428 13.3822C17.4056 13.463 17.3516 13.535 17.2844 13.5933C16.0906 14.6269 14.9492 14.9995 13.8609 14.9995C12.3836 14.9995 11.0039 14.3167 9.72031 13.6831C7.64609 12.6558 5.84297 11.7659 3.75 13.4191V16.8745C3.75 17.0403 3.68415 17.1993 3.56694 17.3165C3.44973 17.4337 3.29076 17.4995 3.125 17.4995C2.95924 17.4995 2.80027 17.4337 2.68306 17.3165C2.56585 17.1993 2.5 17.0403 2.5 16.8745V3.74953C2.5006 3.66049 2.52022 3.57261 2.55754 3.49176C2.59486 3.41092 2.64903 3.33899 2.71641 3.28078C5.52891 0.844841 8.05312 2.0925 10.2781 3.19328C12.4219 4.25422 14.2805 5.1714 16.4656 3.28078C16.5559 3.20249 16.6668 3.15173 16.7851 3.13454C16.9034 3.11734 17.0241 3.13443 17.133 3.18376C17.2419 3.2331 17.3343 3.31262 17.3993 3.4129C17.4644 3.51318 17.4993 3.63 17.5 3.74953Z"
                  fill="black"
                />
              </svg>
            </div>
          </label>

          <label
            class="subscriber-details-text mt-2"
            *ngIf="details.title === 'Contact Preferences'"
          >
            <div class="small-text" *ngIf="subscriberDetails?.isSms">SMS/WhatsApp</div>
            <div class="small-text" *ngIf="subscriberDetails?.isEmail">Email</div>
            <div class="small-text" *ngIf="subscriberDetails?.relevantInfo">
              Relevant Communication
            </div>
            <div
              *ngIf="
                !subscriberDetails?.isSms &&
                !subscriberDetails?.isEmail &&
                !subscriberDetails?.relevantInfo
              "
            >
              NA
            </div>
          </label>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="es-modal-footer d-flex pt-4">
    <button class="btn btn-primary ms-auto w-100" (click)="close()">OK</button>
  </div>
</div>

<!-- Loading Spinner - Start -->
<ng-template #spinner class="loader-align">
  <app-loading-spinner></app-loading-spinner>
</ng-template>
<!-- Loading Spinner - End -->
