import { Component, OnInit } from '@angular/core';
import { CustomerService, LocalStorageService, UsersService } from '../../service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  selectedCustomer: any;
  selectedCustomerTree: any = [];
  userDetails: any;
  customer: any;

  constructor(
    private localStorage: LocalStorageService,
    usersService: UsersService,
    private customerService: CustomerService
  ) {
    usersService.getCurrentUser().subscribe((result) => {
      this.userDetails = result;
    });

    customerService.getCustomer().subscribe((result: any) => {
      if (result) this.selectedCustomer = this.localStorage.getSelectedCustomer();
    });
  }

  ngOnInit(): void {
    this.selectedCustomer = this.localStorage.getSelectedCustomer();
    this.selectedCustomerTree = this.localStorage.getSelectedCustomerTree()?.split(',');
    this.getCustomerDetails();
  }

  /**
   * Get selected customer details to create breadcrumb bar
   */
  getCustomerDetails() {
    if (
      this.localStorage.getSelectedCustomer() &&
      this.localStorage.getSelectedCustomer() != 'false'
    ) {
      this.customerService
        .getSingleCustomer(this.localStorage.getSelectedCustomer())
        .subscribe((res: any) => {
          if (res && res.length > 0) {
            this.customer = res[0];
          }
        });
    }
  }
}
