import { ApplicationRef, EnvironmentInjector, Injectable, createComponent } from '@angular/core';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private injector: EnvironmentInjector,
    private applicationRef: ApplicationRef
  ) {}

  openModal(templateRef: any, userConfig: any) {
    // Create element
    const popup = document.createElement('popup-component');

    let className = 'main-container';
    if (
      document.getElementsByClassName('body-container') &&
      document.getElementsByClassName('body-container')[0]
    ) {
      className = 'body-container';
    }
    document.getElementsByClassName(className)[0].appendChild(popup);

    // Create the component and wire it up with the element
    const dialogComponentRef = createComponent(DialogComponent, {
      environmentInjector: this.injector,
      hostElement: popup,
    });

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(dialogComponentRef.hostView);

    // Listen to the close event
    dialogComponentRef.instance.close.subscribe(() => {
      document.body.removeChild(popup);
      this.applicationRef.detachView(dialogComponentRef.hostView);
    });

    // Set the message
    dialogComponentRef.instance.template = templateRef;
    dialogComponentRef.instance.context = userConfig.context;
    dialogComponentRef.instance.cssClass = userConfig.cssClass;

    // Add to the DOM
    document.body.appendChild(popup);
    return dialogComponentRef;
  }
}
