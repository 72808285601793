<div class="es-modal add-edit-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3" class="add-edit-icon">
        <div class="mb-3">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [class]="title === 'Invite Agent' ? 'add-user-icon' : 'edit-user-icon'"
          >
            <rect class="rect1" x="4" y="4" width="48" height="48" rx="24" fill="#33A85B" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.1875 23.8751C23.1875 21.7235 24.9317 19.9792 27.0833 19.9792C29.2349 19.9792 30.9792 21.7235 30.9792 23.8751C30.9792 26.0267 29.2349 27.7709 27.0833 27.7709C24.9317 27.7709 23.1875 26.0267 23.1875 23.8751ZM27.0833 21.3542C25.6911 21.3542 24.5625 22.4829 24.5625 23.8751C24.5625 25.2673 25.6911 26.3959 27.0833 26.3959C28.4756 26.3959 29.6042 25.2673 29.6042 23.8751C29.6042 22.4829 28.4756 21.3542 27.0833 21.3542Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.9785 32.5833C19.9785 30.6847 21.5175 29.1458 23.416 29.1458H23.7285C23.8976 29.1458 24.0656 29.1725 24.2264 29.225L25.0198 29.4841C26.3603 29.9217 27.8051 29.9217 29.1455 29.4841L29.9389 29.225C30.0997 29.1725 30.2677 29.1458 30.4369 29.1458H30.7493C32.6479 29.1458 34.1868 30.6847 34.1868 32.5833V33.6725C34.1868 34.363 33.6864 34.9516 33.005 35.0629C29.0828 35.7033 25.0826 35.7033 21.1603 35.0629C20.4789 34.9516 19.9785 34.363 19.9785 33.6725V32.5833ZM23.416 30.5208C22.2769 30.5208 21.3535 31.4442 21.3535 32.5833V33.6725C21.3535 33.6891 21.3655 33.7032 21.3819 33.7059C25.1574 34.3224 29.008 34.3224 32.7834 33.7059C32.7998 33.7032 32.8118 33.6891 32.8118 33.6725V32.5833C32.8118 31.4442 31.8884 30.5208 30.7493 30.5208H30.4369C30.4127 30.5208 30.3887 30.5246 30.3657 30.532L29.5723 30.7912C27.9546 31.3194 26.2107 31.3194 24.593 30.7912L23.7996 30.532C23.7767 30.5246 23.7526 30.5208 23.7285 30.5208H23.416Z"
              fill="white"
            />
            <path
              d="M34.8757 22.7292C35.2553 22.7292 35.5632 23.0371 35.5632 23.4167V25.0209H37.1673C37.547 25.0209 37.8548 25.3287 37.8548 25.7084C37.8548 26.0881 37.547 26.3959 37.1673 26.3959H35.5632V28.0001C35.5632 28.3798 35.2553 28.6876 34.8757 28.6876C34.496 28.6876 34.1882 28.3798 34.1882 28.0001V26.3959H32.584C32.2043 26.3959 31.8965 26.0881 31.8965 25.7084C31.8965 25.3287 32.2043 25.0209 32.584 25.0209H34.1882V23.4167C34.1882 23.0371 34.496 22.7292 34.8757 22.7292Z"
              fill="white"
            />
            <rect
              class="rect2"
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#A3FFD3"
              stroke-width="8"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>

  <div>
    <p class="modal-header-text mb-0">{{ title }}</p>
    <p class="modal-sub-header-text mb-0 mt-2">
      Invite agent by entering their details below and submitting them.
    </p>
  </div>
  <div class="es-modal-body" *ngIf="inviteAgentForm">
    <form class="es-modal-form" [formGroup]="inviteAgentForm" novalidate (ngSubmit)="submit()">
      <div class="div-height">
        <label for="name" class="form-label es-modal-form-label">Agent Name*</label>
        <input
          type="text"
          class="form-control es-input"
          id="name"
          aria-describedby="name"
          formControlName="name"
          [ngClass]="[inviteAgentForm.controls['name'].valid ? 'border-purple' : '']"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="
              inviteAgentForm.controls['name'].touched &&
              inviteAgentForm.controls['name'].dirty &&
              inviteAgentForm.controls['name'].errors
            "
          >
            <span class="small" *ngIf="inviteAgentForm.controls['name'].errors?.['required']">
              Agent Name is required!
            </span>
            <span class="small" *ngIf="inviteAgentForm.controls['name'].errors?.['spacesOnly']">
              Value contains spaces only!
            </span>
            <span class="small" *ngIf="inviteAgentForm.controls['name'].errors?.['minlength']">
              Agent Name should have min.
              {{ inviteAgentForm.controls['name'].errors?.['minlength']?.requiredLength }}
              characters.
            </span>
            <span class="small" *ngIf="inviteAgentForm.controls['name'].errors?.['maxlength']">
              Agent Name should have max.
              {{ inviteAgentForm.controls['name'].errors?.['maxlength']?.requiredLength }}
              characters.
            </span>
            <span class="small" *ngIf="inviteAgentForm.controls['name'].errors?.['pattern']">
              Name must contain only alphabets.
            </span>
          </span>
        </div>
      </div>

      <div class="div-height">
        <label for="email" class="form-label es-modal-form-label">Email Address*</label>
        <input
          appTrimSpaces
          type="text"
          class="form-control es-input"
          id="email"
          aria-describedby="email"
          formControlName="email"
          [ngClass]="{
            'border-purple': inviteAgentForm.controls['email'].valid,
            'border-error':
              inviteAgentForm.controls['email']?.touched &&
              inviteAgentForm.controls['email']?.dirty &&
              (inviteAgentForm.controls['email']?.errors?.['required'] ||
                inviteAgentForm.controls['email']?.errors?.['minlength'] ||
                inviteAgentForm.controls['email']?.errors?.['maxlength'] ||
                inviteAgentForm.controls['email']?.errors?.['pattern'])
          }"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="
              inviteAgentForm.controls['email'].touched &&
              inviteAgentForm.controls['email'].dirty &&
              inviteAgentForm.controls['email'].errors
            "
          >
            <span class="small" *ngIf="inviteAgentForm.controls['email'].errors?.['required']">
              Email address is required!
            </span>
            <span
              class="small"
              *ngIf="
                inviteAgentForm.controls['email'].errors?.['email'] ||
                inviteAgentForm.controls['email'].errors?.['pattern']
              "
              >Please enter valid email address!</span
            >
            <span
              class="small"
              *ngIf="
                !inviteAgentForm.controls['email'].errors?.['email'] &&
                !inviteAgentForm.controls['email'].errors?.['pattern'] &&
                inviteAgentForm.controls['email'].errors?.['minlength']
              "
            >
              Email address should have min.
              {{ inviteAgentForm.controls['email'].errors?.['minlength']?.requiredLength }}
              characters.
            </span>
            <span
              class="small"
              *ngIf="
                !inviteAgentForm.controls['email'].errors?.['email'] &&
                !inviteAgentForm.controls['email'].errors?.['pattern'] &&
                inviteAgentForm.controls['email'].errors?.['maxlength']
              "
            >
              Email address should have max.
              {{ inviteAgentForm.controls['email'].errors?.['maxlength']?.requiredLength }}
              characters.
            </span>
          </span>
        </div>
      </div>

      <div class="div-height">
        <label for="name" class="form-label es-modal-form-label">Phone Number*</label>
        <app-mobile-number-input
          [inputControl]="inviteAgentForm.controls['mobile']"
        ></app-mobile-number-input>
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="
              inviteAgentForm.controls['mobile'].touched &&
              inviteAgentForm.controls['mobile'].dirty &&
              inviteAgentForm.controls['mobile'].errors
            "
          >
            <span class="small" *ngIf="inviteAgentForm.controls['mobile'].errors?.['required']"
              >Phone Number is required!</span
            >
            <span class="small" *ngIf="inviteAgentForm.controls['mobile'].errors?.['minlength']"
              >Phone Number cannot be less than 7 characters!</span
            >
            <span class="small" *ngIf="inviteAgentForm.controls['mobile'].errors?.['maxlength']"
              >Phone Number cannot be more than 15 characters!</span
            >
          </span>
        </div>
      </div>
      <div class="d-flex mt-2">
        <button class="btn btn-secondary w-20 mr-30" (click)="close()">Cancel</button>
        <button
          type="submit"
          class="btn btn-primary ms-auto w-20"
          [disabled]="inviteAgentForm.invalid"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
  <div class="es-modal-footer"></div>
</div>
