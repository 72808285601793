<div class="es-modal add-edit-modal plan" *ngIf="!inProgress; else spinner">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 subscription-header-icon">
        <svg
          class="add-plan"
          *ngIf="title === 'Add New Plan'"
          class="m-auto"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.75 13.5C8.75 13.0858 9.08579 12.75 9.5 12.75H11.25V11C11.25 10.5858 11.5858 10.25 12 10.25C12.4142 10.25 12.75 10.5858 12.75 11V12.75H14.5C14.9142 12.75 15.25 13.0858 15.25 13.5C15.25 13.9142 14.9142 14.25 14.5 14.25H12.75V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V14.25H9.5C9.08579 14.25 8.75 13.9142 8.75 13.5Z"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.1612 9.74679C21.558 12.233 21.5296 14.7686 21.077 17.2453C20.8747 18.353 19.9483 19.1833 18.8252 19.2636L17.1873 19.3808C13.7334 19.6279 10.2664 19.6279 6.81252 19.3808L5.29851 19.2725C4.10249 19.1869 3.11598 18.3027 2.90046 17.1232C2.46379 14.7333 2.38904 12.2913 2.6787 9.87915L2.95118 7.61004C3.11289 6.26343 4.25529 5.25 5.61158 5.25H7.90314C9.0209 5.25 9.95217 6.04846 10.1577 7.10612H18.4721C19.7785 7.10612 20.8912 8.05533 21.0971 9.34535L21.1612 9.74679ZM19.6015 16.9757C20.0235 14.6662 20.05 12.3017 19.6799 9.98322L19.6158 9.58178C19.5261 9.0197 19.0413 8.60612 18.4721 8.60612H9.75927C9.1742 8.60612 8.69991 8.13183 8.69991 7.54677C8.69991 7.10672 8.34319 6.75 7.90314 6.75H5.61158C5.01455 6.75 4.51167 7.19611 4.44048 7.78888L4.168 10.058C3.89627 12.3208 3.9664 14.6116 4.37603 16.8536C4.46856 17.36 4.89208 17.7396 5.40556 17.7763L6.91956 17.8846C10.3021 18.1266 13.6977 18.1266 17.0803 17.8846L18.7181 17.7674C19.1587 17.7359 19.5221 17.4102 19.6015 16.9757Z"
            fill="black"
          />
        </svg>

        <svg
          *ngIf="title === 'Edit Plan'"
          class="m-auto"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.1632 9.74679C21.56 12.233 21.5316 14.7686 21.079 17.2453C20.8767 18.353 19.9503 19.1833 18.8272 19.2636L17.1893 19.3808C13.7354 19.6279 10.2684 19.6279 6.81447 19.3808L5.30046 19.2725C4.10444 19.1869 3.11793 18.3027 2.90241 17.1232C2.46574 14.7333 2.39099 12.2913 2.68065 9.87915L2.95313 7.61004C3.11484 6.26343 4.25724 5.25 5.61353 5.25H7.90509C9.02285 5.25 9.95412 6.04846 10.1597 7.10612H18.4741C19.7805 7.10612 20.8932 8.05533 21.0991 9.34535L21.1632 9.74679ZM19.6035 16.9757C20.0255 14.6662 20.052 12.3017 19.6819 9.98322L19.6178 9.58178C19.5281 9.0197 19.0433 8.60612 18.4741 8.60612H9.76122C9.17615 8.60612 8.70186 8.13183 8.70186 7.54677C8.70186 7.10672 8.34514 6.75 7.90509 6.75H5.61353C5.0165 6.75 4.51362 7.19611 4.44243 7.78888L4.16995 10.058C3.89822 12.3208 3.96835 14.6116 4.37798 16.8536C4.47051 17.36 4.89403 17.7396 5.40751 17.7763L6.92151 17.8846C10.3041 18.1266 13.6997 18.1266 17.0823 17.8846L18.7201 17.7674C19.1607 17.7359 19.5241 17.4102 19.6035 16.9757Z"
            fill="black"
          />
          <g clip-path="url(#clip0_13216_49756)">
            <path
              d="M9.7035 13.8667L9.38683 15.2334C9.37591 15.2834 9.37628 15.3351 9.38793 15.3849C9.39957 15.4347 9.42219 15.4813 9.45414 15.5212C9.48609 15.5612 9.52655 15.5934 9.57258 15.6157C9.6186 15.638 9.66903 15.6498 9.72016 15.6501C9.74399 15.6525 9.768 15.6525 9.79183 15.6501L11.1668 15.3334L13.8068 12.7034L12.3335 11.2334L9.7035 13.8667Z"
              fill="black"
            />
            <path
              d="M14.6378 11.3865L13.6544 10.4032C13.5898 10.3388 13.5023 10.3027 13.4111 10.3027C13.3199 10.3027 13.2324 10.3388 13.1678 10.4032L12.6211 10.9498L14.0928 12.4215L14.6394 11.8748C14.6714 11.8427 14.6968 11.8045 14.714 11.7626C14.7312 11.7206 14.74 11.6757 14.7399 11.6303C14.7397 11.585 14.7306 11.5401 14.7131 11.4982C14.6956 11.4564 14.67 11.4184 14.6378 11.3865Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_13216_49756">
              <rect width="6" height="6" fill="white" transform="translate(9 10)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-4">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>

  <div>
    <p class="modal-header-text mb-0">{{ title }}</p>
    <p class="modal-sub-header-text mb-0 mt-2">
      {{
        title === 'Add New Plan'
          ? 'Add a new plan according to your liking.'
          : 'Edit the existing plan information.'
      }}
    </p>
    <p *ngIf="isErr" class="help-block ng-invalid text-danger">
      <small>{{ err }}</small>
    </p>
  </div>
  <div class="es-modal-body plan-dialog-bodyscroll">
    <form
      class="es-modal-form"
      [formGroup]="planForm"
      novalidate
      (ngSubmit)="title === 'Add New Plan' ? submit() : updatePlan()"
      autocomplete="off"
    >
      <div class="div-height">
        <label for="productCategory" class="form-label es-modal-form-label">Plan Label</label>
        <input
          type="text"
          class="form-control es-input"
          id="productCategory"
          aria-describedby="productCategory"
          formControlName="productCategory"
          [ngClass]="{
            'border-purple': f.productCategory.value.length > 0,
            'input-disable': title === 'Edit Plan'
          }"
          placeholder="Enter your Plan Label"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="
              (f.productCategory.touched && f.productCategory.pristine) ||
              (f.productCategory.errors && submitted)
            "
          >
            <span class="small" *ngIf="f.productCategory.errors?.maxlength?.requiredLength">
              Plan Label should have max.
              {{ f.productCategory.errors?.maxlength?.requiredLength }} characters.
            </span>
          </span>
        </div>
      </div>
      <div class="div-height">
        <label for="name" class="form-label es-modal-form-label">Plan Name</label>
        <input
          type="text"
          class="form-control es-input"
          id="name"
          aria-describedby="name"
          formControlName="name"
          [ngClass]="[f.name.valid ? 'border-purple' : '']"
          placeholder="Name your Plan"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.name.touched && f.name.pristine) || (f.name.errors && submitted)"
          >
            <span class="small" *ngIf="f.name.errors.required"> Plan Name is required! </span>
            <span class="small" *ngIf="f.name.errors?.maxlength?.requiredLength">
              Plan Name should have max. {{ f.name.errors?.maxlength?.requiredLength }} characters.
            </span>
          </span>
        </div>
      </div>
      <div class="div-height d-flex justify-content-between">
        <div class="input-width">
          <label for="dataSize" class="form-label es-modal-form-label">Data</label>
          <div class="d-flex" [ngClass]="{ 'input-disable': title === 'Edit Plan' }">
            <select
              class="form-control es-select"
              formControlName="dataUnit"
              [ngClass]="[f.dataSize.value !== 0 && f.dataSize.valid ? 'border-purple' : '']"
              [disabled]="true"
            >
              <option [value]="data" *ngFor="let data of dataDropdown">{{ data }}</option>
            </select>
            <input
              type="text"
              class="form-control es-input data-input number-input-right-padding"
              id="dataSize"
              aria-describedby="dataSize"
              formControlName="dataSize"
              (keypress)="numberOnly($event)"
              [ngClass]="[f.dataSize.value !== 0 && f.dataSize.valid ? 'border-purple' : '']"
              [disabled]="true"
            />
          </div>
          <div class="input-number-btn" [ngClass]="{ 'input-disable': title === 'Edit Plan' }">
            <span class="number-decremental" (click)="updateValue('dataSize', 'dec')"> - </span>
            <span class="number-incremental" (click)="updateValue('dataSize', 'inc')"> + </span>
          </div>
          <div class="mt-2">
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.dataSize.touched && f.dataSize.pristine) || (f.dataSize.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.dataSize.errors.required"> Data is required! </span>
            </span>
          </div>
        </div>
        <div class="input-width">
          <label for="voice" class="form-label es-modal-form-label">Validity</label>
          <div class="d-flex" [ngClass]="{ 'input-disable': title === 'Edit Plan' }">
            <select
              class="form-control es-select validity-select"
              formControlName="validityUnit"
              [ngClass]="[f.validity.value !== 0 && f.validity.valid ? 'border-purple' : '']"
            >
              <option [value]="validity" *ngFor="let validity of validityDropdown">
                {{ validity | titlecase }}
              </option>
            </select>
            <input
              type="text"
              class="form-control es-input data-input number-input-right-padding"
              id="validity"
              aria-describedby="validity"
              formControlName="validity"
              (keypress)="numberOnly($event)"
              [ngClass]="[f.validity.value !== 0 && f.validity.valid ? 'border-purple' : '']"
            />
          </div>
          <div class="input-number-btn" [ngClass]="{ 'input-disable': title === 'Edit Plan' }">
            <span class="number-decremental" (click)="updateValue('validity', 'dec')"> - </span>
            <span class="number-incremental" (click)="updateValue('validity', 'inc')"> + </span>
          </div>
          <div class="mt-2">
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.validity.touched && f.validity.pristine) || (f.validity.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.validity.errors.required"> Validity is required! </span>
            </span>
          </div>
        </div>
      </div>
      <div class="div-height d-flex justify-content-between">
        <div class="input-width">
          <label for="priceBundle" class="form-label es-modal-form-label">Price</label>
          <div class="d-flex relative">
            <input
              type="text"
              class="form-control es-input price-input number-input-right-padding"
              id="priceBundle"
              aria-describedby="priceBundle"
              formControlName="priceBundle"
              (keypress)="numberWithDecimalOnly($event)"
              [ngClass]="[f.priceBundle.value !== 0 && f.priceBundle.valid ? 'border-purple' : '']"
              appDecimalInput
            />
            <span class="form-label es-modal-form-label price-input-icon">{{ currencyType }}</span>
          </div>
          <div class="input-number-btn">
            <span class="number-decremental" (click)="updateValue('priceBundle', 'dec')"> - </span>
            <span class="number-incremental" (click)="updateValue('priceBundle', 'inc')"> + </span>
          </div>
          <div class="mt-2">
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.priceBundle.touched && f.priceBundle.pristine) ||
                (f.priceBundle.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.priceBundle.errors.required"> Price is required! </span>
            </span>
          </div>
        </div>
        <div class="input-width imsi-input">
          <label for="imsiType" class="form-label es-modal-form-label">IMSI Type</label>
          <div
            class="d-flex relative container-ng-select"
            [ngClass]="{ 'input-disable': title === 'Edit Plan' }"
          >
            <ng-select
              [items]="imsiTypeList"
              [multiple]="false"
              [closeOnSelect]="true"
              bindLabel="label"
              bindValue="_id"
              placeholder="Select IMSI Type"
              formControlName="imsiType"
              class="full-width"
              (change)="selectIMSI($event)"
            >
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="radio" /> {{ item.label }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="radio" name="name" [checked]="item$.selected" />
                {{ item.label }}
              </ng-template>
            </ng-select>
            <svg
              class="default"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.imsiType.touched && f.imsiType.pristine) || (f.imsiType.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.imsiType.errors.required"> IMSI Type is required! </span>
            </span>
          </div>
        </div>
      </div>

      <div class="div-height d-flex justify-content-between">
        <div class="input-width imsi-input">
          <label for="region" class="form-label es-modal-form-label">Activation Type</label>
          <div
            class="d-flex relative container-ng-select"
            [ngClass]="{ 'input-disable': title === 'Edit Plan' }"
          >
            <ng-select
              [items]="activationType"
              [multiple]="false"
              [closeOnSelect]="true"
              bindLabel="name"
              bindValue="name"
              formControlName="activationType"
              class="full-width"
              (change)="selectRegion($event)"
            >
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="radio" /> {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="radio" name="name" [checked]="item$.selected" />
                {{ item.name }}
              </ng-template>
            </ng-select>
            <svg
              class="default"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.activationType.touched && f.activationType.pristine) ||
                (f.activationType.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.activationType.errors.required">
                Activation Type is required!
              </span>
            </span>
          </div>
        </div>
        <div class="input-width imsi-input">
          <label for="region" class="form-label es-modal-form-label">Region</label>
          <div
            class="d-flex relative container-ng-select"
            [ngClass]="{ 'input-disable': title === 'Edit Plan' }"
          >
            <ng-select
              [items]="regionList"
              [multiple]="false"
              [closeOnSelect]="true"
              bindLabel="name"
              bindValue="_id"
              placeholder="Select the region for your plan"
              formControlName="region"
              class="full-width"
              (change)="selectRegion($event)"
              [class.disabled]="f.supportedCountries.value.length > 0"
            >
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="radio" /> {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="radio" name="name" [checked]="item$.selected" />
                {{ item.name }}
              </ng-template>
            </ng-select>
            <svg
              class="default"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="div-height d-flex justify-content-between">
        <div class="col-12 location-input">
          <label for="supportedCountries" class="form-label es-modal-form-label">Location</label>
          <div class="relative" [ngClass]="{ 'input-disable': title === 'Edit Plan' }">
            <ng-select
              [items]="countriesAlias"
              formControlName="supportedCountries"
              bindLabel="name"
              bindValue="name"
              [multiple]="true"
              [closeOnSelect]="false"
              placeholder="Search for countries / Multiple countries can be selected"
              (change)="onCountryChange($event)"
              [class.disabled]="f.region.value !== null"
              [compareWith]="compareCountries"
              [clearSearchOnAdd]="true"
            >
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice: 0 : 2">
                  <span class="ng-value-label"
                    ><img [src]="$any(item).flag" class="flag-style" [alt]="$any(item).name" />
                    {{ $any(item).name }}</span
                  >
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true"
                    >×</span
                  >
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                  <span
                    class="ng-value-label"
                    [tooltip]="displayContryList(items)"
                    placement="top"
                    delay="100"
                    offset="15"
                    >{{ items.length - 2 }} more...</span
                  >
                </div>
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div>
                  <img [src]="item.flag" class="flag-style" [alt]="item.name" /> {{ item.name }}
                  {{ item.iso3code }} ({{ item.dial_code }})
                </div>

                <div class="form-label es-modal-form-label unlimited">
                  <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" />
                  <span class="checkmark"></span>
                </div>
              </ng-template>
            </ng-select>

            <span class="ng-search">
              <svg
                class="img-fluid"
                fill="#707070"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="30px"
                height="30px"
              >
                <path
                  d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div class="div-height d-flex justify-content-between">
        <div class="input-width">
          <label for="dateEarliestActivation" class="form-label es-modal-form-label"
            >Earliest Activation*</label
          >
          <div class="d-flex input-icon" [ngClass]="{ 'input-disable': title === 'Edit Plan' }" style="margin-right: 12px;">
            <mat-form-field
              appearance="outline"
              [ngClass]="[f.dateEarliestActivation.valid ? 'border-purple' : '']"
            >
              <input
                matInput
                [matDatepicker]="dateEarliestActivation"
                formControlName="dateEarliestActivation"
                placeholder="DD-MM-YYYY"
                (click)="dateEarliestActivation.open()"
                [min]="currentDate"
                [readonly]="isEdit"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="dateEarliestActivation"
                [disabled]="isEdit"
              ></mat-datepicker-toggle>
              <mat-datepicker #dateEarliestActivation></mat-datepicker>
            </mat-form-field>
          </div>
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.dateEarliestActivation.touched && f.dateEarliestActivation.pristine) ||
                (f.dateEarliestActivation.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.dateEarliestActivation.errors.required">
                Earliest Activation is required!
              </span>
            </span>
          </div>
        </div>

        <div class="input-width">
          <label for="dateEarliestAvailable" class="form-label es-modal-form-label"
            >Earliest Available*</label
          >
          <div class="d-flex input-icon" [ngClass]="{ 'input-disable': title === 'Edit Plan' }" style="margin-right: 12px;">
            <mat-form-field
              appearance="outline"
              [ngClass]="[f.dateEarliestAvailable.valid ? 'border-purple' : '']"
            >
              <input
                matInput
                [matDatepicker]="dateEarliestAvailable"
                formControlName="dateEarliestAvailable"
                placeholder="DD-MM-YYYY"
                (click)="dateEarliestAvailable.open()"
                [min]="f.dateEarliestActivation.value"
                #matDatePickerEle
                [readonly]="isEdit"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="dateEarliestAvailable"
                [disabled]="isEdit"
              ></mat-datepicker-toggle>
              <mat-datepicker #dateEarliestAvailable></mat-datepicker>
            </mat-form-field>
          </div>
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.dateEarliestAvailable.touched && f.dateEarliestAvailable.pristine) ||
                (f.dateEarliestAvailable.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.dateEarliestAvailable.errors.required">
                Earliest Available is required!
              </span>
              <span class="small" *ngIf="f.dateEarliestAvailable.errors.matDatepickerMin">
                Earliest Available must be greater than Earliest Activation Date.
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="div-height d-flex justify-content-between">
        <div class="input-width">
          <label for="dateLatestAvailable" class="form-label es-modal-form-label"
            >Latest Available*</label
          >
          <div class="d-flex input-icon" [ngClass]="{ 'input-disable': title === 'Edit Plan' }" style="margin-right: 12px;">
            <mat-form-field
              appearance="outline"
              [ngClass]="[f.dateLatestAvailable.valid ? 'border-purple' : '']"
            >
              <input
                matInput
                [matDatepicker]="dateLatestAvailable"
                formControlName="dateLatestAvailable"
                placeholder="DD-MM-YYYY"
                (click)="dateLatestAvailable.open()"
                [min]="f.dateEarliestAvailable.value"
                [readonly]="isEdit"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="dateLatestAvailable"
                [disabled]="isEdit"
              ></mat-datepicker-toggle>
              <mat-datepicker #dateLatestAvailable></mat-datepicker>
            </mat-form-field>
          </div>
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.dateLatestAvailable.touched && f.dateLatestAvailable.pristine) ||
                (f.dateLatestAvailable.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.dateLatestAvailable.errors.required">
                Latest Available is required!
              </span>
              <span class="small" *ngIf="f.dateLatestAvailable.errors.matDatepickerMin">
                Latest Available must be greater than Earliest Available Date.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-2 gx-2 d-flex justify-content-between">
        <button class="btn btn-secondary btn-width" (click)="close()">Cancel</button>
        <button type="submit" class="btn btn-primary ms-auto btn-width">
          {{ title === 'Add New Plan' ? 'Submit' : 'Save Changes' }}
        </button>
      </div>
    </form>
  </div>
  <div class="es-modal-footer"></div>
</div>

<!-- Loading Spinner - Start -->
<ng-template #spinner class="loader-align">
  <app-loading-spinner></app-loading-spinner>
</ng-template>
<!-- Loading Spinner - End -->
