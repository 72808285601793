import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalFormat',
})
export class DecimalFormatPipe implements PipeTransform {
  transform(value: number): string | number {
    if (value % 1 !== 0) {
      // Check if the number has a decimal part
      return value.toFixed(2); // Format with 2 decimals
    }
    return value; // Return as-is if no decimals
  }
}
