<div
  class="admin-breadcrumb"
  *ngIf="
    customer &&
    customer.parentNames &&
    customer.parentNames.length > 0 &&
    userDetails.customerId !== selectedCustomer &&
    !userDetails.roles.includes('superAdmin')
  "
>
  <div
    style="--bs-breadcrumb-divider: '>'"
    aria-label="breadcrumb"
    class="d-flex align-items-center"
  >
    <ol class="breadcrumb mb-2">
      <li
        class="breadcrumb-item"
        aria-current="page"
        *ngFor="let tree of customer.parentNames; let last = last"
      >
        <span [ngClass]="{ 'active-bredcrumb': last }">{{ tree.name }} </span>
        <span class="breadcrumb-arrow-container" *ngIf="!last">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
          >
            <path
              d="M9 8C8.99119 7.70406 8.87659 7.45039 8.63859 7.2221L1.78061 0.787486C1.57786 0.601466 1.33986 0.5 1.04897 0.5C0.458374 0.5 0 0.939684 0 1.5062C0 1.77678 0.114594 2.03044 0.317336 2.22492L6.48776 8L0.317336 13.7751C0.114594 13.9696 0 14.2148 0 14.4938C0 15.0603 0.458374 15.5 1.04897 15.5C1.33105 15.5 1.57786 15.3985 1.78061 15.2125L8.63859 8.76945C8.88541 8.54961 9 8.29594 9 8Z"
              fill="#6365EF"
            />
          </svg>
        </span>
      </li>
    </ol>
  </div>
</div>
