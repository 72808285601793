import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * GET Method: Subscription List
   * @param itemsPerPage
   * @param currentPage
   * @returns
   */
  subscriptionList(itemsPerPage?: number, currentPage?: number) {
    if (itemsPerPage && currentPage) {
      return this.http.get(
        `${this.serverUrl}/subscriptions?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
      );
    }
    return this.http.get(`${this.serverUrl}/subscriptions`);
  }

  /**
   * POST Method: Subscription
   * @param data
   * @returns
   */
  createSubscription(data: any) {
    return this.http.post(`${this.serverUrl}/subscriptions`, data);
  }

  /**
   * PUT Method: Subscription
   * @param id
   * @param data
   * @returns
   */
  updateSubscription(id: any, data: any) {
    return this.http.put(`${this.serverUrl}/subscriptions/${id}`, data);
  }

  /**
   * DELETE Method: Subscription
   * @param id
   * @returns
   */
  deleteSubscription(id: any) {
    return this.http.delete(`${this.serverUrl}/subscriptions/${id}`);
  }

  /**
   * GET Method: Subscription's Data usage
   * @param subscriptionId
   * @returns
   */
  getSubscriptionDataUsage(subscriptionId: string) {
    return this.http.get(`${this.serverUrl}/activation/data-usage/${subscriptionId}`);
  }

  /**
   * GET Method: Subscription's plan details
   * @returns
   */
  getPlans() {
    return this.http.get(`${this.serverUrl}/subscription/plans`);
  }

  /**
   * GET Method: Subscription's refund status
   * @param subscriptionId
   * @returns
   */
  getRefund(subscriptionId: string) {
    return this.http.get(`${this.serverUrl}/subscription/refund/${subscriptionId}`);
  }

  resendPurchaseDetail(subscriptionId: string) {
    return this.http.post(
      `${this.serverUrl}/subscription/${subscriptionId}/resend-purchase-email`,
      {}
    );
  }

  resendRefundDetail(subscriptionId: string) {
    return this.http.post(
      `${this.serverUrl}/subscription/${subscriptionId}/resend-refund-email`,
      {}
    );
  }
}
