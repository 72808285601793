<div
  class="d-flex justify-content-between dashboard-head-flex align-items-center"
  [ngClass]="{ 'products-trs-head': featureHeader?.name === 'products TRS' }"
>
  <div class="dashboard-head-text" [ngClass]="{'dashboard-insider-head-text' : ['revenueReport', 'dataUsage', 'subscriberReport', 'subscriptionReport'].includes(featureHeader?.name ?? '')}">
    <div class="d-flex align-items-center">
      <!-- Back Arrow for All Agents List View -->
      <button class="btn" (click)="goToProducts()" *ngIf="featureHeader?.name === 'agent'">
        <svg
          class="cursor-pointer all-agents-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
        >
          <mask
            id="mask0_103_21499"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="26"
            height="26"
          >
            <rect width="26" height="26" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_103_21499)">
            <path
              d="M13.0007 21.6676L4.33398 13.0009L13.0007 4.33423L14.2736 5.5981L7.77354 12.0981H21.6673V13.9037H7.77354L14.2736 20.4037L13.0007 21.6676Z"
              fill="black"
            />
          </g>
        </svg>
      </button>

      <!-- Feature Title -->
      <h2 *ngIf="featureHeader?.name !== 'customer'; else customer">
        {{ featureHeader?.featureTitleName }}
      </h2>

      <!-- Customer List View Breadcrumb -->
      <ng-template #customer>
        <div class="sub-customer-view-header" style="border-bottom: unset">
          <div aria-label="breadcrumb" class="d-flex align-items-center">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                Customers
                <span
                  class="breadcrumb-arrow-container ms-2"
                  *ngIf="
                    customerDetails &&
                    customerDetails.parentNames &&
                    customerDetails.parentNames.length > 0 &&
                    userDetails.customerId === selectedCustomer
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="16"
                    viewBox="0 0 9 16"
                    fill="none"
                  >
                    <path
                      d="M9 8C8.99119 7.70406 8.87659 7.45039 8.63859 7.2221L1.78061 0.787486C1.57786 0.601466 1.33986 0.5 1.04897 0.5C0.458374 0.5 0 0.939684 0 1.5062C0 1.77678 0.114594 2.03044 0.317336 2.22492L6.48776 8L0.317336 13.7751C0.114594 13.9696 0 14.2148 0 14.4938C0 15.0603 0.458374 15.5 1.04897 15.5C1.33105 15.5 1.57786 15.3985 1.78061 15.2125L8.63859 8.76945C8.88541 8.54961 9 8.29594 9 8Z"
                      fill="#6365EF"
                    />
                  </svg>
                </span>
              </li>
              <ng-container
                *ngIf="
                  customerDetails &&
                  customerDetails.parentNames &&
                  customerDetails.parentNames.length > 0 &&
                  userDetails.customerId === selectedCustomer
                "
              >
                <li
                  class="breadcrumb-item"
                  aria-current="page"
                  *ngFor="let cust of customerDetails.parentNames; let last = last"
                >
                  <span [ngClass]="{ 'active-bredcrumb': last }"> {{ cust?.name }} </span>
                  <span class="breadcrumb-arrow-container ms-2" *ngIf="!last">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="16"
                      viewBox="0 0 9 16"
                      fill="none"
                    >
                      <path
                        d="M9 8C8.99119 7.70406 8.87659 7.45039 8.63859 7.2221L1.78061 0.787486C1.57786 0.601466 1.33986 0.5 1.04897 0.5C0.458374 0.5 0 0.939684 0 1.5062C0 1.77678 0.114594 2.03044 0.317336 2.22492L6.48776 8L0.317336 13.7751C0.114594 13.9696 0 14.2148 0 14.4938C0 15.0603 0.458374 15.5 1.04897 15.5C1.33105 15.5 1.57786 15.3985 1.78061 15.2125L8.63859 8.76945C8.88541 8.54961 9 8.29594 9 8Z"
                        fill="#6365EF"
                      />
                    </svg>
                  </span>
                </li>
              </ng-container>
            </ol>
          </div>
        </div>
      </ng-template>
    </div>

    <p class="trs-theme-editor-text" *ngIf="featureHeader?.name === 'products TRS'">
      Edit the theme and change logo and color for this TRS CRM and Web App according to your brand.
    </p>
  </div>
  <div>
    <!-- Add Button -->
    <button
      type="button"
      class="btn btn-dashboard-head d-flex"
      (click)="onAddButtonClick(featureHeader.name)"
      *ngIf="showAddButton && !refreshPlansMasterEnabled"
    >
      <span class="me-3 m-auto">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM14 10.75H10.75V14C10.75 14.41 10.41 14.75 10 14.75C9.59 14.75 9.25 14.41 9.25 14V10.75H6C5.59 10.75 5.25 10.41 5.25 10C5.25 9.59 5.59 9.25 6 9.25H9.25V6C9.25 5.59 9.59 5.25 10 5.25C10.41 5.25 10.75 5.59 10.75 6V9.25H14C14.41 9.25 14.75 9.59 14.75 10C14.75 10.41 14.41 10.75 14 10.75Z"
            fill="white"
          />
        </svg>
      </span>
      <span class="m-auto text-nowrap">{{ featureHeader?.featureButtonText }}</span>
    </button>

    <!-- Loyalty Enable/Disable Button -->
    <div class="email-custom-toggle" *ngIf="featureHeader?.name === 'loyalty'">
      <span
        class="btn btn-t-default"
        [ngClass]="{
          'btn-custom-active': isDefault
        }"
        (click)="changeMode()"
        >Enable</span
      >
      <span
        class="btn btn-custom"
        [ngClass]="{
          'btn-custom-active': !isDefault
        }"
        (click)="changeMode()"
        >Disable</span
      >
    </div>

    <!-- Refresh Plans Button -->
    <button
      type="button"
      class="btn btn-dashboard-head d-flex"
      (click)="onRefreshButtonClick()"
      *ngIf="featureHeader?.name === 'plan' && refreshPlansMasterEnabled"
    >
      <span class="me-3 m-auto">
        <svg
          width="14"
          height="20"
          viewBox="0 0 14 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.25065 13.3776C0.945095 12.8498 0.715929 12.3082 0.563151 11.7526C0.410373 11.197 0.333984 10.6276 0.333984 10.0443C0.333984 8.18316 0.979818 6.59983 2.27148 5.29427C3.56315 3.98872 5.13954 3.33594 7.00065 3.33594H7.14648L5.81315 2.0026L6.97982 0.835938L10.3132 4.16927L6.97982 7.5026L5.81315 6.33594L7.14648 5.0026H7.00065C5.61176 5.0026 4.43121 5.49219 3.45898 6.47135C2.48676 7.45052 2.00065 8.64149 2.00065 10.0443C2.00065 10.4054 2.04232 10.7595 2.12565 11.1068C2.20898 11.454 2.33398 11.7943 2.50065 12.1276L1.25065 13.3776ZM7.02148 19.1693L3.68815 15.8359L7.02148 12.5026L8.18815 13.6693L6.85482 15.0026H7.00065C8.38954 15.0026 9.5701 14.513 10.5423 13.5339C11.5145 12.5547 12.0007 11.3637 12.0007 9.96094C12.0007 9.59983 11.959 9.24566 11.8757 8.89844C11.7923 8.55122 11.6673 8.21094 11.5007 7.8776L12.7507 6.6276C13.0562 7.15538 13.2854 7.69705 13.4382 8.2526C13.5909 8.80816 13.6673 9.3776 13.6673 9.96094C13.6673 11.822 13.0215 13.4054 11.7298 14.7109C10.4382 16.0165 8.86176 16.6693 7.00065 16.6693H6.85482L8.18815 18.0026L7.02148 19.1693Z"
            fill="white"
          />
        </svg>
      </span>
      <span class="m-auto">Refresh Plans</span>
    </button>
  </div>
</div>
