import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  private countryList$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * GET Method: Region list
   * @returns
   */
  getAllRegions() {
    return this.http.get(`${this.serverUrl}/regions`);
  }

  /**
   * POST Method: create a new subscription at CRM
   * @param data
   * @returns
   */
  createSubscription(data: any) {
    return this.http.post(`${this.serverUrl}/subscriptions`, data);
  }

  /**
   * PUT Method: update an existing subscription details
   * @param id
   * @param data
   * @returns
   */
  updateSubscription(id: any, data: any) {
    return this.http.put(`${this.serverUrl}/subscriptions/${id}`, data);
  }

  /**
   * GET Method: Country list by region
   * @param groupIndex
   * @returns
   */
  getCountriesByGroup(groupIndex: number) {
    return this.http.get(`${this.serverUrl}/countries/group/${groupIndex}`);
  }

  /**
   * GET Method: Country list and store to Observable
   * @returns
   */
  getCountries() {
    if (this.countryList$?.value) {
      return this.countryList$.asObservable();
    } else {
      return this.http.get(`${this.serverUrl}/countries`).pipe(
        map((result: any) => {
          this.countryList$.next(result);
          return result;
        })
      );
    }
  }
}
