import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-brand-colors',
  templateUrl: './brand-colors.component.html',
  styleUrls: ['./brand-colors.component.scss'],
})
export class BrandColorsComponent {
  @Input() edit!: boolean;
  @Input() brandColors!: any[];
  @Input() component!: String;
  @Input() isDarkTheme!: boolean;
  @Input() EventLog!: (component: any) => void;
  @Input() configColors!: any[];
  @Output() setSelectedTheme = new EventEmitter();
  crmPallet = require('../../../../assets/theme-editor/pallet.json');
  trsPallet = require('../../../../assets/theme-editor/trs-theme-editor-pallet.json');
  pallet: any;
  @Input() selectedTheme!: any;

  colorPickerWidth = '240';
  isBrandColor: boolean = true;

  constructor(private renderer: Renderer2, private elRef: ElementRef) {
    console.log(window.location.pathname);
    if(window.location.pathname == '/products/trs-theme-editor') {
      this.pallet = this.trsPallet;
    } else if(window.location.pathname == '/theme-editor') {
      this.pallet = this.crmPallet;
    }
  } 

  getThemeColors(theme: any) {
    if (!this.edit) return;
    this.setSelectedTheme.emit({ theme, component: this.component });
  }

  colorPickerClose(color: any) {
    if (this.EventLog) {
      this.EventLog(color);
    }
  }

  toggleColorPicker(index: any) {
    this.brandColors.forEach((ele, i) => {
      if (i != index) {
        ele.isColorPickerOpen = false;
      }
    });
  }

  toggleBrandColor() {
    if (this.edit) {
      this.isBrandColor = !this.isBrandColor;
    }

    this.brandColors.forEach((ele, i) => {
      ele.isColorPickerOpen = false;
    });
  }
}
