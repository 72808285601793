<div class="es-modal add-edit-modal" [ngStyle]="{ width: data.icon === 'confirm' ? '400px' : '' }">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 confirmation-icon" *ngIf="data.icon" [class]="data.icon + '-icon'">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="data.icon === 'trash'"
        >
          <rect class="rect1" x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
          <path
            d="M32 22V21.2C32 20.0799 32 19.5198 31.782 19.092C31.5903 18.7157 31.2843 18.4097 30.908 18.218C30.4802 18 29.9201 18 28.8 18H27.2C26.0799 18 25.5198 18 25.092 18.218C24.7157 18.4097 24.4097 18.7157 24.218 19.092C24 19.5198 24 20.0799 24 21.2V22M26 27.5V32.5M30 27.5V32.5M19 22H37M35 22V33.2C35 34.8802 35 35.7202 34.673 36.362C34.3854 36.9265 33.9265 37.3854 33.362 37.673C32.7202 38 31.8802 38 30.2 38H25.8C24.1198 38 23.2798 38 22.638 37.673C22.0735 37.3854 21.6146 36.9265 21.327 36.362C21 35.7202 21 34.8802 21 33.2V22"
            stroke="#D92D20"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            class="rect2"
            x="4"
            y="4"
            width="48"
            height="48"
            rx="24"
            stroke="#FEF3F2"
            stroke-width="8"
          />
        </svg>

        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="data.icon === 'cancel'"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8" class="rect2"/>
          <path
            d="M28 18L17 37H39M28 22L35.53 35H20.47M27 26V30H29V26M27 32V34H29V32"
            fill="#D92D20"
          />
        </svg>

        <svg
          *ngIf="data.icon === 'confirm'"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#9FFFD1" />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#2D9563" stroke-width="8" />
          <mask
            id="mask0_5829_39733"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="16"
            y="16"
            width="24"
            height="24"
          >
            <rect x="16" y="16" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_5829_39733)">
            <path
              d="M26.6 32C26.6 30.65 26.7209 29.6792 26.9625 29.0875C27.2042 28.4958 27.7167 27.85 28.5 27.15C29.1834 26.55 29.7042 26.0292 30.0625 25.5875C30.4209 25.1458 30.6 24.6417 30.6 24.075C30.6 23.3917 30.3709 22.825 29.9125 22.375C29.4542 21.925 28.8167 21.7 28 21.7C27.15 21.7 26.5042 21.9583 26.0625 22.475C25.6209 22.9917 25.3084 23.5167 25.125 24.05L22.55 22.95C22.9 21.8833 23.5417 20.9583 24.475 20.175C25.4084 19.3917 26.5834 19 28 19C29.75 19 31.0959 19.4875 32.0375 20.4625C32.9792 21.4375 33.45 22.6083 33.45 23.975C33.45 24.8083 33.2709 25.5208 32.9125 26.1125C32.5542 26.7042 31.9917 27.375 31.225 28.125C30.4084 28.9083 29.9125 29.5042 29.7375 29.9125C29.5625 30.3208 29.475 31.0167 29.475 32H26.6ZM28 38C27.45 38 26.9792 37.8042 26.5875 37.4125C26.1959 37.0208 26 36.55 26 36C26 35.45 26.1959 34.9792 26.5875 34.5875C26.9792 34.1958 27.45 34 28 34C28.55 34 29.0209 34.1958 29.4125 34.5875C29.8042 34.9792 30 35.45 30 36C30 36.55 29.8042 37.0208 29.4125 37.4125C29.0209 37.8042 28.55 38 28 38Z"
              fill="#2D9563"
            />
          </g>
        </svg>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close(false)"></a>
    </div>
  </div>

  <div>
    <p class="modal-header-text mb-0" *ngIf="data.title">{{ data.title }}</p>
    <p class="modal-sub-header-text mb-0 mt-2">{{ message }}</p>
  </div>

  <div class="es-modal-footer d-flex mt-4" *ngIf="data.buttonGroup.length > 0; else showDefault">
    <button
      type="submit"
      *ngFor="let btn of data.buttonGroup"
      class="btn"
      [class]="btn.cssClass"
      (click)="btn.title === 'Cancel' ? close(false) : close(btn.value)"
    >
      {{ btn.title }}
    </button>
  </div>
  <ng-template #showDefault>
    <div class="es-modal-footer">
      <button type="submit" class="btn btn-dark" (click)="close(false)">Cancel</button>
    </div>
  </ng-template>
</div>
