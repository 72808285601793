import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * POST Method: Invite Agent
   * @param agentDetails
   * @returns
   */
  inviteAgent(agentDetails: any) {
    return this.http.post(`${this.serverUrl}/agents/invite`, agentDetails);
  }

  /**
   * GET Method: Agent list with pagination
   * @param itemsPerPage
   * @param currentPage
   * @param customerId
   * @returns
   */
  getAgentList(itemsPerPage?: number, currentPage?: number, customerId?: string) {
    if (itemsPerPage && currentPage && customerId) {
      return this.http.get(
        `${this.serverUrl}/agents?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&customerId=${customerId}`
      );
    } else if (itemsPerPage && currentPage) {
      return this.http.get(
        `${this.serverUrl}/agents?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
      );
    } else if (customerId) {
      return this.http.get(`${this.serverUrl}/agents?customerId=${customerId}`);
    } else {
      return this.http.get(`${this.serverUrl}/agents`);
    }
  }
}
