import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showentries',
})
export class ShowentriesPipe implements PipeTransform {
  transform(
    currentPage: number,
    totalEntries: number,
    itemsPerPage: number,
    entriesPerPage: number
  ): string {
    let count: number = 0;

    if (itemsPerPage - entriesPerPage == 0) {
      count = itemsPerPage * (currentPage + 1);
    }

    if (itemsPerPage - entriesPerPage > 0) {
      count = totalEntries;
    }

    const formattedCount = count.toLocaleString();
    const formattedTotalEntries = totalEntries.toLocaleString();

    return `Showing ${formattedCount} of ${formattedTotalEntries} entries`;
  }
}
