import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimalInput]',
})
export class DecimalInputDirective {
  private regex: RegExp = new RegExp(/^\d+(\.\d{0,2})?$/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !this.regex.exec(next)) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    // Format value to two decimal places when leaving the input
    if (value && this.regex.exec(value)) {
      const formattedValue = parseFloat(value).toFixed(2);
      this.el.nativeElement.value = formattedValue;
    }
  }
}
