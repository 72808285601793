<nav class="navbar navbar-expand-lg main-navbar justify-content-between">
  <!-- Search Input Div: Left -->
  <div class="d-flex search-form" *ngIf="showSearch">
    <form [formGroup]="searchform">
      <input
        class="form-control"
        type="text"
        formControlName="searchTerm"
        name="searchTerm"
        [placeholder]="placeHolder"
        autocomplete="off"
      />
    </form>
    <span class="search-icon-div">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3496 14.3562C14.2563 14.4481 14.1306 14.4998 13.9996 14.5C13.8668 14.4994 13.7393 14.448 13.6434 14.3562L10.9434 11.65C9.80622 12.6051 8.34425 13.0844 6.86236 12.9878C5.38046 12.8912 3.99306 12.2263 2.98951 11.1316C1.98596 10.037 1.44375 8.59717 1.47597 7.11248C1.50818 5.62779 2.11233 4.21286 3.16241 3.16277C4.21249 2.11269 5.62743 1.50855 7.11212 1.47633C8.59681 1.44412 10.0366 1.98633 11.1313 2.98988C12.2259 3.99343 12.8908 5.38083 12.9874 6.86273C13.084 8.34462 12.6047 9.80659 11.6496 10.9437L14.3496 13.6437C14.3969 13.6903 14.4344 13.7457 14.46 13.8069C14.4856 13.868 14.4988 13.9337 14.4988 14C14.4988 14.0663 14.4856 14.1319 14.46 14.1931C14.4344 14.2542 14.3969 14.3097 14.3496 14.3562ZM7.24961 12C8.18907 12 9.10743 11.7214 9.88857 11.1995C10.6697 10.6775 11.2785 9.93567 11.638 9.06772C11.9976 8.19977 12.0916 7.24471 11.9083 6.3233C11.7251 5.40189 11.2727 4.55552 10.6084 3.89122C9.94407 3.22692 9.0977 2.77452 8.17629 2.59124C7.25488 2.40797 6.29981 2.50203 5.43186 2.86155C4.56391 3.22106 3.82206 3.82988 3.30013 4.61102C2.77819 5.39215 2.49961 6.31051 2.49961 7.24998C2.50126 8.50925 3.00224 9.71647 3.89268 10.6069C4.78312 11.4973 5.99034 11.9983 7.24961 12Z"
          fill="#000"
          fill-opacity="0.72"
        />
      </svg>
    </span>
  </div>

  <!-- Filter, Theme toggle, login div -->
  <div class="d-flex ms-auto" id="navbarSupportedContent">
    <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
      <li class="nav-item my-auto">
        <div class="dark-light-toggle">
          <label class="switch">
            <input type="checkbox" (click)="toggleTheme()" />
            <span class="slider"></span>

            <div class="light-mode-icon d-flex" *ngIf="!isDarkTheme">
              <!-- <img class="m-auto" src="/assets/images/dashboard/icon/light-mode.svg" alt=""> -->
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 0C7.26972 0 7.48837 0.218652 7.48837 0.488372V1.13953C7.48837 1.40926 7.26972 1.62791 7 1.62791C6.73028 1.62791 6.51163 1.40926 6.51163 1.13953V0.488372C6.51163 0.218652 6.73028 0 7 0Z"
                  fill="#E9EAF0"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.25581 7C3.25581 4.93214 4.93214 3.25581 7 3.25581C9.06786 3.25581 10.7442 4.93214 10.7442 7C10.7442 9.06786 9.06786 10.7442 7 10.7442C4.93214 10.7442 3.25581 9.06786 3.25581 7ZM7 4.23256C5.47158 4.23256 4.23256 5.47158 4.23256 7C4.23256 8.52842 5.47158 9.76744 7 9.76744C8.52842 9.76744 9.76744 8.52842 9.76744 7C9.76744 5.47158 8.52842 4.23256 7 4.23256Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M2.74092 2.05027C2.5502 1.85955 2.24098 1.85955 2.05026 2.05027C1.85954 2.24099 1.85954 2.55021 2.05026 2.74093L2.5107 3.20137C2.70142 3.39209 3.01064 3.39209 3.20137 3.20137C3.39209 3.01065 3.39209 2.70143 3.20137 2.51071L2.74092 2.05027Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M14 7C14 7.26972 13.7813 7.48837 13.5116 7.48837H12.8605C12.5907 7.48837 12.3721 7.26972 12.3721 7C12.3721 6.73028 12.5907 6.51163 12.8605 6.51163H13.5116C13.7813 6.51163 14 6.73028 14 7Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M11.9497 2.74092C12.1405 2.5502 12.1405 2.24098 11.9497 2.05026C11.759 1.85954 11.4498 1.85954 11.2591 2.05026L10.7986 2.5107C10.6079 2.70142 10.6079 3.01064 10.7986 3.20137C10.9893 3.39209 11.2986 3.39209 11.4893 3.20137L11.9497 2.74092Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M7 12.3721C7.26972 12.3721 7.48837 12.5907 7.48837 12.8605V13.5116C7.48837 13.7813 7.26972 14 7 14C6.73028 14 6.51163 13.7813 6.51163 13.5116V12.8605C6.51163 12.5907 6.73028 12.3721 7 12.3721Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M11.4893 10.7986C11.2986 10.6079 10.9894 10.6079 10.7987 10.7986C10.608 10.9894 10.608 11.2986 10.7987 11.4893L11.2591 11.9497C11.4498 12.1405 11.7591 12.1405 11.9498 11.9497C12.1405 11.759 12.1405 11.4498 11.9498 11.2591L11.4893 10.7986Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M1.62791 7C1.62791 7.26972 1.40926 7.48837 1.13953 7.48837H0.488372C0.218652 7.48837 0 7.26972 0 7C0 6.73028 0.218652 6.51163 0.488372 6.51163H1.13953C1.40926 6.51163 1.62791 6.73028 1.62791 7Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M3.20132 11.4893C3.39204 11.2986 3.39204 10.9894 3.20132 10.7986C3.0106 10.6079 2.70138 10.6079 2.51066 10.7986L2.05022 11.2591C1.8595 11.4498 1.8595 11.759 2.05022 11.9497C2.24094 12.1405 2.55016 12.1405 2.74088 11.9497L3.20132 11.4893Z"
                  fill="#E9EAF0"
                />
              </svg>
            </div>
            <div class="dark-mode-icon" *ngIf="isDarkTheme">
              <!-- <img src="/assets/images/dashboard/icon/dark-mode.svg" alt=""> -->
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.71964 1.05411C3.10552 1.23711 1.04167 3.41573 1.04167 6.07639C1.04167 8.85699 3.29579 11.1111 6.07639 11.1111C8.30408 11.1111 10.1942 9.66402 10.8575 7.6588C10.347 7.86969 9.78758 7.98611 9.20139 7.98611C6.80432 7.98611 4.86111 6.0429 4.86111 3.64583C4.86111 2.67421 5.18064 1.77715 5.71964 1.05411ZM0 6.07639C0 2.72049 2.72049 0 6.07639 0C6.38862 0 6.69568 0.0235945 6.9958 0.0691799C7.20341 0.100714 7.37178 0.253843 7.42281 0.457543C7.47384 0.661242 7.39755 0.875661 7.22932 1.00135C6.42308 1.60371 5.90278 2.56395 5.90278 3.64583C5.90278 5.46761 7.37962 6.94444 9.20139 6.94444C9.98905 6.94444 10.711 6.66903 11.2782 6.20878C11.4411 6.07653 11.6676 6.05502 11.8525 6.15424C12.0375 6.25345 12.1448 6.45399 12.1248 6.66292C11.8294 9.74389 9.23452 12.1528 6.07639 12.1528C2.72049 12.1528 0 9.43229 0 6.07639Z"
                  fill="#24272C"
                />
              </svg>
            </div>
          </label>
        </div>
      </li>
      <li>
        <div class="raise-ticket">
          <a [href]="supportLink" target="_blank">
            <img
              class="help-icon"
              [src]="'/assets/images/navbar/Help_' + (!isDarkTheme ? 'Dark.svg' : '.svg')"
              alt=""
              [tooltip]="tooltip"
              placement="bottom"
              delay="100"
              offset="5"
            />
          </a>
        </div>
      </li>
    </ul>

    <div class="d-flex user-div">
      <div class="user-name-div me-4 my-auto">Hi, {{ userDetails?.firstName }}</div>
      <div class="dropdown">
        <a
          class="btn btn-dropdown"
          href="#"
          role="button"
          id="customerDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div class="user-profile-div">
            <img [src]="userDetails?.profileImageURL || '/assets/images/navbar/user.png'" alt="" />
          </div>
        </a>

        <ul class="dropdown-menu" aria-labelledby="customerDropdown">
          <li><a class="dropdown-item" (click)="signout()">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>
