import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { DialogComponent, DialogService } from '../../service/dialog';
import { UploadInventoryComponent } from '../upload-inventory/upload-inventory.component';

@Component({
  selector: 'app-report-alert',
  templateUrl: './report-alert.component.html',
  styleUrls: ['./report-alert.component.scss'],
})
export class ReportAlertComponent implements OnInit {
  title: string = 'Something went wrong!';
  body: string =
    'Oops, it seems like we have run into an error. Please rectify the problems and try again';
  dialogRef: DialogComponent;
  titleArr: string[] = ['Successful!', 'Error', 'Info', 'Warning'];
  type!: string;
  buttonGroup = [
    { cssClass: 'btn-secondary', title: 'Back', value: false },
    { cssClass: 'ms-auto', title: 'Try Again', value: false },
    { cssClass: 'ms-auto', title: 'Close', value: false },
    { cssClass: 'ms-auto', title: 'Continue', value: false },
    { cssClass: 'ms-auto', title: 'Upload Another', value: false },
  ];

  constructor(
    private viewContainer: ViewContainerRef,
    private dialogService: DialogService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.title = this.dialogRef.context?.title;
    this.body = this.dialogRef.context?.body;
    this.type = this.dialogRef.context?.type;

    switch (true) {
      case this.title == 'Error':
        this.buttonGroup = this.buttonGroup.filter(
          (x) => x.title == 'Back' || x.title == 'Try Again'
        );
        break;
      case this.title == 'Successful!' && this.type == 'Inventory':
        this.buttonGroup = this.buttonGroup.filter(
          (x) => x.title == 'Continue' || x.title == 'Upload Another'
        );
        break;
    }
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(openUploadInvenotry?: any): void {
    this.dialogRef.close.emit();

    if (openUploadInvenotry) {
      this.dialogService
        .openModal(UploadInventoryComponent, {
          cssClass: 'modal-sm',
          context: { data: {}, title: 'Upload Inventory' },
        })
        .instance.close.subscribe(() => {});
    }
  }
}
