import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  crmConfig = this._localStorage?.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig?.serverUrl : environment.serverUrl;
  private _currentUser$ = new BehaviorSubject<any>(undefined);
  private _userRoles$ = new BehaviorSubject<Array<string>>([]);
  inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * GET Method: User me (Logged-in user details)
   * @returns
   */
  getUserDetails() {
    return this.http.get(`${this.serverUrl}/users/me`);
  }

  /**
   * Get current userDetails from observable
   * @returns
   */
  getCurrentUser() {
    return this._currentUser$.asObservable();
  }

  /**
   * Set current userDetails to observable
   * @param userDetails
   */
  setCurrentUser(userDetails: any) {
    this._userRoles$.next(userDetails?.roles || []);
    this._currentUser$.next(userDetails);
  }

  /**
   * Validate user role
   * @returns
   */
  validateUserRole() {
    return this._userRoles$.asObservable();
  }

  /**
   * POST Method: Send invite to a new user
   * @param data
   * @returns
   */
  createUsers(data: any) {
    const finalUrl = data.customerId
      ? `${this.serverUrl}/users/invite?customerId=${data.customerId}`
      : `${this.serverUrl}/users/invite`;
    return this.http.post(finalUrl, data);
  }

  /**
   * GET Method: User list with/without pagination
   * @param customerId
   * @param itemsPerPage
   * @param currentPage
   * @returns
   */
  getAllUsers(customerId: any, itemsPerPage?: number, currentPage?: number) {
    if (itemsPerPage && currentPage) {
      return this.http.get(
        `${this.serverUrl}/users?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}${
          customerId ? 'customerId=' + customerId : ''
        }`
      );
    } else if (customerId) {
      return this.http.get(`${this.serverUrl}/users?customerId=${customerId}`);
    } else {
      return this.http.get(`${this.serverUrl}/users`);
    }
  }

  /**
   * PUT Method: Update user details
   * @param id
   * @param data
   * @returns
   */
  updateUser(id: any, data: any) {
    return this.http.put(`${this.serverUrl}/users/${id}`, data);
  }

  /**
   * DELETE Method: Delete user by id
   * @param id
   * @returns
   */
  deleteUser(id: any) {
    return this.http.delete(`${this.serverUrl}/users/${id}`);
  }

  /**
   * PUT Method: Update Super admin details
   * @param data
   * @returns
   */
  updateSuperAdminUser(data: any) {
    return this.http.put(`${this.serverUrl}/users/update`, data);
  }

  /**
   * PUT Method: Update Super admind password
   * @param data
   * @returns
   */
  updateSuperAdminPassword(data: any) {
    return this.http.put(`${this.serverUrl}/users/changePassword`, data);
  }
}
