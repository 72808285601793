export class FeatureHeader {
  public name: string;
  public featureTitleName: string;
  public featureButtonText: string;

  constructor(name: string, titleName: string, buttonText: string) {
    this.name = name;
    this.featureTitleName = titleName;
    this.featureButtonText = buttonText;
  }
}
