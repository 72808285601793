import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../service/alert.service';
import { DialogComponent } from '../../service/dialog';
import { PlansService } from '../../service/plans.service';
import { RegionsService } from '../../service/regions.service';
import { UsersService } from '../../service/users.service';

@Component({
  selector: 'app-user-mgmt',
  templateUrl: './user-mgmt.component.html',
  styleUrls: ['./user-mgmt.component.scss'],
})
export class UserMgmtComponent implements OnInit {
  dialogRef: DialogComponent;
  data: any;
  userForm: any;
  title: string = 'Add New User';
  regionList: any = [];
  planList: any = [];
  submitted = false;
  customerId: any;
  isSuperAdmin: boolean = true;

  constructor(
    private viewContainer: ViewContainerRef,
    private regionService: RegionsService,
    private planService: PlansService,
    private usersService: UsersService,
    private alertService: AlertService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.data = this.dialogRef.context?.data;
    this.title = this.dialogRef.context?.title;
    this.customerId = this.dialogRef.context?.customerId;
    this.createuserForm();
  }

  /**
   * @description Get all regions
   */
  getAllRegions(): void {
    this.regionService.getAllRegions().subscribe({
      next: (res: any) => {
        this.regionList = res;
      },
      error: (err) => {
        this.alertService.error(err.error.message, err.status);
      },
    });
  }

  /**
   * @description Get All plans
   */
  getAlPlans(): void {
    this.planService.listPlans().subscribe({
      next: (res) => {
        this.planList = res;
      },
      error: (err) => {
        this.alertService.error(err.error.message, err.status);
      },
    });
  }

  /**
   * @description Initiate user form
   */
  createuserForm() {
    const countryCode =
      this.data?.countryCode && this.data?.countryCode?.trim() != ''
        ? this.data?.countryCode + ' '
        : '';
    const value =
      this.data?.roles?.length > 0
        ? this.data?.roles[0]
        : this.isSuperAdmin && !this.customerId
          ? 'superAdmin'
          : 'admin';
    this.userForm = new UntypedFormGroup({
      email: new UntypedFormControl(
        this.title === 'Edit User' ? { value: this.data?.email, disabled: true } : this.data?.email,
        [
          Validators.required,
          Validators.email,
          Validators.minLength(7),
          Validators.maxLength(254),
          Validators.pattern('^[\\w\\-\\.]+@([\\w\\-]+\\.)+[\\w\\-]{2,20}$'),
        ]
      ),
      firstName: new UntypedFormControl(this.data?.firstName, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern('^[a-zA-Z]*$'),
      ]),
      lastName: new UntypedFormControl(this.data?.lastName, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern('^[a-zA-Z]*$'),
      ]),
      mobile: new UntypedFormControl(`${countryCode}${this.data?.mobile || ''}`, [
        Validators.required,
        Validators.minLength(countryCode.length + 7),
        Validators.maxLength(countryCode.length + 15),
      ]),
      role: new UntypedFormControl(value, [Validators.required]),
    });
    this.userForm.controls.mobile.valueChanges.subscribe((phoneNumber: any) => {
      const tempNo = phoneNumber?.split(' ') || [];
      if (tempNo.length === 2) {
        const countryCodeLength = tempNo[0].length + 1;
        this.userForm.controls.mobile.setValidators([
          Validators.required,
          Validators.minLength(countryCodeLength + 7),
          Validators.maxLength(countryCodeLength + 15),
        ]);
      }
    });
  }

  get f() {
    return this.userForm.controls;
  }

  /**
   * @description Condition call to create and updadte user
   */
  submit() {
    if (this.title === 'Edit User') {
      this.update();
    } else {
      this.createUser();
    }
  }

  /**
   * @description Create user
   *
   * @returns
   */
  createUser() {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }

    const user = this.userForm.value;
    const phoneNumberData = user.mobile.split(' ');
    if (phoneNumberData.length == 1) {
      phoneNumberData[0] = '+91';
      phoneNumberData[1] = user.mobile;
    }
    this.usersService
      .createUsers({
        ...user,
        mobile: phoneNumberData[1],
        countryCode: phoneNumberData[0],
        customerId: this.customerId,
      })
      .subscribe({
        next: (res: any) => {
          this.dialogRef.close.emit({ ...res, name: user.firstName + ' ' + user.lastName });
        },
        error: (err) => {
          this.alertService.error(err.error.message, err.status);
        },
      });
  }

  /**
   * @description Update user
   *
   * @returns
   */
  update() {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }

    const user = this.userForm.value;
    const phoneNumberData = user.mobile.split(' ');
    if (phoneNumberData.length == 1) {
      phoneNumberData[0] = '+91';
      phoneNumberData[1] = user.mobile;
    }
    this.usersService
      .updateUser(this.data._id, {
        ...user,
        mobile: phoneNumberData[1],
        countryCode: phoneNumberData[0],
        customerId: this.customerId,
      })
      .subscribe({
        next: (res: any) => {
          this.dialogRef.close.emit(res);
        },
        error: (err) => {
          this.alertService.error(err.error.message, err.status);
        },
      });
  }

  /**
   * @description Restrict user to enter alphabets in mobile field
   *
   * @param event
   * @returns
   */
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit();
  }
}
