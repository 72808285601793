<div class="es-modal add-edit-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3" class="add-edit-icon">
        <div class="mb-3">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="title === 'Add New User' || title === 'Invite User'"
            [class]="title === 'Add New User' ? 'add-user-icon' : 'edit-user-icon'"
          >
            <rect class="rect1" x="4" y="4" width="48" height="48" rx="24" fill="#33A85B" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.1875 23.8751C23.1875 21.7235 24.9317 19.9792 27.0833 19.9792C29.2349 19.9792 30.9792 21.7235 30.9792 23.8751C30.9792 26.0267 29.2349 27.7709 27.0833 27.7709C24.9317 27.7709 23.1875 26.0267 23.1875 23.8751ZM27.0833 21.3542C25.6911 21.3542 24.5625 22.4829 24.5625 23.8751C24.5625 25.2673 25.6911 26.3959 27.0833 26.3959C28.4756 26.3959 29.6042 25.2673 29.6042 23.8751C29.6042 22.4829 28.4756 21.3542 27.0833 21.3542Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.9785 32.5833C19.9785 30.6847 21.5175 29.1458 23.416 29.1458H23.7285C23.8976 29.1458 24.0656 29.1725 24.2264 29.225L25.0198 29.4841C26.3603 29.9217 27.8051 29.9217 29.1455 29.4841L29.9389 29.225C30.0997 29.1725 30.2677 29.1458 30.4369 29.1458H30.7493C32.6479 29.1458 34.1868 30.6847 34.1868 32.5833V33.6725C34.1868 34.363 33.6864 34.9516 33.005 35.0629C29.0828 35.7033 25.0826 35.7033 21.1603 35.0629C20.4789 34.9516 19.9785 34.363 19.9785 33.6725V32.5833ZM23.416 30.5208C22.2769 30.5208 21.3535 31.4442 21.3535 32.5833V33.6725C21.3535 33.6891 21.3655 33.7032 21.3819 33.7059C25.1574 34.3224 29.008 34.3224 32.7834 33.7059C32.7998 33.7032 32.8118 33.6891 32.8118 33.6725V32.5833C32.8118 31.4442 31.8884 30.5208 30.7493 30.5208H30.4369C30.4127 30.5208 30.3887 30.5246 30.3657 30.532L29.5723 30.7912C27.9546 31.3194 26.2107 31.3194 24.593 30.7912L23.7996 30.532C23.7767 30.5246 23.7526 30.5208 23.7285 30.5208H23.416Z"
              fill="white"
            />
            <path
              d="M34.8757 22.7292C35.2553 22.7292 35.5632 23.0371 35.5632 23.4167V25.0209H37.1673C37.547 25.0209 37.8548 25.3287 37.8548 25.7084C37.8548 26.0881 37.547 26.3959 37.1673 26.3959H35.5632V28.0001C35.5632 28.3798 35.2553 28.6876 34.8757 28.6876C34.496 28.6876 34.1882 28.3798 34.1882 28.0001V26.3959H32.584C32.2043 26.3959 31.8965 26.0881 31.8965 25.7084C31.8965 25.3287 32.2043 25.0209 32.584 25.0209H34.1882V23.4167C34.1882 23.0371 34.496 22.7292 34.8757 22.7292Z"
              fill="white"
            />
            <rect
              class="rect2"
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#A3FFD3"
              stroke-width="8"
            />
          </svg>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="title === 'Edit User'"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#A5A6EE" />
            <path
              d="M24.5828 35.48H22.5828C22.1661 35.48 21.7495 35.23 21.5828 34.8967C21.3328 34.5633 21.3328 34.23 21.4995 33.8133C22.4995 31.48 24.7495 29.8967 27.3328 29.8967C29.9162 29.8967 32.0828 27.73 32.0828 25.1466C32.0828 22.5633 29.9162 20.48 27.2495 20.48C24.6661 20.48 22.4995 22.6466 22.4995 25.23C22.4995 26.73 23.1661 28.0633 24.2495 28.8967C22.3328 29.6467 20.6661 31.23 19.8328 33.23C19.4995 34.0633 19.5828 35.0633 20.0828 35.8133C20.6661 36.6467 21.4995 37.1467 22.4995 37.1467H24.4995C24.9995 37.1467 25.3328 36.8133 25.3328 36.3133C25.3328 35.8133 25.0828 35.48 24.5828 35.48ZM24.1661 25.23C24.1661 23.5633 25.5828 22.1466 27.2495 22.1466C28.9162 22.1466 30.3328 23.5633 30.3328 25.23C30.3328 26.8967 28.9162 28.3133 27.2495 28.3133C25.5828 28.3133 24.1661 26.8967 24.1661 25.23Z"
              fill="white"
            />
            <path
              d="M35.6673 27.98C34.834 27.1466 33.4173 27.1466 32.584 27.98L28.1673 32.3966C27.1673 33.3966 26.584 34.8133 26.584 36.3133C26.584 36.8133 26.9173 37.1466 27.4173 37.1466C28.9173 37.1466 30.2507 36.5633 31.334 35.5633L35.7507 31.1466C36.1673 30.73 36.4173 30.1466 36.4173 29.5633C36.4173 28.98 36.084 28.3966 35.6673 27.98ZM34.5007 29.98L30.084 34.3966C29.584 34.8966 29.0007 35.23 28.334 35.3966C28.5007 34.73 28.834 34.1466 29.334 33.6466L33.7507 29.23C33.834 29.1466 34.0007 29.0633 34.1673 29.0633C34.334 29.0633 34.4173 29.1466 34.5007 29.23C34.584 29.3133 34.6673 29.48 34.6673 29.5633C34.6673 29.6466 34.584 29.8133 34.5007 29.98Z"
              fill="white"
            />
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#6365EF" stroke-width="8" />
          </svg>
        </div>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>

  <div>
    <p class="modal-header-text mb-0">{{ title }}</p>
    <p class="modal-sub-header-text mb-0 mt-2">
      <ng-container *ngIf="title === 'Add New User'"
        >Add a new user by entering their details below and saving them.</ng-container
      >
      <ng-container *ngIf="title === 'Edit User'"
        >Edit your user details by changing the values below and saving them.</ng-container
      >
      <ng-container *ngIf="title === 'Invite User'"
        >Invite user by entering their details below and submitting them.</ng-container
      >
    </p>
  </div>
  <div class="es-modal-body" *ngIf="userForm">
    <form class="es-modal-form" [formGroup]="userForm" novalidate (ngSubmit)="submit()">
      <div class="div-height">
        <label for="name" class="form-label es-modal-form-label">First Name*</label>
        <input
          appCustomInputValidator="noSpace"
          type="text"
          class="form-control es-input"
          id="name"
          aria-describedby="name"
          formControlName="firstName"
          [ngClass]="[f.firstName.valid ? 'border-purple' : '']"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="
              (f.firstName.touched && f.firstName.pristine) || (f.firstName.errors && submitted)
            "
          >
            <span class="small" *ngIf="f.firstName.errors.required"> First Name is required! </span>
            <span class="small" *ngIf="f.firstName.errors.pattern">
              First Name contains alphabets only!
            </span>
            <span class="small" *ngIf="f.firstName.errors?.maxlength?.requiredLength">
              First Name should have max.
              {{ f.firstName.errors?.maxlength?.requiredLength }} characters.
            </span>
            <span class="small" *ngIf="f.firstName.errors?.minlength?.requiredLength">
              First Name should have min.
              {{ f.firstName.errors?.minlength?.requiredLength }} characters.
            </span>
          </span>
        </div>
      </div>

      <div class="div-height">
        <label for="name" class="form-label es-modal-form-label">Last Name*</label>
        <input
          appCustomInputValidator="noSpace"
          type="text"
          class="form-control es-input"
          id="name"
          aria-describedby="name"
          formControlName="lastName"
          [ngClass]="[f.lastName.valid ? 'border-purple' : '']"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.lastName.touched && f.lastName.pristine) || (f.lastName.errors && submitted)"
          >
            <span class="small" *ngIf="f.lastName.errors.required"> Last Name is required! </span>
            <span class="small" *ngIf="f.lastName.errors.pattern">
              Last Name contains alphabets only!
            </span>
            <span class="small" *ngIf="f.lastName.errors?.maxlength?.requiredLength">
              Last Name should have max.
              {{ f.lastName.errors?.maxlength?.requiredLength }} characters.
            </span>
            <span class="small" *ngIf="f.lastName.errors?.minlength?.requiredLength">
              Last Name should have min.
              {{ f.lastName.errors?.minlength?.requiredLength }} characters.
            </span>
          </span>
        </div>
      </div>

      <div class="div-height">
        <label for="name" class="form-label es-modal-form-label">Email Address*</label>
        <input
          appTrimSpaces
          type="text"
          class="form-control es-input"
          id="name"
          aria-describedby="name"
          formControlName="email"
          [ngClass]="[f.email.valid || f.email.disabled ? 'border-purple' : '']"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.email.touched && f.email.pristine) || (f.email.errors && submitted)"
          >
            <span class="small" *ngIf="f.email.errors.required"> Email Address is required! </span>
            <span class="small" *ngIf="f.email.errors?.['email'] || f.email.errors?.['pattern']">
              Invalid email address!
            </span>
            <span
              class="small"
              *ngIf="
                !f.email.errors?.['email'] &&
                !f.email.errors?.['pattern'] &&
                f.email.errors?.['minlength']
              "
            >
              Email address should have min.
              {{ f.email.errors?.['minlength']?.requiredLength }}
              characters.
            </span>
            <span
              class="small"
              *ngIf="
                !f.email.errors?.['email'] &&
                !f.email.errors?.['pattern'] &&
                f.email.errors?.['maxlength']
              "
            >
              Email address should have max.
              {{ f.email.errors?.['maxlength']?.requiredLength }}
              characters.
            </span>
          </span>
        </div>
      </div>

      <div class="div-height user-mobile-input">
        <label for="name" class="form-label es-modal-form-label">Phone Number*</label>
        <app-mobile-number-input [inputControl]="f.mobile"></app-mobile-number-input>
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.mobile.touched && f.mobile.pristine) || (f.mobile.errors && submitted)"
          >
            <span class="small" *ngIf="f.mobile.errors?.required"> Phone Number is required! </span>
            <span class="small" *ngIf="f.mobile.errors?.minlength">
              Phone number must be of 7 digits.
            </span>
            <span class="small" *ngIf="f.mobile.errors?.maxlength">
              Phone number must be of 15 digits.
            </span>
          </span>
        </div>
      </div>
      <div class="div-height">
        <label for="role" class="form-label es-modal-form-label">Role*</label>
        <ng-select
          class="form-select-control w-100"
          placeholder="Select User role"
          formControlName="role"
          [clearable]="false"
        >
          <ng-option value="admin"> Admin </ng-option>
        </ng-select>
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="f.role?.touched && f.role?.pristine && f.role?.errors"
          >
            <span class="small" *ngIf="f.role?.errors?.['required']">
              Role Selection is required!
            </span>
          </span>
        </div>
      </div>
      <div class="d-flex mt-5">
        <button type="button" class="btn btn-secondary w-20 mr-30 cancel-button" (click)="close()">Cancel</button>
        <button
          type="submit"
          class="btn ms-auto w-20"
          [ngClass]="userForm.untouched && userForm?.pristine ? 'btn-secondary' : 'btn-primary'"
          [disabled]="userForm.untouched && userForm?.pristine"
        >
          {{ title === 'Add New User' || title === 'Invite User' ? 'Submit' : 'Save Changes' }}
        </button>
      </div>
    </form>
  </div>
  <div class="es-modal-footer"></div>
</div>
