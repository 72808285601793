import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../../shared/service/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;
  private _themeSelection$ = new BehaviorSubject<any>(undefined);

  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorageService,
    private sanitizer: DomSanitizer
  ) {}

  setAppTheme(theme: any) {
    this._themeSelection$.next(theme);
  }

  getAppTheme() {
    return this._themeSelection$.asObservable();
  }

  getDashboardWidgets() {
    return [
      {
        title: 'Total Revenue',
        titleIcon: '/assets/images/dashboard/widgets-icon/users-icon.svg',
        detailsType: 'currency',
        detailsKey: 'revenue',
        // graphIcon: '/assets/images/dashboard/widgets-icon/graph-1.svg',
        graphIcon: this.sanitizer
          .bypassSecurityTrustHtml(`<svg width="117" height="48" viewBox="0 0 117 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="widget-graph-stroke" d="M1 18.1617L4.83333 22.6657C8.66667 27.1696 16.3333 36.1775 24 38.4634C31.6667 40.7494 39.3333 36.3133 47 35.8829C54.6667 35.4525 62.3333 39.0278 70 32.947C77.6667 26.8662 85.3333 11.1294 93 4.81006C100.667 -1.5093 108.333 1.58878 112.167 3.13781L116 4.68685" stroke="#6365EF"/>
        <path d="M4.83333 22.6651L1 18.1612V47.9852H116V4.80967L115.853 4.75029C111.881 3.14347 100.571 -1.43111 93 4.8095C85.3333 11.1289 77.6667 26.8657 70 32.9464C62.3333 39.0272 54.6667 35.4519 47 35.8823C39.3333 36.3127 31.6667 40.7488 24 38.4629C16.3333 36.177 8.66667 27.1691 4.83333 22.6651Z" fill="url(#paint0_linear_1616_14034)"/>
        <defs>
        <linearGradient id="paint0_linear_1616_14034" x1="-0.314286" y1="17.4282" x2="1" y2="90.371" gradientUnits="userSpaceOnUse">
        <stop class="widget-graph-stop-color" stop-color="#6365EF" stop-opacity="0.1"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
        </linearGradient>
        </defs>
        </svg>
        `),
        showFooter: true,
        increase: 10,
      },
      {
        title: 'Total Subscribers',
        titleIcon: '/assets/images/dashboard/widgets-icon/users-icon.svg',
        detailsType: '',
        detailsKey: 'subscriberCount',
        // graphIcon: '/assets/images/dashboard/widgets-icon/graph-2.svg',
        graphIcon: this.sanitizer
          .bypassSecurityTrustHtml(`<svg width="117" height="46" viewBox="0 0 117 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="widget-graph-stroke" d="M1 23.2214L4.19444 26.251C7.38889 29.2806 13.7778 35.3399 20.1667 36.478C26.5556 37.6162 32.9444 33.8332 39.3333 32.9828C45.7222 32.1324 52.1111 34.2144 58.5 29.2275C64.8889 24.2405 71.2778 12.1844 77.6667 7.02274C84.0556 1.86108 90.4444 3.5938 96.8333 3.73907C103.222 3.88435 109.611 2.44217 112.806 1.72109L116 1" stroke="#6365EF"/>
        <path d="M4.19444 26.251L1 23.2214V45.4138H116V1L112.806 1.72109C109.611 2.44217 103.222 3.88435 96.8333 3.73907C90.4444 3.5938 84.0556 1.86108 77.6667 7.02274C71.2778 12.1844 64.8889 24.2405 58.5 29.2275C52.1111 34.2144 45.7222 32.1324 39.3333 32.9828C32.9444 33.8332 26.5556 37.6162 20.1667 36.478C13.7778 35.3399 7.38889 29.2806 4.19444 26.251Z" fill="url(#paint0_linear_1616_14049)"/>
        <defs>
        <linearGradient id="paint0_linear_1616_14049" x1="52.5517" y1="13.6897" x2="58.5" y2="45.4138" gradientUnits="userSpaceOnUse">
        <stop class="widget-graph-stop-color" stop-color="#6365EF" stop-opacity="0.16"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
        </linearGradient>
        </defs>
        </svg>`),
        showFooter: true,
        increase: 5,
      },
      {
        title: 'Total Subscriptions',
        titleIcon: '/assets/images/dashboard/widgets-icon/checkmark-icon.svg',
        detailsType: '',
        detailsKey: 'subscriptionCount',
        // graphIcon: '/assets/images/dashboard/widgets-icon/graph-3.svg',
        graphIcon: this.sanitizer
          .bypassSecurityTrustHtml(`<svg width="119" height="47" viewBox="0 0 119 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="widget-graph-stroke" d="M1 23.6079L4.25 26.6902C7.5 29.7725 14 35.9371 20.5 37.095C27 38.253 33.5 34.4043 40 33.539C46.5 32.6738 53 34.7921 59.5 29.7184C66 24.6446 72.5 12.3789 79 7.12748C85.5 1.87605 92 3.63891 98.5 3.78671C105 3.93451 111.5 2.46725 114.75 1.73363L118 1" stroke="#6365EF"/>
        <path d="M4.25 26.6902L1 23.6079V46.1862H118V1L114.75 1.73363C111.5 2.46725 105 3.93451 98.5 3.78671C92 3.63891 85.5 1.87605 79 7.12748C72.5 12.3789 66 24.6446 59.5 29.7184C53 34.7921 46.5 32.6738 40 33.539C33.5 34.4043 27 38.253 20.5 37.095C14 35.9371 7.5 29.7725 4.25 26.6902Z" fill="url(#paint0_linear_1616_14062)"/>
        <defs>
        <linearGradient id="paint0_linear_1616_14062" x1="53.4483" y1="13.9103" x2="59.5" y2="46.1862" gradientUnits="userSpaceOnUse">
        <stop class="widget-graph-stop-color" stop-color="#6365EF" stop-opacity="0.16"/>
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0"/>
        </linearGradient>
        </defs>
        </svg>`),
        showFooter: true,
        increase: 5,
      },
      {
        title: 'Active Plans',
        titleIcon: '/assets/images/dashboard/widgets-icon/plan-icon.svg',
        detailsType: '',
        detailsKey: 'planCount',
        graphIcon: '',
        showFooter: false,
        increase: 2,
      },
    ];
  }

  getRaiseTicket() {
    return 'https://support.glowingbud.com/';
  }

  /*
  getProfiles(){
    return this.http.get(`${this.serverUrl}/dashboard`, this.getHeader());
  }

  getDashboardCounts(){
    return [
      this.http.get(`${this.serverUrl}/subscriptions/totalRevenue`, this.getHeader()),
      this.http.get(`${this.serverUrl}/subscriptions/count`, this.getHeader()),
      this.http.get(`${this.serverUrl}/subscribers/count`, this.getHeader()),
      this.http.get(`${this.serverUrl}/plans/count`, this.getHeader())
    ];
  }
  */

  getProfiles() {
    return this.http.get(`${this.serverUrl}/dashboard`);
  }

  getDashboardCounts() {
    return [
      this.http.get(`${this.serverUrl}/subscriptions/totalRevenue`),
      this.http.get(`${this.serverUrl}/subscriptions/count`),
      this.http.get(`${this.serverUrl}/subscribers/count`),
      this.http.get(`${this.serverUrl}/plans/count`),
    ];
  }

  getReports(dateRangeValue?: string, fromDate?: any, toDate?: any, customerId?: any) {
    if (dateRangeValue && customerId) {
      if (fromDate && toDate) {
        return this.http.get(
          `${this.serverUrl}/subscriptions/revenue/graph?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}&customerId=${customerId}`
        );
      } else {
        return this.http.get(
          `${this.serverUrl}/subscriptions/revenue/graph?dateRange=${dateRangeValue}&customerId=${customerId}`
        );
      }
    } else if (dateRangeValue) {
      if (fromDate && toDate) {
        return this.http.get(
          `${this.serverUrl}/subscriptions/revenue/graph?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        return this.http.get(
          `${this.serverUrl}/subscriptions/revenue/graph?dateRange=${dateRangeValue}`
        );
      }
    } else if (customerId) {
      return this.http.get(
        `${this.serverUrl}/subscriptions/revenue/graph?dateRange=year&customerId=${customerId}`
      );
    } else {
      return this.http.get(`${this.serverUrl}/subscriptions/revenue/graph?dateRange=year`);
    }
  }

  /* Super Admin Dashboard Statistics APIs - START */
  getUptimeStatistics() {
    return this.http.get(`${this.serverUrl}/reports/uptime`);
  }

  getPlatformDataReportStatistics(type: string, fromDate?: string, toDate?: string) {
    if (fromDate && toDate) {
      return this.http.get(
        `${this.serverUrl}/statistics/superadmin-dashboard?dateRange=${type}&fromDate=${fromDate}&toDate=${toDate}`
      );
    }
    return this.http.get(`${this.serverUrl}/statistics/superadmin-dashboard?dateRange=${type}`);
  }

  getActivityLog(lastId?: string) {
    if (lastId) {
      return this.http.get(`${this.serverUrl}/logs/recent?lastId=${lastId}`);
    }
    return this.http.get(`${this.serverUrl}/logs/recent`);
  }

  getSuperAdminDashboardStatisticsData() {
    return [
      // this.http.get(`${this.serverUrl}/statistics/uptime`),
      this.http.get(`${this.serverUrl}/statistics/superadmin-dashboard?dateRange=all`),
      this.http.get(`${this.serverUrl}/logs/recent`),
    ];
  }
  /* Super Admin Dashboard Statistics APIs - END */
}
