import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeEditorService {
  private isDarkThemeSource = new BehaviorSubject<boolean>(false);
  private lightPreviewLogoSrcSource = new BehaviorSubject<string>('');
  private darkPreviewLogoSrcSource = new BehaviorSubject<string>('');

  // Separate BehaviorSubjects for each component's slide
  private crmSlide = new BehaviorSubject<number>(0);
  private trsSlide = new BehaviorSubject<number>(0);
  private isCrmThemeEditor = new BehaviorSubject<boolean>(true);
  private isTrsDashboardSource = new BehaviorSubject<boolean>(false);
  private currentSlideOfTrs = new BehaviorSubject<number>(0);

  isDarkTheme$ = this.isDarkThemeSource.asObservable();
  lightPreviewLogoSrc$ = this.lightPreviewLogoSrcSource.asObservable();
  darkPreviewLogoSrc$ = this.darkPreviewLogoSrcSource.asObservable();

  currentCrmSlide$ = this.crmSlide.asObservable();
  currentTrsSlide$ = this.trsSlide.asObservable();

  isCrmThemeEditor$ = this.isCrmThemeEditor.asObservable();
  isTrsDashboard$ = this.isTrsDashboardSource.asObservable();
  currentSlideOfTrs$ = this.currentSlideOfTrs.asObservable();

  setIsDarkTheme(isDarkTheme: boolean) {
    this.isDarkThemeSource.next(isDarkTheme);
  }

  setLightPreviewLogoSrc(value: string): void {
    this.lightPreviewLogoSrcSource.next(value);
  }

  setDarkPreviewLogoSrc(value: string): void {
    this.darkPreviewLogoSrcSource.next(value);
  }

  // Separate method to set current slide for either component
  setCurrentTheme(slide: number, component: string): void {
    if (component === 'crmComponent') {
      this.crmSlide.next(slide);
    } else if (component === 'trsComponent') {
      this.trsSlide.next(slide);
    }
  }

  setIsCrmThemeEditor(value: boolean) {
    this.isCrmThemeEditor.next(value);
  }

  setIsTrsDashboard(isTrsDashboard: boolean) {
    this.isTrsDashboardSource.next(isTrsDashboard);
  }
}
