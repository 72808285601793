<div class="brand-colors-section bg-box-shadow">
  <div class="custom-template-mode-parent">
    <label class="switch">
      <input type="checkbox" id="togBtn" (click)="toggleBrandColor()" [disabled]="!edit" />
      <div class="slider" [ngClass]="{ disabled: !edit }">
        <span class="dark-text" [ngClass]="{ 'color-white': isBrandColor }">Custom</span>
        <span class="light-text" [ngClass]="{ 'color-white': !isBrandColor }">Templates</span>
      </div>
    </label>
  </div>

  <!-- Custom Colors -->
  <div *ngIf="isBrandColor">
    <p class="card-heading">Brand Colors</p>
    <p class="brand-color-text card-text">Set the colors for your brand</p>

    <div class="brand-colors-inner" [ngClass]="{ disabled: !edit }">
      <div class="brand-colors-row" *ngFor="let color of brandColors; let i = index">
        <div class="d-flex align-items-center justify-content-between">
          <p class="color-var-name">{{ color.name }}</p>

          <button
            class="refresh-button"
            tooltip="Reset Color"
            placement="right"
            delay="100"
            offset="15"
            (click)="
              isDarkTheme
                ? (color.darkValue = configColors[i].darkValue)
                : (color.lightValue = configColors[i].lightValue);
              EventLog(color)
            "
          >
            <svg
              width="16"
              height="19"
              viewBox="0 0 16 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 18.95C4.98333 18.7 3.3125 17.8208 1.9875 16.3125C0.6625 14.8042 0 13.0333 0 11C0 9.9 0.216667 8.84583 0.65 7.8375C1.08333 6.82917 1.7 5.95 2.5 5.2L3.925 6.625C3.29167 7.19167 2.8125 7.85 2.4875 8.6C2.1625 9.35 2 10.15 2 11C2 12.4667 2.46667 13.7625 3.4 14.8875C4.33333 16.0125 5.53333 16.7 7 16.95V18.95ZM9 18.95V16.95C10.45 16.6833 11.6458 15.9917 12.5875 14.875C13.5292 13.7583 14 12.4667 14 11C14 9.33333 13.4167 7.91667 12.25 6.75C11.0833 5.58333 9.66667 5 8 5H7.925L9.025 6.1L7.625 7.5L4.125 4L7.625 0.5L9.025 1.9L7.925 3H8C10.2333 3 12.125 3.775 13.675 5.325C15.225 6.875 16 8.76667 16 11C16 13.0167 15.3375 14.7792 14.0125 16.2875C12.6875 17.7958 11.0167 18.6833 9 18.95Z"
                fill=""
              />
            </svg>
          </button>
        </div>

        <div class="color-code">
          <div class="color-code-inner">
            <input
              type="text"
              class="w-100"
              *ngIf="isDarkTheme"
              [(ngModel)]="color.darkValue"
              (ngModelChange)="EventLog(color)"
            />
            <input
              type="text"
              class="w-100"
              *ngIf="!isDarkTheme"
              [(ngModel)]="color.lightValue"
              (ngModelChange)="EventLog(color)"
            />
          </div>
        </div>

        <div class="color-code-selector">
          <input
            class="cursor-pointer"
            *ngIf="isDarkTheme"
            [style.background]="color.darkValue"
            [cpAlphaChannel]="'disabled'"
            [(colorPicker)]="color.darkValue"
            [cpOutputFormat]="'hex'"
            [cpWidth]="colorPickerWidth"
            [cpAlphaChannel]="'always'"
            (colorPickerClose)="EventLog(color)"
            (cpInputChange)="EventLog(color)"
            [cpPosition]="'top-right'"
            (cpSliderDragEnd)="EventLog(color)"
            [cpDisableInput]="false"
            type="text"
            [cpToggle]="color.isColorPickerOpen"
            (click)="color.isColorPickerOpen = !color.isColorPickerOpen; toggleColorPicker(i)"
          />

          <input
            class="cursor-pointer"
            *ngIf="!isDarkTheme"
            [style.background]="color.lightValue"
            [(colorPicker)]="color.lightValue"
            [cpOutputFormat]="'hex'"
            [cpWidth]="colorPickerWidth"
            [cpAlphaChannel]="'always'"
            (colorPickerClose)="EventLog(color)"
            (cpInputChange)="EventLog(color)"
            [cpPosition]="'top-right'"
            (cpSliderDragEnd)="EventLog(color)"
            [cpDisableInput]="false"
            type="text"
            [cpToggle]="color.isColorPickerOpen"
            (click)="color.isColorPickerOpen = !color.isColorPickerOpen; toggleColorPicker(i)"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Templates -->
  <div *ngIf="!isBrandColor">
    <p class="card-heading">Templates</p>
    <p class="brand-color-text card-text">
      Choose from the list of pre-defined templates to change the theme to your liking.
    </p>

    <div class="templates-section">
      <div
        class="color-palette-parent cursor-pointer"
        *ngFor="let theme of pallet"
        (click)="getThemeColors(theme)"
      >
        <div class="color-palette" [ngStyle]="{ 'background-color': theme.name === 'Pearl Mist' ? theme.color.darkPrimary : theme.color.primary }">
          <p class="mb-0 theme-name">{{ theme.name }}</p>
        </div>
        <div
          class="selected-palette"
          [ngStyle]="{ display: selectedTheme?.name === theme.name ? 'flex' : 'none' }"
        >
          <div class="palette-check-img-div">
            <img class="w-100 h-100" src="/assets/images/theme-editor/palette-check.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
