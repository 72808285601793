import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * GET Method: Contact or Support request List
   * @param type
   * @param itemsPerPage
   * @param currentPage
   * @returns
   */
  getAllContactSupportRequests(type: string, itemsPerPage?: number, currentPage?: number) {
    if (itemsPerPage && currentPage) {
      return this.http.get(
        `${this.serverUrl}/support-request?type=${type}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
      );
    }

    return this.http.get(`${this.serverUrl}/support-request?type=${type}`);
  }

  /**
   * GET Method: Contact or Support request info by id
   * @param requestId
   * @returns
   */
  getContactSupportRequestById(requestId: string) {
    return this.http.get(`${this.serverUrl}/support-request/${requestId}`);
  }

  /**
   * PUT Method: Update the contact or support request status
   * @param requestId
   * @param statusValue
   * @returns
   */
  updateContactSupportRequestStatus(requestId: string, statusValue: string) {
    return this.http.put(`${this.serverUrl}/support-request/${requestId}`, { status: statusValue });
  }
}
