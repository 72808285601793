import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class subscriberService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * POST Method: Subscriber
   * @param data
   * @returns
   */
  createSubscriber(data: any) {
    return this.http.post(`${this.serverUrl}/subscribers`, data);
  }

  /**
   * GET Method: Subscriber List
   * @param itemsPerPage
   * @param currentPage
   * @returns
   */
  getAllSubscriber(itemsPerPage?: number, currentPage?: number) {
    if (itemsPerPage && currentPage) {
      return this.http.get(
        `${this.serverUrl}/subscribers?dateRange=all&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
      );
    }
    return this.http.get(`${this.serverUrl}/subscribers?dateRange=all`);
  }

  /**
   * GET Method: Subscriber by id
   * @param subscriberId
   * @returns
   */
  getSingleSubscriber(subscriberId: string) {
    return this.http.get(`${this.serverUrl}/subscribers/${subscriberId}`);
  }

  /**
   * PUT Method: Subscriber by id
   * @param id
   * @param data
   * @returns
   */
  updateSubscriber(id: any, data: any) {
    return this.http.put(`${this.serverUrl}/subscribers/${id}`, data);
  }

  /**
   * DELETE Method: Subscriber by id
   * @param id
   * @returns
   */
  deleteSubscriber(id: any) {
    return this.http.delete(`${this.serverUrl}/subscribers/${id}`);
  }

  /**
   * POST Method: Invite Subscriber
   * @param data
   * @returns
   */
  inviteSubscriber(data: any) {
    return this.http.post(`${this.serverUrl}/subscriber/invite-subscriber`, data);
  }

  /**
   * GET Method: Subscriber list with/without filter and pagination
   * @param dateRangeValue
   * @param fromDate
   * @param toDate
   * @param itemsPerPage
   * @param currentPage
   * @returns
   */
  getFilteredSubscribersList(
    dateRangeValue?: string,
    fromDate?: any,
    toDate?: any,
    itemsPerPage?: number,
    currentPage?: number
  ) {
    if (dateRangeValue) {
      if (fromDate && toDate) {
        return this.http.get(
          `${this.serverUrl}/subscribers?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
        );
      } else {
        return this.http.get(
          `${this.serverUrl}/subscribers?dateRange=${dateRangeValue}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
        );
      }
    } else {
      return this.http.get(`${this.serverUrl}/subscribers?dateRange=year`);
    }
  }

  /**
   * GET Method: Subscribers list by subscriber id
   * @param subscriberId
   * @param paginateConfig
   * @returns
   */
  getSubscriptionsList(subscriberId: string, paginateConfig: any) {
    return this.http.get(
      `${this.serverUrl}/subscribers/${subscriberId}?itemsPerPage=${
        paginateConfig.itemsPerPage
      }&currentPage=${paginateConfig.currentPage - 1}`
    );
  }

  getNotificationsList(subscriberId: string, paginateConfig: any) {
    return this.http.post(
      `${this.serverUrl}/notifications/subscribers/${subscriberId}?itemsPerPage=${
        paginateConfig.itemsPerPage
      }&currentPage=${paginateConfig.currentPage - 1}`,
      {}
    );
  }
}
