import { Component, ViewContainerRef } from '@angular/core';
import { DialogComponent } from '../../service/dialog/dialog.component';

@Component({
  selector: 'app-download-sample-file',
  templateUrl: './download-sample-file.component.html',
  styleUrls: ['./download-sample-file.component.scss'],
})
export class DownloadSampleFileComponent {
  dialogRef!: DialogComponent;
  data: any;
  title: string = 'Download Sample File';
  description: string = 'Download CSV Sample file to see how your file should look like';
  submitted: boolean = false;
  files: any[] = [{ name: 'sample.csv', path: 'assets/sample-files/sample.csv' }];

  constructor(private viewContainer: ViewContainerRef) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit();
  }
}
