import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage.service';
import { UsersService } from '../users.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  userDetails: any;
  constructor(
    private _localStorageService: LocalStorageService,
    private router: Router,
    private userService: UsersService
  ) {
    this.userService.getCurrentUser().subscribe((data) => (this.userDetails = data));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this._localStorageService.getToken();
    const isApiUrl =
      request.url.startsWith(environment.serverUrl) ||
      request.url.startsWith(environment.paymentServerUrl);
    const selectedCustomer = this._localStorageService.getSelectedCustomer();

    if (token && isApiUrl) {
      if (request.url.includes('inventory') || request.url.includes('utilities/upload') || request.url.includes('refresh-plans')) {
        request = request.clone({
          setHeaders: {
            Accept: 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            Accept: 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      }
    }

    // && !(request.serializeBody()?.toString()?.includes('customerId')
    if (
      selectedCustomer &&
      selectedCustomer != 'false' &&
      !request.urlWithParams.includes('customerId') &&
      ((this.userDetails?.roles?.includes('admin') &&
        this.userDetails?.customerId != selectedCustomer) ||
        this.userDetails?.roles?.includes('superAdmin')) &&
      !request.urlWithParams?.includes(selectedCustomer)
    ) {
      // if(request.method == 'PUT' || request.method == 'POST'){
      //   const body : any = request.body || {};
      //   request = request.clone({
      //     body: { ...body, customerId: selectedCustomer}
      //   });
      // } else {
      request = request.clone({ setParams: { customerId: selectedCustomer } });
      // }
    }
    return next.handle(request).pipe(
      map((data) => data),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this._localStorageService.clearStorage();
          this.router.navigate(['/signin']);
        }

        return throwError(() => err);
      })
    );
  }
}
