import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * @description Trim space validator
 *
 * @param control
 * @returns
 */
export function trimSpaceValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.value.trim().length === 0) {
    return { spacesOnly: true };
  }
  return null;
}
