import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective {
  // @Input('tooltip') tooltipTitle!: string;
  @Input('tooltip') tooltipObj!: any;
  @Input() placement!: string;
  @Input() delay!: string;
  @Input() offset: string = '0';
  @Input() svgPath!: string;
  tooltip!: HTMLElement | undefined;
  tooltipTitle!: string;
  @Input() customMaxWidth!: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  @HostListener('click') onClick() {
    if (this.tooltip?.innerText == 'Copy') {
      this.tooltip.innerText = 'Copied';
    }

    if (this.tooltip && this.tooltip?.innerText != 'Copied') {
      this.hide();
    }
  }

  /**
   * Show the tooltip
   */
  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
    if (this.tooltipObj?.isCustomerSelected === false) {
      this.hide();
    }
  }

  /**
   * Hide the tooltip
   */
  hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    const self = this;
    window.setTimeout(() => {
      self.renderer.removeChild(document.body, self.tooltip);
      self.tooltip = undefined;
    }, parseInt(self.delay));
  }

  /**
   * Create a tooltip
   */
  create() {
    this.tooltip = this.renderer.createElement('span');

    if (this.svgPath) {
      const img = this.renderer.createElement('img');
      img.setAttribute('src', this.svgPath);
      this.renderer.appendChild(this.tooltip, img);
      this.renderer.addClass(this.tooltip, 'ng-tooltip-light');
    }

    // Mapping tooltip titles for Products SVG elements
    const tooltipTitlesMap: any = {
      androidApp: 'Android App',
      api: 'API',
      iosApp: 'iOS App',
      mobile: 'Mobile App',
      sdk: 'SDK',
      shopifyApp: 'Shopify Application',
      trs: 'TRS',
      webApp: 'Web Application',
    };

    // Set tooltip title based on mapping or use default
    this.tooltipTitle =
      tooltipTitlesMap[this.tooltipObj] || this.tooltipObj?.title || this.tooltipObj;

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle) // textNode
    );

    this.renderer.appendChild(document.body, this.tooltip);
    // this.renderer.appendChild(this.el.nativeElement, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);

    if (this.customMaxWidth) {
      this.renderer.setStyle(this.tooltip, 'max-width', this.customMaxWidth);
      this.renderer.setStyle(this.tooltip, 'white-space', 'normal');
    }
  }

  /**
   * Setting up the position of the tooltip element
   */
  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltip?.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;

    if (this.placement === 'top' && tooltipPos) {
      top = hostPos.top - tooltipPos.height - parseInt(this.offset);
      // left = hostPos.left + (hostPos.width - (tooltipPos.width / 2.5)) / 2;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom' && tooltipPos) {
      top = hostPos.bottom + parseInt(this.offset);
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - parseInt(this.offset);
    }

    if (this.placement === 'right' && tooltipPos) {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + parseInt(this.offset);
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
