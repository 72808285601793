<div class="es-modal add-edit-modal" *ngIf="!inProgress; else spinner">
  <!-- <div class="modal-header-flex">
        <div class="modal-header-text-container">
          <div class="mb-3" [class]="title === 'Add New Customer' ? 'add-customer-icon' : 'edit-customer-icon'">
            <svg width="32" height="40" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="title === 'Add New Customer'">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1875 24.875C24.1875 22.7234 25.9317 20.9792 28.0833 20.9792C30.2349 20.9792 31.9792 22.7234 31.9792 24.875C31.9792 27.0266 30.2349 28.7709 28.0833 28.7709C25.9317 28.7709 24.1875 27.0266 24.1875 24.875ZM28.0833 22.3542C26.6911 22.3542 25.5625 23.4828 25.5625 24.875C25.5625 26.2672 26.6911 27.3959 28.0833 27.3959C29.4756 27.3959 30.6042 26.2672 30.6042 24.875C30.6042 23.4828 29.4756 22.3542 28.0833 22.3542Z" fill="#07A92B"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9785 33.5833C20.9785 31.6848 22.5175 30.1458 24.416 30.1458H24.7285C24.8976 30.1458 25.0656 30.1726 25.2264 30.225L26.0198 30.4842C27.3603 30.9218 28.8051 30.9218 30.1455 30.4842L30.9389 30.225C31.0997 30.1726 31.2677 30.1458 31.4369 30.1458H31.7493C33.6479 30.1458 35.1868 31.6848 35.1868 33.5833V34.6726C35.1868 35.363 34.6864 35.9517 34.005 36.063C30.0828 36.7034 26.0826 36.7034 22.1603 36.063C21.4789 35.9517 20.9785 35.363 20.9785 34.6726V33.5833ZM24.416 31.5208C23.2769 31.5208 22.3535 32.4443 22.3535 33.5833V34.6726C22.3535 34.6892 22.3655 34.7033 22.3819 34.706C26.1574 35.3224 30.008 35.3224 33.7834 34.706C33.7998 34.7033 33.8118 34.6892 33.8118 34.6726V33.5833C33.8118 32.4443 32.8884 31.5208 31.7493 31.5208H31.4369C31.4127 31.5208 31.3887 31.5247 31.3657 31.5321L30.5723 31.7912C28.9546 32.3194 27.2107 32.3194 25.593 31.7912L24.7996 31.5321C24.7767 31.5247 24.7526 31.5208 24.7285 31.5208H24.416Z" fill="#07A92B"/>
              <path d="M35.8757 23.7292C36.2553 23.7292 36.5632 24.037 36.5632 24.4167V26.0209H38.1673C38.547 26.0209 38.8548 26.3287 38.8548 26.7084C38.8548 27.088 38.547 27.3959 38.1673 27.3959H36.5632V29C36.5632 29.3797 36.2553 29.6875 35.8757 29.6875C35.496 29.6875 35.1882 29.3797 35.1882 29V27.3959H33.584C33.2043 27.3959 32.8965 27.088 32.8965 26.7084C32.8965 26.3287 33.2043 26.0209 33.584 26.0209H35.1882V24.4167C35.1882 24.037 35.496 23.7292 35.8757 23.7292Z" fill="#07A92B"/>
            </svg>
            <svg width="32" height="40" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="title === 'Edit Customer'">
              <path d="M24.5828 35.4801H22.5828C22.1661 35.4801 21.7495 35.2301 21.5828 34.8967C21.3328 34.5634 21.3328 34.2301 21.4995 33.8134C22.4995 31.4801 24.7495 29.8967 27.3328 29.8967C29.9162 29.8967 32.0828 27.7301 32.0828 25.1467C32.0828 22.5634 29.9162 20.48 27.2495 20.48C24.6661 20.48 22.4995 22.6467 22.4995 25.23C22.4995 26.7301 23.1661 28.0634 24.2495 28.8967C22.3328 29.6467 20.6661 31.2301 19.8328 33.2301C19.4995 34.0634 19.5828 35.0634 20.0828 35.8134C20.6661 36.6467 21.4995 37.1467 22.4995 37.1467H24.4995C24.9995 37.1467 25.3328 36.8134 25.3328 36.3134C25.3328 35.8134 25.0828 35.4801 24.5828 35.4801ZM24.1661 25.23C24.1661 23.5634 25.5828 22.1467 27.2495 22.1467C28.9162 22.1467 30.3328 23.5634 30.3328 25.23C30.3328 26.8967 28.9162 28.3134 27.2495 28.3134C25.5828 28.3134 24.1661 26.8967 24.1661 25.23Z" fill="#6365EF"/>
              <path d="M35.6673 27.9801C34.834 27.1468 33.4173 27.1468 32.584 27.9801L28.1673 32.3968C27.1673 33.3968 26.584 34.8134 26.584 36.3134C26.584 36.8134 26.9173 37.1468 27.4173 37.1468C28.9173 37.1468 30.2507 36.5634 31.334 35.5634L35.7507 31.1468C36.1673 30.7301 36.4173 30.1468 36.4173 29.5634C36.4173 28.9801 36.084 28.3968 35.6673 27.9801ZM34.5007 29.9801L30.084 34.3968C29.584 34.8968 29.0007 35.2301 28.334 35.3968C28.5007 34.7301 28.834 34.1468 29.334 33.6468L33.7507 29.2301C33.834 29.1468 34.0007 29.0634 34.1673 29.0634C34.334 29.0634 34.4173 29.1468 34.5007 29.2301C34.584 29.3134 34.6673 29.4801 34.6673 29.5634C34.6673 29.6468 34.584 29.8134 34.5007 29.9801Z" fill="#6365EF"/>
            </svg>
          </div>
          <p class="modal-header-text mb-0">{{title}}</p>
        </div>
        <div class="es-btn-close-container mt-0 mb-auto me-4">
            <a class="es-btn-close mt-3" (click)="close()"></a>
        </div>
    </div> -->
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3" class="add-edit-icon">
        <div class="mb-3">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="title === 'Add New Customer'"
            [class]="title === 'Add New Customer' ? 'add-user-icon' : 'edit-user-icon'"
          >
            <rect class="rect1" x="4" y="4" width="48" height="48" rx="24" fill="#33A85B" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.1875 23.8751C23.1875 21.7235 24.9317 19.9792 27.0833 19.9792C29.2349 19.9792 30.9792 21.7235 30.9792 23.8751C30.9792 26.0267 29.2349 27.7709 27.0833 27.7709C24.9317 27.7709 23.1875 26.0267 23.1875 23.8751ZM27.0833 21.3542C25.6911 21.3542 24.5625 22.4829 24.5625 23.8751C24.5625 25.2673 25.6911 26.3959 27.0833 26.3959C28.4756 26.3959 29.6042 25.2673 29.6042 23.8751C29.6042 22.4829 28.4756 21.3542 27.0833 21.3542Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.9785 32.5833C19.9785 30.6847 21.5175 29.1458 23.416 29.1458H23.7285C23.8976 29.1458 24.0656 29.1725 24.2264 29.225L25.0198 29.4841C26.3603 29.9217 27.8051 29.9217 29.1455 29.4841L29.9389 29.225C30.0997 29.1725 30.2677 29.1458 30.4369 29.1458H30.7493C32.6479 29.1458 34.1868 30.6847 34.1868 32.5833V33.6725C34.1868 34.363 33.6864 34.9516 33.005 35.0629C29.0828 35.7033 25.0826 35.7033 21.1603 35.0629C20.4789 34.9516 19.9785 34.363 19.9785 33.6725V32.5833ZM23.416 30.5208C22.2769 30.5208 21.3535 31.4442 21.3535 32.5833V33.6725C21.3535 33.6891 21.3655 33.7032 21.3819 33.7059C25.1574 34.3224 29.008 34.3224 32.7834 33.7059C32.7998 33.7032 32.8118 33.6891 32.8118 33.6725V32.5833C32.8118 31.4442 31.8884 30.5208 30.7493 30.5208H30.4369C30.4127 30.5208 30.3887 30.5246 30.3657 30.532L29.5723 30.7912C27.9546 31.3194 26.2107 31.3194 24.593 30.7912L23.7996 30.532C23.7767 30.5246 23.7526 30.5208 23.7285 30.5208H23.416Z"
              fill="white"
            />
            <path
              d="M34.8757 22.7292C35.2553 22.7292 35.5632 23.0371 35.5632 23.4167V25.0209H37.1673C37.547 25.0209 37.8548 25.3287 37.8548 25.7084C37.8548 26.0881 37.547 26.3959 37.1673 26.3959H35.5632V28.0001C35.5632 28.3798 35.2553 28.6876 34.8757 28.6876C34.496 28.6876 34.1882 28.3798 34.1882 28.0001V26.3959H32.584C32.2043 26.3959 31.8965 26.0881 31.8965 25.7084C31.8965 25.3287 32.2043 25.0209 32.584 25.0209H34.1882V23.4167C34.1882 23.0371 34.496 22.7292 34.8757 22.7292Z"
              fill="white"
            />
            <rect
              class="rect2"
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#A3FFD3"
              stroke-width="8"
            />
          </svg>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="title === 'Edit Customer'"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#A5A6EE" />
            <path
              d="M24.5828 35.48H22.5828C22.1661 35.48 21.7495 35.23 21.5828 34.8967C21.3328 34.5633 21.3328 34.23 21.4995 33.8133C22.4995 31.48 24.7495 29.8967 27.3328 29.8967C29.9162 29.8967 32.0828 27.73 32.0828 25.1466C32.0828 22.5633 29.9162 20.48 27.2495 20.48C24.6661 20.48 22.4995 22.6466 22.4995 25.23C22.4995 26.73 23.1661 28.0633 24.2495 28.8967C22.3328 29.6467 20.6661 31.23 19.8328 33.23C19.4995 34.0633 19.5828 35.0633 20.0828 35.8133C20.6661 36.6467 21.4995 37.1467 22.4995 37.1467H24.4995C24.9995 37.1467 25.3328 36.8133 25.3328 36.3133C25.3328 35.8133 25.0828 35.48 24.5828 35.48ZM24.1661 25.23C24.1661 23.5633 25.5828 22.1466 27.2495 22.1466C28.9162 22.1466 30.3328 23.5633 30.3328 25.23C30.3328 26.8967 28.9162 28.3133 27.2495 28.3133C25.5828 28.3133 24.1661 26.8967 24.1661 25.23Z"
              fill="white"
            />
            <path
              d="M35.6673 27.98C34.834 27.1466 33.4173 27.1466 32.584 27.98L28.1673 32.3966C27.1673 33.3966 26.584 34.8133 26.584 36.3133C26.584 36.8133 26.9173 37.1466 27.4173 37.1466C28.9173 37.1466 30.2507 36.5633 31.334 35.5633L35.7507 31.1466C36.1673 30.73 36.4173 30.1466 36.4173 29.5633C36.4173 28.98 36.084 28.3966 35.6673 27.98ZM34.5007 29.98L30.084 34.3966C29.584 34.8966 29.0007 35.23 28.334 35.3966C28.5007 34.73 28.834 34.1466 29.334 33.6466L33.7507 29.23C33.834 29.1466 34.0007 29.0633 34.1673 29.0633C34.334 29.0633 34.4173 29.1466 34.5007 29.23C34.584 29.3133 34.6673 29.48 34.6673 29.5633C34.6673 29.6466 34.584 29.8133 34.5007 29.98Z"
              fill="white"
            />
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#6365EF" stroke-width="8" />
          </svg>
        </div>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>

  <div>
    <p class="modal-header-text mb-0">{{ title }}</p>
    <p class="modal-sub-header-text mb-0 mt-2">
      {{
        title === 'Add New Customer'
          ? 'Add a new customer by entering their details below and saving them.'
          : 'Edit your customer details by changing the values below and saving them.'
      }}
    </p>
  </div>
  <div class="es-modal-body" *ngIf="customerForm">
    <form class="es-modal-form" [formGroup]="customerForm" novalidate (ngSubmit)="submit()">
      <div class="mb-3">
        <label for="name" class="form-label es-modal-form-label">Full Name</label>
        <input
          type="text"
          class="form-control es-input"
          id="name"
          aria-describedby="name"
          formControlName="name"
          [ngClass]="[f.name.valid ? 'border-purple' : '']"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.name.touched && f.name.pristine) || (f.name.errors && submitted)"
          >
            <span class="small" *ngIf="f.name.errors.required"> Name is required! </span>
          </span>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <label for="smdp" class="form-label es-modal-form-label">Select Provider</label>
          <!-- <ng-select
                          [items]="Provider"
                          bindLabel="name"
                          placeholder="Select Provider"
                          formControlName="smdp" 
                          [multiple]="true"
                          >
                </ng-select> -->
          <div class="container-ng-select">
            <ng-select
              appearance="outline"
              placeholder="Select a Service Provider"
              labelForId="smdp"
              formControlName="smdp"
            >
              <ng-option [value]="option.name" *ngFor="let option of Provider">
                <img src="{{ option.image }}" width="20px" height="20px" />&nbsp; {{ option.name }}
              </ng-option>
            </ng-select>
            <svg
              class="default"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.smdp.touched && f.smdp.pristine) || (f.smdp.errors && submitted)"
          >
            <span class="small" *ngIf="f.smdp.errors.required"> Provider Name is required! </span>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <button class="btn btn-secondary w-20 mr-30" (click)="close()">Cancel</button>
        <button type="submit" class="btn btn-primary ms-auto w-20">
          {{ title === 'Add New Customer' ? 'Submit' : 'Save Changes' }}
        </button>
      </div>
    </form>
  </div>
  <div class="es-modal-footer"></div>
</div>

<!-- Loading Spinner - Start -->
<ng-template #spinner class="loader-align">
  <app-loading-spinner></app-loading-spinner>
</ng-template>
<!-- Loading Spinner - End -->
