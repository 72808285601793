import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;
  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * POST Method: Upload file
   * @param file
   * @returns
   */
  onUpload(file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.serverUrl}/inventory/import`, formData, {
      reportProgress: true,
      observe: 'events' as 'body',
    });
  }
}
