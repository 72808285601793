import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ThemeEditorService } from '../../service/theme-editor.service';

@Component({
  selector: 'app-preview-dashboard',
  templateUrl: './preview-dashboard.component.html',
  styleUrls: ['./preview-dashboard.component.scss'],
})
export class PreviewDashboardComponent implements OnInit {
  isTrsDashboard!: boolean;

  constructor(
    private themeEditorService: ThemeEditorService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.themeEditorService.isTrsDashboard$.subscribe((value) => {
      this.isTrsDashboard = value;
      this.cdr.detectChanges();
    });
  }
}
