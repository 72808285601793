import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * GET Method: Plans list with/without pagination and it's status
   * @param itemsPerPage
   * @param currentPage
   * @param status
   * @returns
   */
  listPlans(itemsPerPage?: number, currentPage?: number, status?: string) {
    const params: { [key: string]: any } = {};

    if (status && status !== 'All Plans') {
      params['status'] = status;
    }

    if (itemsPerPage) {
      params['itemsPerPage'] = itemsPerPage;
    }

    if (currentPage) {
      params['currentPage'] = currentPage;
    }

    const url = `${this.serverUrl}/plans`;
    return this.http.get(url, { params });
  }

  /**
   * GET Method: Region specific plans
   * @param regionId
   * @returns
   */
  fetchPlansByRegion(regionId: string) {
    return this.http.get(`${this.serverUrl}/customer-plans?regionId=${regionId}`);
  }

  /**
   * POST Method: save new plan
   * @param data
   * @returns
   */
  createPlan(data: any) {
    return this.http.post(`${this.serverUrl}/plans`, data);
  }

  /**
   * PUT Method: Update existing plan
   * @param id
   * @param data
   * @returns
   */
  updatePlan(id: any, data: any) {
    return this.http.put(`${this.serverUrl}/plans/${id}`, data);
  }

  /**
   * DELETE Method: Delete a plan
   * @param id
   * @returns
   */
  deletePlan(id: any) {
    return this.http.delete(`${this.serverUrl}/plans/${id}`);
  }

  /**
   * GET Method: IMSI List
   * @returns
   */
  getIMSITypeList() {
    return this.http.get(`${this.serverUrl}/inventory/getUniqueIMSI`);
  }

  /**
   * GET Method: IMSI, Countries and Regions
   * @returns
   */
  getIMSIAndCountryList() {
    return [
      this.http.get(`${this.serverUrl}/inventory/getUniqueIMSI`),
      this.http.get(`${this.serverUrl}/countries`),
      this.http.get(`${this.serverUrl}/group`),
    ];
  }

  /**
   * GET Method: Region list
   * @returns
   */
  getRegionList() {
    return this.http.get(`${this.serverUrl}/group`);
  }

  /**
   * POST Method: Refresh Plans (Customer-specific)
   * @returns
   */
  refreshPlans(payload: any) {
    return this.http.post(`${this.serverUrl}/refresh-plans`, payload);
  }
}
