<div class="auth-parent-section dark-content">
  <div class="auth-main-body">
    <div class="row">
      <div class="col-lg-7 pe-0">
        <div class="auth-left-section">
          <div class="d-flex mb-4">
            <img class="img-fluid main-logo z-index-2" src="/assets/icons/logo.svg" alt="" />
          </div>
          <h1 class="welcome-heading mb-0 z-index-2">
            Now would be a perfect time to open your mail!
          </h1>
          <div class="my-4">
            <div class="ripple-position d-none-tab">
              <div>
                <div class="circles-parent">
                  <div class="solid-circle solid-circle-grey"></div>
                  <div class="circle3 common-circle common-circle-grey common-circle-1"></div>
                  <div class="circle2 common-circle common-circle-grey common-circle-1"></div>
                  <div class="circle1 common-circle common-circle-grey common-circle-1"></div>
                </div>
              </div>
            </div>
            <div class="d-flex text-end express-img-div">
              <img class="img-fluid m-auto" src="/assets/images/auth/recover-password.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ps-0 z-index-3">
        <div class="auth-right-section">
          <div class="mt-5">
            <p class="signin-text mb-0">Reset your Password</p>
            <p class="signin-label-text mb-0">Enter the details below to reset your password</p>
            <form
              class="signin-form-parent"
              [formGroup]="forgotPasswordForm"
              novalidate
              (ngSubmit)="submit()"
            >
              <div class="form-inner-div">
                <label for="exampleInputEmail1" class="form-label">Enter your Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="name@company.com"
                  formControlName="email"
                />
                <div>
                  <!-- <p class="text-danger text-center" *ngIf="err">{{err}}</p> -->
                  <span
                    class="help-block ng-invalid text-danger"
                    *ngIf="(!f.email.valid && !f.email.pristine) || (f.email.errors && submitted)"
                  >
                    <span class="small" *ngIf="f.email.errors.required"> Email is required! </span>
                  </span>
                  <span
                    class="help-block ng-invalid text-danger"
                    *ngIf="f.email.errors && submitted"
                  >
                    <span class="small" *ngIf="f.email.errors.email"> Enter valid email! </span>
                    <span class="small" *ngIf="f.email.errors.incorrect"> User not found! </span>
                  </span>
                  <span class="help-block text-success" *ngIf="showOTPSection">
                    <span class="small">
                      We have sent a confirmation to the email id linked to this username.
                    </span>
                  </span>
                </div>
              </div>
              <button type="submit" class="btn auth-btn common-btn" [disabled]="showOTPSection">
                Next
              </button>
              <a class="btn auth-btn common-btn" routerLink="/signin">Back to Sign In</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
