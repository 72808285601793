import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { DialogComponent, DialogService } from '../../service/dialog';
import { UploadInventoryComponent } from '../upload-inventory/upload-inventory.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  title: string = 'Something went wrong!';
  body: string =
    'Oops, it seems like we have run into an error. Please rectify the problems and try again';
  dialogRef: DialogComponent;
  titleArr: string[] = ['Successful!', 'Error', 'Info', 'Warning'];
  type!: string;
  buttonGroup = [
    { cssClass: 'btn-secondary', title: 'Back', value: false },
    { cssClass: 'ms-auto', title: 'Try Again', value: false },
    { cssClass: 'ms-auto', title: 'Close', value: false },
    { cssClass: 'ms-auto', title: 'Continue', value: false },
    { cssClass: 'ms-auto', title: 'Upload Another', value: false },
  ];
  statusCode!: number;
  customTitle!: string;

  typeBasedArrays: string[] = [
    'CUSTOMER_ENABLE',
    'CUSTOMER_DISABLE',
    'CUSTOMER_PRODUCT_ENABLE',
    'CUSTOMER_PRODUCT_DISABLE',
    'CUSTOMER_FEATURE_ENABLE',
    'CUSTOMER_FEATURE_DISABLE',
    'AGENT',
    'USER_SETTING_CHANGES',
    'USER_PASSWORD_UPDATE',
    'CUSTOMER_UPDATE',
    'USER_INVITE',
    'TRS_AGENT_DISABLE',
    'TRS_AGENT_ENABLE',
    'EMAIL_FORWARDING',
    'CURRENCY',
    'LANGUAGE',
    'PLAN_REMINDER',
    'INVENTORY_USAGE_REMINDER',
  ];

  customObj: any;
  products: any;
  uploadRefreshPlanFile: any;

  constructor(
    private viewContainer: ViewContainerRef,
    private dialogService: DialogService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  ngOnInit(): void {
    this.title = this.dialogRef.context?.title;
    this.body = this.dialogRef.context?.body;
    this.type = this.dialogRef.context?.type;
    this.statusCode = this.dialogRef.context?.statusCode;
    this.customTitle = this.dialogRef.context?.customObj?.title;
    this.products = this.dialogRef.context?.customObj?.products || [];

    // Set title for alert popup
    this.title =
      this.type === 'delete-success'
        ? this.dialogRef.context?.customObj?.title || this.title
        : this.title;
    switch (true) {
      case this.title == 'Error':
        this.buttonGroup = this.buttonGroup.filter(
          (x) => x.title == 'Back' || x.title == 'Try Again'
        );
        break;
      case this.title == 'Successful!' && this.type == 'Inventory':
        this.buttonGroup = this.buttonGroup.filter(
          (x) => x.title == 'Continue' || x.title == 'Upload Another'
        );
        break;
    }
  }

  /**
   * @description On close of alert popup emit event to parent component
   *
   * @param openUploadInvenotry
   */
  close(openUploadInvenotry?: any): void {
    if (this.dialogRef.context.body === 'Submit to refresh the plans.')
      return this.dialogRef.close.emit('close modal');

    this.dialogRef.close.emit();
    if (openUploadInvenotry) {
      this.dialogService
        .openModal(UploadInventoryComponent, {
          cssClass: 'modal-sm',
          context: { data: {}, title: 'Upload Inventory' },
        })
        .instance.close.subscribe(() => {});
    }
  }

  onUploadCSV(event: any) {
    this.uploadRefreshPlanFile = event.target.files[0];
  }

  submitRefreshPlan() {
    this.dialogRef.close.emit(this.uploadRefreshPlanFile);
  }

  /**
   * @description Check if any value in the products object is true
   *
   * @returns
   */
  isAnyProductTrue(): boolean {
    return Object.values(this.products).some((value) => value === true);
  }
}
