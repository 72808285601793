import { getCurrencySymbol } from '@angular/common';
import { Component, HostListener, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { SubscriptionsService } from '../../service';
import { AlertService } from '../../service/alert.service';
import { DialogComponent } from '../../service/dialog';
import { subscriberService } from '../../service/subscriber.service';

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-dialog-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SubscriptionDialogComponent implements OnInit {
  dialogRef: DialogComponent;
  data: any;
  subscriptionForm: any;
  submitted = false;

  title: string = 'Add New Subscription';
  planList: any = [];

  regionId: string | null = null;
  subscribeList: any = [];
  countryList: any = [];
  currentDate = moment().utc();
  showHideRegion: boolean = true;
  selectedPlan: any;
  inProgress: boolean = false;
  err: boolean = false;
  currencyType: string = 'USD';

  smallScreenWidth = 1300;
  largeScreenWidth = 1920;
  spanTextWidth!: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateSpanWidth();
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private alertService: AlertService,
    private subscriberService: subscriberService,
    private subscriptionService: SubscriptionsService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.data = this.dialogRef.context?.data;
    this.title = this.dialogRef.context?.title;

    // Get currency
    this.currencyType =
      getCurrencySymbol(localStorage.getItem('currency')!, 'wide') ??
      getCurrencySymbol('USD', 'wide');
    this.createSubscriptionForm();
    this.getPlans();
    this.getUserId();
  }

  /**
   * @description Initiate subscription form
   */
  createSubscriptionForm() {
    this.subscriptionForm = new UntypedFormGroup({
      planId: new UntypedFormControl(null, [Validators.required]),
      regionId: new UntypedFormControl({ value: null, disabled: true }),
      country: new UntypedFormControl(null),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      startDate: new UntypedFormControl(null, [Validators.required]),
      endDate: new UntypedFormControl('', [Validators.required]),
      priceBundle: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
      data: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
    });
  }

  get f() {
    return this.subscriptionForm.controls;
  }

  /**
   * @description Get subscriber list
   */
  getUserId() {
    this.subscriberService.getAllSubscriber().subscribe({
      next: (res: any) => {
        this.subscribeList = res.data;
      },
      error: (err) => {
        this.alertService.error(err.error.message, err.status);
      },
    });
  }

  /**
   * @description Get all plans
   */
  getPlans() {
    this.subscriptionService.getPlans().subscribe({
      next: (res: any) => {
        this.planList = res.data;
        this.updateSpanWidth();
      },
      error: (err) => {
        this.alertService.error(err.error.message, err.status);
      },
    });
  }

  /**
   * @description on plan select update form
   *
   * @param event
   */
  onPlanSelect(event: any) {
    if (event) {
      this.f.startDate.setValue(this.currentDate);
      this.f.priceBundle.setValue(event.priceBundle);
      this.f.data.setValue(event.data);
      let endDate = new Date();
      endDate = moment(endDate)
        .add(event.validity - 1, 'days')
        .toDate();
      this.f.endDate.setValue(endDate.toISOString().slice(0, 10));
    }
  }

  /**
   * @description On date change update form
   *
   * @param event
   */
  onDateChange(event: any) {
    if (this.selectedPlan) {
      const startDate = event.target.value;

      this.f.startDate.setValue(startDate);
      const endDate = moment(startDate).add(this.selectedPlan.validity, 'days').toDate();
      this.f.endDate.setValue(endDate);
    }
  }

  /**
   * @description Create subscription
   *
   * @returns
   */
  submit() {
    this.submitted = true;

    if (this.subscriptionForm.invalid) {
      return;
    }

    const obj = {
      email: this.f.email.value,
      country: this.f.country.value ?? this.f.regionId.value,
      activationDate: String(this.f.startDate.value),
      expiryDate: String(this.f.endDate.value),
      planId: this.f.planId.value,
    };

    if (!this.f.regionId.value && obj && obj.country == null) {
      this.err = true;
      return;
    }
    this.inProgress = true;
    this.subscriptionService.createSubscription(obj).subscribe({
      next: (res: any) => {
        this.dialogRef.close.emit(res);
        this.inProgress = false;
        this.err = false;
      },
      error: (err) => {
        this.alertService.error(err.error.message, err.status);
        this.inProgress = false;
        this.err = false;
      },
    });
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit(false);
  }

  /**
   * @description Increment and decrement value
   *
   * @param controlKey
   * @param updateBy
   */
  updateValue(controlKey: string, updateBy: string) {
    if (updateBy == 'inc') {
      let incValue = isNaN(parseInt(this.f[controlKey].value))
        ? 0
        : parseInt(this.f[controlKey].value);
      this.f[controlKey].setValue(++incValue);
    } else if (updateBy == 'dec') {
      let decValue = isNaN(parseInt(this.f[controlKey].value))
        ? 1
        : parseInt(this.f[controlKey].value);
      if (--decValue > -1) {
        this.f[controlKey].setValue(decValue);
      }
    }
  }

  /**
   * @description On plan selection update form
   *
   * @param event
   */
  onPlanSelection(event: any) {
    this.selectedPlan = event;
    if (event == undefined) {
      this.showHideRegion = true;
      this.f.regionId.setValue(null);
      this.err = false;
    }

    if (event && event.groupId) {
      this.showHideRegion = true;
      this.f.regionId.setValue(event.region);
      this.countryList = null;
      this.err = false;
    }

    if (event && !event.groupId && event.supportedCountries) {
      this.countryList = event.supportedCountries;
      this.showHideRegion = false;
    }
    this.f.country.setValue(null);
    this.f.startDate.setValue(this.currentDate.toDate());
    this.f.priceBundle.setValue(event.priceBundle);
    this.f.data.setValue(event.data);
    const endDate = moment(this.currentDate).add(event.validity, 'days').toDate();
    this.f.endDate.setValue(endDate);
  }

  /**
   * @description Breadcrumbs text width based on the screen resolution
   */
  updateSpanWidth() {
    const screenWidth = window.screen.width;
    if (screenWidth >= this.largeScreenWidth) {
      this.spanTextWidth = 24;
    } else if (screenWidth >= this.smallScreenWidth) {
      this.spanTextWidth = 16;
    }
  }

  /**
   * @description Reset subscription form
   */
  clearForm() {
    this.subscriptionForm.reset();
  }
}
