import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AlertService, PlansService, RegionsService } from '../../service';
import { DialogComponent } from '../../service/dialog';

@Component({
  selector: 'app-plan-info',
  templateUrl: './plan-info.component.html',
  styleUrls: ['./plan-info.component.scss'],
})
export class PlanInfoComponent implements OnInit {
  dialogRef: DialogComponent;
  planDetails: any;
  countryList!: Array<any>;
  inProgress: boolean = false;
  currencyType: string = 'USD';

  constructor(
    private viewContainer: ViewContainerRef,
    private regionService: RegionsService,
    private plansService: PlansService,
    private alertService: AlertService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.planDetails = this.dialogRef.context?.data;
    this.countryList = this.planDetails?.supportedCountries;
    this.currencyType = localStorage.getItem('currency')!;
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit();
  }

  /**
   * @description Create country list
   *
   * @returns
   */
  displayContryList() {
    return this.countryList
      .map((country: any) => country.name)
      .slice(1)
      .join(', ');
  }

  /**
   * @description Update plan status
   *
   * @param plan
   */
  updatePlanStatus(plan: any) {
    this.plansService.updatePlan(plan._id, { isActive: !plan.isActive }).subscribe({
      next: (res: any) => {
        this.alertService.success(res.message);
        this.dialogRef.close.emit(res);
      },
      error: (err) => {
        this.alertService.error(err.error.message, err.status);
      },
    });
  }
}
