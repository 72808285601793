<nav class="navbar navbar-expand-lg main-navbar justify-content-between">
  <div class="search-container" *ngIf="!isCrmThemeEditor && currentSlideTrs === 1">
    <span class="search-icon">
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.737 13.1023C12.6474 13.1906 12.5267 13.2401 12.401 13.2403C12.2735 13.2398 12.1511 13.1904 12.059 13.1023L9.46695 10.5043C8.3753 11.4213 6.97181 11.8813 5.54919 11.7886C4.12657 11.6959 2.79467 11.0576 1.83126 10.0067C0.867854 8.95585 0.347332 7.57364 0.378256 6.14833C0.40918 4.72303 0.989162 3.36469 1.99724 2.35662C3.00532 1.34854 4.36366 0.768555 5.78896 0.737631C7.21426 0.706707 8.59648 1.22723 9.64733 2.19064C10.6982 3.15404 11.3365 4.48595 11.4292 5.90857C11.522 7.33119 11.0619 8.73468 10.145 9.82633L12.737 12.4183C12.7823 12.463 12.8183 12.5162 12.8429 12.5749C12.8675 12.6337 12.8801 12.6967 12.8801 12.7603C12.8801 12.824 12.8675 12.887 12.8429 12.9457C12.8183 13.0044 12.7823 13.0577 12.737 13.1023ZM5.92095 10.8403C6.82283 10.8403 7.70446 10.5729 8.45435 10.0718C9.20424 9.57077 9.78871 8.8586 10.1338 8.02536C10.479 7.19213 10.5693 6.27527 10.3933 5.39072C10.2174 4.50616 9.78309 3.69365 9.14536 3.05592C8.50763 2.41819 7.69512 1.9839 6.81056 1.80795C5.92601 1.632 5.00915 1.7223 4.17592 2.06744C3.34268 2.41257 2.63051 2.99704 2.12945 3.74693C1.62839 4.49682 1.36095 5.37845 1.36095 6.28033C1.36254 7.48923 1.84348 8.64816 2.6983 9.50298C3.55312 10.3578 4.71205 10.8387 5.92095 10.8403Z"
          fill="#303030"
        />
      </svg>
    </span>
    <span class="search-text">Search by Traveller Name or Email ID</span>
  </div>
  <!-- Filter, Theme toggle, login div -->
  <div class="d-flex ms-auto gap-2 align-items-center" id="navbarSupportedContent">
    <ul class="navbar-nav mb-2 mb-lg-0 ms-auto gap-2" *ngIf="isCrmThemeEditor">
      <li class="nav-item my-auto">
        <div class="dark-light-toggle">
          <label class="switch">
            <input type="checkbox" />
            <span class="slider"></span>

            <div class="light-mode-icon d-flex" *ngIf="!isDarkTheme">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 0C7.26972 0 7.48837 0.218652 7.48837 0.488372V1.13953C7.48837 1.40926 7.26972 1.62791 7 1.62791C6.73028 1.62791 6.51163 1.40926 6.51163 1.13953V0.488372C6.51163 0.218652 6.73028 0 7 0Z"
                  fill="#E9EAF0"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.25581 7C3.25581 4.93214 4.93214 3.25581 7 3.25581C9.06786 3.25581 10.7442 4.93214 10.7442 7C10.7442 9.06786 9.06786 10.7442 7 10.7442C4.93214 10.7442 3.25581 9.06786 3.25581 7ZM7 4.23256C5.47158 4.23256 4.23256 5.47158 4.23256 7C4.23256 8.52842 5.47158 9.76744 7 9.76744C8.52842 9.76744 9.76744 8.52842 9.76744 7C9.76744 5.47158 8.52842 4.23256 7 4.23256Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M2.74092 2.05027C2.5502 1.85955 2.24098 1.85955 2.05026 2.05027C1.85954 2.24099 1.85954 2.55021 2.05026 2.74093L2.5107 3.20137C2.70142 3.39209 3.01064 3.39209 3.20137 3.20137C3.39209 3.01065 3.39209 2.70143 3.20137 2.51071L2.74092 2.05027Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M14 7C14 7.26972 13.7813 7.48837 13.5116 7.48837H12.8605C12.5907 7.48837 12.3721 7.26972 12.3721 7C12.3721 6.73028 12.5907 6.51163 12.8605 6.51163H13.5116C13.7813 6.51163 14 6.73028 14 7Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M11.9497 2.74092C12.1405 2.5502 12.1405 2.24098 11.9497 2.05026C11.759 1.85954 11.4498 1.85954 11.2591 2.05026L10.7986 2.5107C10.6079 2.70142 10.6079 3.01064 10.7986 3.20137C10.9893 3.39209 11.2986 3.39209 11.4893 3.20137L11.9497 2.74092Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M7 12.3721C7.26972 12.3721 7.48837 12.5907 7.48837 12.8605V13.5116C7.48837 13.7813 7.26972 14 7 14C6.73028 14 6.51163 13.7813 6.51163 13.5116V12.8605C6.51163 12.5907 6.73028 12.3721 7 12.3721Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M11.4893 10.7986C11.2986 10.6079 10.9894 10.6079 10.7987 10.7986C10.608 10.9894 10.608 11.2986 10.7987 11.4893L11.2591 11.9497C11.4498 12.1405 11.7591 12.1405 11.9498 11.9497C12.1405 11.759 12.1405 11.4498 11.9498 11.2591L11.4893 10.7986Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M1.62791 7C1.62791 7.26972 1.40926 7.48837 1.13953 7.48837H0.488372C0.218652 7.48837 0 7.26972 0 7C0 6.73028 0.218652 6.51163 0.488372 6.51163H1.13953C1.40926 6.51163 1.62791 6.73028 1.62791 7Z"
                  fill="#E9EAF0"
                />
                <path
                  d="M3.20132 11.4893C3.39204 11.2986 3.39204 10.9894 3.20132 10.7986C3.0106 10.6079 2.70138 10.6079 2.51066 10.7986L2.05022 11.2591C1.8595 11.4498 1.8595 11.759 2.05022 11.9497C2.24094 12.1405 2.55016 12.1405 2.74088 11.9497L3.20132 11.4893Z"
                  fill="#E9EAF0"
                />
              </svg>
            </div>
            <div class="dark-mode-icon d-flex" *ngIf="isDarkTheme">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.71964 1.05411C3.10552 1.23711 1.04167 3.41573 1.04167 6.07639C1.04167 8.85699 3.29579 11.1111 6.07639 11.1111C8.30408 11.1111 10.1942 9.66402 10.8575 7.6588C10.347 7.86969 9.78758 7.98611 9.20139 7.98611C6.80432 7.98611 4.86111 6.0429 4.86111 3.64583C4.86111 2.67421 5.18064 1.77715 5.71964 1.05411ZM0 6.07639C0 2.72049 2.72049 0 6.07639 0C6.38862 0 6.69568 0.0235945 6.9958 0.0691799C7.20341 0.100714 7.37178 0.253843 7.42281 0.457543C7.47384 0.661242 7.39755 0.875661 7.22932 1.00135C6.42308 1.60371 5.90278 2.56395 5.90278 3.64583C5.90278 5.46761 7.37962 6.94444 9.20139 6.94444C9.98905 6.94444 10.711 6.66903 11.2782 6.20878C11.4411 6.07653 11.6676 6.05502 11.8525 6.15424C12.0375 6.25345 12.1448 6.45399 12.1248 6.66292C11.8294 9.74389 9.23452 12.1528 6.07639 12.1528C2.72049 12.1528 0 9.43229 0 6.07639Z"
                  fill="#24272C"
                />
              </svg>
            </div>
          </label>
        </div>
      </li>
      <li>
        <div class="raise-ticket">
          <a href="" target="_blank">
            <img
              class="help-icon"
              [src]="'/assets/images/navbar/Help_' + (!isDarkTheme ? 'Dark.svg' : '.svg')"
              alt=""
            />
          </a>
        </div>
      </li>
    </ul>

    <div class="wallet-row" *ngIf="!isCrmThemeEditor">
      <span class="wallet-icon">
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6667 10.375C13.0639 10.375 13.3924 10.2451 13.6521 9.98542C13.9118 9.7257 14.0417 9.39722 14.0417 9C14.0417 8.60278 13.9118 8.27431 13.6521 8.01459C13.3924 7.75486 13.0639 7.625 12.6667 7.625C12.2694 7.625 11.941 7.75486 11.6813 8.01459C11.4215 8.27431 11.2917 8.60278 11.2917 9C11.2917 9.39722 11.4215 9.7257 11.6813 9.98542C11.941 10.2451 12.2694 10.375 12.6667 10.375ZM2.58333 17.25C2.07917 17.25 1.64742 17.0703 1.28808 16.711C0.928751 16.3517 0.74939 15.9202 0.750002 15.4167V2.58333C0.750002 2.07917 0.929668 1.64742 1.289 1.28808C1.64833 0.928751 2.07978 0.74939 2.58333 0.750002H15.4167C15.9208 0.750002 16.3526 0.929668 16.7119 1.289C17.0713 1.64833 17.2506 2.07978 17.25 2.58333V4.875H15.4167V2.58333H2.58333V15.4167H15.4167V13.125H17.25V15.4167C17.25 15.9208 17.0703 16.3526 16.711 16.7119C16.3517 17.0713 15.9202 17.2506 15.4167 17.25H2.58333ZM9.91667 13.5833C9.4125 13.5833 8.98075 13.4037 8.62142 13.0443C8.26208 12.685 8.08272 12.2536 8.08333 11.75V6.25C8.08333 5.74584 8.263 5.31409 8.62233 4.95475C8.98167 4.59542 9.41311 4.41606 9.91667 4.41667H16.3333C16.8375 4.41667 17.2693 4.59633 17.6286 4.95567C17.9879 5.315 18.1673 5.74645 18.1667 6.25V11.75C18.1667 12.2542 17.987 12.6859 17.6277 13.0453C17.2683 13.4046 16.8369 13.5839 16.3333 13.5833H9.91667ZM16.3333 11.75V6.25H9.91667V11.75H16.3333Z"
            fill="#6365EF"
          />
        </svg>
      </span>
      <p class="mb-0 wallet-price">$ 569.00</p>
    </div>

    <div class="d-flex user-div gap-2">
      <p class="user-name-div mb-0" [ngClass]="{'trs-user-name': !isCrmThemeEditor}">Hi, {{ userDetails?.firstName }}</p>
      <div class="dropdown">
        <button
          class="btn btn-dropdown"
          href="#"
          id="customerDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div class="user-profile-div">
            <img
              class="w-100 h-100"
              [src]="userDetails?.profileImageURL || '/assets/images/navbar/user.png'"
              alt=""
            />
          </div>
        </button>

        <ul class="dropdown-menu" aria-labelledby="customerDropdown">
          <li><a class="dropdown-item">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>
