<div
  class="d-flex phone-number-form-control"
  *ngIf="inputControl"
  [class.border-error]="inputControl?.touched && inputControl?.errors"
  [class.border-purple]="inputControl?.valid"
>
  <div class="form-control country-selection user-mobile-btn">
    <div class="dropdown">
      <button
        class="btn btn-ghost dropdown-toggle form-control"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        [disabled]="disabled"
      >
        {{ countryDialCode }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li class="country-search-box">
          <span class="search-icon-div">
            <svg
              class="img-fluid"
              fill="#747373"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18px"
              height="18px"
            >
              <path
                d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"
              />
            </svg>
          </span>
          <input
            type="text"
            placeholder="Search"
            [formControl]="country"
            class="form-control search-form-input"
          />
        </li>
        <span class="country-list">
          <li
            class="country-options row"
            *ngFor="
              let country of countryList | searchFilter: country?.value || '' : 'name';
              trackBy: trackCountries
            "
            (click)="setDialCode(country)"
          >
            <span class="col-4 px-2">{{ country?.dial_code }}</span>
            <span class="col-8 px-2">{{ country?.name }}</span>
          </li>
        </span>
      </ul>
    </div>
  </div>
  <input
    appNumberOnly
    type="tel"
    class="form-control auth-form-input mobile-input"
    [formControl]="phoneNumber"
    (focusout)="handleEvent($event)"
    [ngClass]="{ 'mobile-input-disable-color': disabled }"
  />
</div>
