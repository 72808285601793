import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  serverUrl = environment.serverUrl;
  lookupServerUrl = environment.lookupServerUrl;
  configDetails$ = new BehaviorSubject<any>(undefined);

  constructor(private http: HttpClient) {}

  /**
   * GET Method: Client Cache Configuration
   * @param cacheId
   * @param customerId
   * @returns
   */
  getConfigurationSetting(cacheId?: string, customerId?: string) {
    let configurationUrl: string;

    if (cacheId) {
      configurationUrl = `customers/setting/configuration?cacheId=${cacheId}`;
    } else {
      configurationUrl = `customers/setting/configuration`;
    }
    if (customerId) {
      configurationUrl += (cacheId ? '&' : '?') + `customerId=${customerId}`;
    }
    return this.http.get(`${this.serverUrl}/${configurationUrl}`);
  }


  /**
   * GET Method: Lookup server call to get the region and domain
   * @param host 
   * @returns 
   */
  fetchRegionAndConfigurationUsingDomain(host: string) {
    return this.http.get(`${this.lookupServerUrl}/crm/domain/${host}`);
  }

  fetchRegionAndConfigurationUsingEmail(email: string) {
    return this.http.get(`${this.lookupServerUrl}/crm/email?email=${email}`);
  }

}
