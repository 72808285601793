import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  /**
   * GET Method: Inventory List with/without pagination
   * @param itemsPerPage
   * @param currentPage
   * @returns
   */
  listInventory(itemsPerPage?: number, currentPage?: number) {
    if (itemsPerPage && currentPage) {
      return this.http.get(
        `${this.serverUrl}/inventories?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
      );
    }
    return this.http.get(`${this.serverUrl}/inventories`);
  }
}
