import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { LocalStorageService } from '../../service';
import { DialogComponent } from '../../service/dialog';

@Component({
  selector: 'app-subscription-plan-info',
  templateUrl: './subscription-plan-info.component.html',
  styleUrls: ['./subscription-plan-info.component.scss'],
})
export class SubscriptionPlanInfoComponent implements OnInit {
  dialogRef!: DialogComponent;
  planDetails: any;
  currencyType: string = 'USD';
  configData: any;

  detailsRow: Array<any> = [];
  constructor(
    private viewContainer: ViewContainerRef,
    private localStorage: LocalStorageService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.currencyType = localStorage.getItem('currency')!;
    this.planDetails = this.dialogRef.context?.data;
    this.configData = this.localStorage.getCacheConfig()!;
    this.setDetailsRow();
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit();
  }

  /**
   * @description Set detailed row
   */
  setDetailsRow() {
    this.detailsRow = [
      { title: 'Plan Name', key: 'name', display: true },
      { title: 'Status', key: 'status', display: true },
      { title: 'Plan Expiry', key: 'expiryDate', isDate: true, display: true },
      {
        title: 'Earned Reward Points',
        key: 'earnedPoints',
        display: this.configData?.configuration?.rewardPointsMasterEnabled,
      },
      {
        title: 'Used Reward Points',
        key: 'rewardPointsRedeemed',
        display: this.configData?.configuration?.rewardPointsMasterEnabled,
      },
      {
        title: 'Coupon Code',
        key: 'couponCode',
        display: this.configData?.configuration?.couponCodesMasterEnabled,
      },
      { title: 'ICCID', key: 'iccid', display: true },
      { title: 'Activation Code', key: 'activationCode', customClass: '', display: true },
    ];
  }
}
