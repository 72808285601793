<div class="es-modal info-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 d-flex">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#A5A6EE" />
          <path
            d="M27.5 17C21.704 17 17 21.704 17 27.5C17 33.296 21.704 38 27.5 38C33.296 38 38 33.296 38 27.5C38 21.704 33.296 17 27.5 17ZM27.5 32.75C26.9225 32.75 26.45 32.2775 26.45 31.7V27.5C26.45 26.9225 26.9225 26.45 27.5 26.45C28.0775 26.45 28.55 26.9225 28.55 27.5V31.7C28.55 32.2775 28.0775 32.75 27.5 32.75ZM28.55 24.35H26.45V22.25H28.55V24.35Z"
            fill="white"
          />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#6365EF" stroke-width="8" />
        </svg>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>

  <div>
    <p class="modal-header-text mb-0">Plan Info</p>
  </div>
  <div class="es-modal-body">
    <ng-container *ngFor="let details of detailsRow; let i = index; last as isLast">
      <div
        class="row rowHeight d-flex align-items-center gap-1"
        [ngClass]="{ 'custom-border-bottom': !isLast }"
        *ngIf="details && details.display"
      >
        <div class="col-4 pl-0">
          <label
            class="subscriber-label-text"
            [ngClass]="details.title === 'Contact Preferences' ? 'text-wrap' : ''"
          >
            {{ details.title }}
          </label>
        </div>
        <div
          class="col-7 ml-2 d-flex align-items-center"
          [ngClass]="details.title === 'Activation Code' ? 'activation-code-wrap' : ''"
        >
          <svg
            class="me-1 svg-reward-points"
            width="19"
            height="19"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="
              details.title === 'Earned Reward Points' || details.title === 'Used Reward Points'
            "
          >
            <path
              d="M1.6875 2.25C1.23995 2.25 0.810725 2.42779 0.494257 2.74426C0.17779 3.06072 0 3.48995 0 3.9375V5.27962C0 5.86462 0.30375 6.40687 0.799875 6.714L5.14913 9.40275C4.48759 9.75918 3.96393 10.3262 3.66113 11.0139C3.35833 11.7017 3.29371 12.4708 3.47752 13.1994C3.66132 13.928 4.08302 14.5745 4.67581 15.0363C5.2686 15.4981 5.99855 15.7489 6.75 15.7489C7.50145 15.7489 8.2314 15.4981 8.82419 15.0363C9.41698 14.5745 9.83868 13.928 10.0225 13.1994C10.2063 12.4708 10.1417 11.7017 9.83887 11.0139C9.53607 10.3262 9.01241 9.75918 8.35088 9.40275L12.7001 6.714C12.9446 6.56282 13.1463 6.35171 13.2863 6.10068C13.4263 5.84966 13.4999 5.56704 13.5 5.27962V3.9375C13.5 3.48995 13.3222 3.06072 13.0057 2.74426C12.6893 2.42779 12.2601 2.25 11.8125 2.25H1.6875ZM4.5 7.67925V3.375H9V7.67925L7.04587 8.8875C6.95698 8.94248 6.85452 8.9716 6.75 8.9716C6.64548 8.9716 6.54302 8.94248 6.45412 8.8875L4.5 7.67925ZM6.75 10.125C7.34674 10.125 7.91903 10.3621 8.34099 10.784C8.76295 11.206 9 11.7783 9 12.375C9 12.9717 8.76295 13.544 8.34099 13.966C7.91903 14.3879 7.34674 14.625 6.75 14.625C6.15326 14.625 5.58097 14.3879 5.15901 13.966C4.73705 13.544 4.5 12.9717 4.5 12.375C4.5 11.7783 4.73705 11.206 5.15901 10.784C5.58097 10.3621 6.15326 10.125 6.75 10.125Z"
              fill="black"
            />
          </svg>
          <svg
              class="me-2 svg-coupon-code"
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="details.title === 'Coupon Code'"
            >
              <path
                class="color-stroke"
                d="M11.5046 1H7.99583C4.68833 1 3.03458 1 2.00645 2.0255C1.2977 2.73075 1.07808 3.73263 1.00895 5.36538C0.995827 5.68913 0.988827 5.85188 1.0492 5.9595C1.11045 6.06713 1.35108 6.20275 1.83408 6.47225C2.10614 6.62393 2.33277 6.84551 2.49054 7.11409C2.64831 7.38267 2.73149 7.68851 2.73149 8C2.73149 8.31149 2.64831 8.61733 2.49054 8.88591C2.33277 9.15449 2.10614 9.37607 1.83408 9.52775C1.35108 9.79813 1.10958 9.93287 1.0492 10.0405C0.988827 10.1481 0.995827 10.31 1.00983 10.6338C1.07808 12.2674 1.29858 13.2692 2.00645 13.9745C3.0337 15 4.68745 15 7.99583 15H11.5046C14.8121 15 16.4658 15 17.494 13.9745C18.2027 13.2692 18.4223 12.2674 18.4915 10.6346C18.5046 10.3109 18.5116 10.1481 18.4512 10.0405C18.39 9.93287 18.1493 9.79813 17.6663 9.52775C17.3943 9.37607 17.1676 9.15449 17.0099 8.88591C16.8521 8.61733 16.7689 8.31149 16.7689 8C16.7689 7.68851 16.8521 7.38267 17.0099 7.11409C17.1676 6.84551 17.3943 6.62393 17.6663 6.47225C18.1493 6.20275 18.3908 6.06713 18.4512 5.9595C18.5116 5.85188 18.5046 5.69 18.4906 5.36538C18.4223 3.73263 18.2018 2.73162 17.494 2.0255C16.4667 1 14.813 1 11.5046 1Z"
                stroke="black"
                stroke-width="1.5"
              />
              <path
                class="color-stroke"
                d="M7.125 10.625L12.375 5.375"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                class="color-stroke"
                d="M12.8125 10.1875C12.8125 10.4196 12.7203 10.6421 12.5562 10.8062C12.3921 10.9703 12.1696 11.0625 11.9375 11.0625C11.7054 11.0625 11.4829 10.9703 11.3188 10.8062C11.1547 10.6421 11.0625 10.4196 11.0625 10.1875C11.0625 9.95544 11.1547 9.73288 11.3188 9.56878C11.4829 9.40469 11.7054 9.3125 11.9375 9.3125C12.1696 9.3125 12.3921 9.40469 12.5562 9.56878C12.7203 9.73288 12.8125 9.95544 12.8125 10.1875ZM8.4375 5.8125C8.4375 6.04456 8.34531 6.26712 8.18122 6.43122C8.01712 6.59531 7.79456 6.6875 7.5625 6.6875C7.33044 6.6875 7.10788 6.59531 6.94378 6.43122C6.77969 6.26712 6.6875 6.04456 6.6875 5.8125C6.6875 5.58044 6.77969 5.35788 6.94378 5.19378C7.10788 5.02969 7.33044 4.9375 7.5625 4.9375C7.79456 4.9375 8.01712 5.02969 8.18122 5.19378C8.34531 5.35788 8.4375 5.58044 8.4375 5.8125Z"
                fill="black"
              />
            </svg>
          <div class="subscriber-details-text" *ngIf="planDetails && details"  [ngClass]="details.title === 'Status' ? 'text-capitalize' : ''">
            {{
              !planDetails[details.key] && !details.isDate
                ? 'NA'
                : details.isDate
                  ? ''
                  : planDetails[details.key]
            }}
            <span class="icon copy" *ngIf="details.title === 'Plan Expiry'">
              {{
                planDetails[details?.key] || planDetails.expiredDate
                  ? (planDetails[details.key] || planDetails.expiredDate
                    | date: 'dd-MM-yyyy Thh:mm:ss')
                  : 'NA'
              }}
            </span>
            <span
              class="icon copy"
              *ngIf="
                details.title === 'Earned Reward Points' && planDetails.cashValueOfEarnedPoints
              "
            >
              ({{
                planDetails.cashValueOfEarnedPoints
                  | currency: planDetails.currency : 'symbol' : '1.0-2'
              }})
            </span>

            <span
              class="icon copy"
              *ngIf="details.title === 'Used Reward Points' && planDetails.rewardCashValueRedeemed"
            >
              ({{
                planDetails.rewardCashValueRedeemed
                  | currency: planDetails.currency : 'symbol' : '1.0-2'
              }})
            </span>
            <span
              class="icon copy"
              *ngIf="details.title === 'Coupon Code' && planDetails.couponAmount"
            >
              ({{ planDetails.couponAmount | currency: planDetails.currency : 'symbol' : '1.0-2' }})
            </span>
          </div>

          <div
            class="subscriber-details-text ms-auto"
            style="width: unset"
            *ngIf="
              details.title === 'Display Name' &&
              (planDetails.availableRewardPoints > 0 || planDetails.usedRewardPoints > 0)
            "
          >
            <div class="small-text" *ngIf="planDetails.displayName">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.5 3.74953V13.1245C17.4995 13.2135 17.48 13.3014 17.4428 13.3822C17.4056 13.463 17.3516 13.535 17.2844 13.5933C16.0906 14.6269 14.9492 14.9995 13.8609 14.9995C12.3836 14.9995 11.0039 14.3167 9.72031 13.6831C7.64609 12.6558 5.84297 11.7659 3.75 13.4191V16.8745C3.75 17.0403 3.68415 17.1993 3.56694 17.3165C3.44973 17.4337 3.29076 17.4995 3.125 17.4995C2.95924 17.4995 2.80027 17.4337 2.68306 17.3165C2.56585 17.1993 2.5 17.0403 2.5 16.8745V3.74953C2.5006 3.66049 2.52022 3.57261 2.55754 3.49176C2.59486 3.41092 2.64903 3.33899 2.71641 3.28078C5.52891 0.844841 8.05312 2.0925 10.2781 3.19328C12.4219 4.25422 14.2805 5.1714 16.4656 3.28078C16.5559 3.20249 16.6668 3.15173 16.7851 3.13454C16.9034 3.11734 17.0241 3.13443 17.133 3.18376C17.2419 3.2331 17.3343 3.31262 17.3993 3.4129C17.4644 3.51318 17.4993 3.63 17.5 3.74953Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="es-modal-footer d-flex mt-4">
    <button class="btn btn-primary ms-auto w-100" (click)="close()">Ok</button>
  </div>
</div>
