import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumberMask',
})
export class PhoneNumberMaskPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    let maskedPhone = '';
    let phoneNumber = value;
    if (value?.includes(' ')) {
      const numberArray = value.split(' ');
      maskedPhone = numberArray[0];
      phoneNumber = numberArray[1];
    }
    maskedPhone += `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
      6,
      10
    )} ${phoneNumber.length > 10 ? '-' + phoneNumber.slice(10, 14) : ''}`;
    return maskedPhone;
  }
}
