import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: any;
  submitted = false;
  err = false;
  token: any;
  passwordStrength: string = 'Very Weak';
  passwordStrengthIndex: number = -1;
  passwordStrengthColors: Array<string> = ['#FF1744', '#FFC400', '#64DD17', '#00C853'];
  passwordStrengthColorsText: Array<string> = ['#D50000', '#FF6F00', '#4CAF50', '#00C853'];
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  passwordErr = false;
  passwordMismatchLength: number = 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.resetForm();
    this.route.params.subscribe((params) => {
      this.token = params['token'];
      if (this.token) {
        this.checkLinkValidation();
      }
    });
    if (window.history?.state?.otp) {
      this.token = window.history.state.otp;
    }
  }

  /**
   * check reset password token is valid or not
   */
  checkLinkValidation() {
    this.authService.checkLinkValidation(this.token).subscribe({
      next: (res: any) => {},
      error: (err) => {
        // if invalid show popup and move to signin page
        if (err.error.message === 'Invalid Token.') {
          this.alertService.error(err.error.message);
          this.router.navigate(['/signin']);
        }
      },
    });
  }

  /**
   * Initiate reset form
   */
  resetForm() {
    this.resetPasswordForm = new UntypedFormGroup(
      {
        password: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\\-={}|\\[\\]\\\\:";\'<>?,./]).{8,}'
          ),
        ]),
        confirmPassword: new UntypedFormControl(null, {
          updateOn: 'change',
          validators: [Validators.required],
        }),
      },
      [CustomValidators.MatchValidator('password', 'confirmPassword')]
    );
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  /**
   * Check password and confirmed password are same
   */
  get passwordMatchError() {
    return (
      this.resetPasswordForm.getError('mismatch') &&
      this.resetPasswordForm.get('confirmPassword')?.touched
    );
  }

  /**
   * Reset password api call
   *
   * @returns
   */
  submit() {
    this.err = false;
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }

    const userData = {
      resetPasswordToken: this.token,
      newPassword: this.resetPasswordForm.get('password').value,
      verifyPassword: this.resetPasswordForm.get('confirmPassword').value,
    };

    this.authService.resetPssword(userData).subscribe({
      next: (data: any) => {
        this.alertService.success(data.message);
        this.router.navigate(['/signin']);
      },
      error: (err) => {
        this.err = true;
        if (err.error.message === 'Your new password cannot be same as your old password') {
          this.passwordErr = err.error.message;
        } else {
          this.alertService.error(err.error.message);
        }
      },
    });
  }

  /**
   * Check password strength
   *
   * @param value
   */
  onChangePasswordStrength(value: any) {
    this.passwordStrengthIndex = value;
    switch (value) {
      case 0:
        this.passwordStrength = 'Weak';
        break;
      case 1:
        this.passwordStrength = 'Weak';
        break;
      case 2:
        this.passwordStrength = 'Fair';
        break;
      case 3:
        this.passwordStrength = 'Good';
        break;
      case 4:
        this.passwordStrength = 'Strong';
        break;
      default:
        this.passwordStrength = 'Very Weak';
    }
  }

  onPasswordInput() {
    const password = this.resetPasswordForm.get('password').value;
    const confirmPassword = this.resetPasswordForm.get('confirmPassword').value;
    this.updatePasswordMismatchLength(password, confirmPassword);
  }

  updatePasswordMismatchLength(password: string, confirmPassword: string) {
    if (!password || !confirmPassword) {
      this.passwordMismatchLength = 0;
      return;
    }
    let mismatchLength = 0;
    const minLength = Math.min(password.length, confirmPassword.length);
    for (let i = 0; i < minLength; i++) {
      if (password[i] !== confirmPassword[i]) {
        mismatchLength++;
      }
    }
    mismatchLength += Math.abs(password.length - confirmPassword.length);
    this.passwordMismatchLength = mismatchLength;
  }
}
