import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AlertService, CustomerService, DashboardService, UsersService } from '../../service';
import { LocalStorageService } from '../../service/local-storage.service';
import { SearchService } from '../../service/search/search.service';
declare let $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  isDarkTheme = false;
  screenMode: any;
  parentCustomer: any;
  customerList: any = [];
  userDetails: any;
  showSearch: boolean = true;
  routeUrl!: string;
  placeHolder: string = '';

  tooltip = 'Need Assistance?';
  supportLink = 'https://support.glowingbud.com/';

  @ViewChild('searchForm', { static: false }) searchForm!: NgModel;
  initValue: string = '';
  searchform!: FormGroup;
  urlList = [
    '/',
    '/reports',
    '/setting',
    '/loyalty-point-program',
    '/reports/revenue',
    '/reports/data-usage',
    '/reports/subscriber',
    '/reports/subscription',
    '/products',
    '/customers/',
    '/theme-editor',
    '/products/trs-theme-editor',
  ];

  constructor(
    private customerService: CustomerService,
    private dashboardService: DashboardService,
    private alertService: AlertService,
    private usersService: UsersService,
    public router: Router,
    private _localStorage: LocalStorageService,
    private _searchService: SearchService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.formInit();

    // Conditional check to show or hide search input on page
    if (this.urlList.includes(this.router.url) || this.router.url.includes('/customers/')) {
      this.showSearch = false;
    } else {
      this.showSearch = true;
      this.routeUrl = this.router.url;
      this.placeHolder = this.definePlaceholder(this.router.url);
    }

    // show/hide search box
    router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.routeUrl = route.url || route.urlAfterRedirects;
        const routeArray = this.routeUrl;
        if (this.urlList.includes(route.url) || route.url.includes('/customers/')) {
          this.showSearch = false;
        } else {
          if ((routeArray.match(/\//g) || []).length > 1 && route.url === 'help-center') {
            this.showSearch = false;
          } else {
            this.showSearch = true;
            this.placeHolder = this.definePlaceholder(this.routeUrl);
          }
        }
        this.searchform?.reset();
      }
    });

    usersService.getCurrentUser().subscribe((result) => {
      this.userDetails = result;
    });
  }

  ngOnInit(): void {
    // If auth token is not present navigate to singin page
    if (!this._localStorage.getToken()) {
      this.router.navigate(['/signin']);
    }

    // set theme dark or white
    const theme = this._localStorage.getTheme();
    theme == 'dark' ? (this.isDarkTheme = true) : (this.isDarkTheme = false);
    this.dashboardService.setAppTheme(this.isDarkTheme);
    this._localStorage.setTheme(this.isDarkTheme);
    if (this.isDarkTheme) $('#body').toggleClass('darkMode');
  }

  /**
   * Initiate search form
   */
  formInit(): void {
    this.searchform = this.fb.group({
      searchTerm: [''],
    });
  }

  /**
   * Afterview init get search result depend on search term change
   */
  ngAfterViewInit() {
    if (!this.urlList.includes(this.routeUrl)) {
      this.searchform.controls['searchTerm'].valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((data) => this._searchService.getSearchResult(this.routeUrl, data))
        )
        .subscribe((res) => {
          this.cdr.detectChanges();
          this._searchService.setResults(res);
        });
    }
  }

  /**
   * Toggle dark and light mode and set to localstorage
   */
  toggleTheme() {
    this.isDarkTheme = !this.isDarkTheme;
    this.dashboardService.setAppTheme(this.isDarkTheme);
    this._localStorage.setTheme(this.isDarkTheme);
    $('#body').toggleClass('darkMode');
  }

  /**
   * Define search placeholder depend on routes
   *
   * @param urlEndPoint
   * @returns
   */
  private definePlaceholder(urlEndPoint: any): string {
    const splittedUrl = urlEndPoint.split('/');
    const first =
      splittedUrl[1] == 'subscribers' && splittedUrl.length == 3 ? 'subscriber' : splittedUrl[1];

    const mappings: any = {
      customers: 'Search by Customer Name',
      'user-management': 'Search by User Name or User Email ID',
      agent: 'Search by Agent Name or Email ID',
      products: 'Search by Agent Name or Email ID',
      plans: 'Search by Plan Name or IMSI',
      subscriber: 'Search by Plan Name or ICCID',
      subscribers: 'Search by Display Name or User Email ID',
      subscriptions: 'Search by User Email ID or Plan Name',
      inventory: 'Search by ICCID, IMSI or Activation Code',
      'help-center': 'Search by Name or User Email ID',
    };
    const result = mappings[first] || 'Search';
    return result;
  }

  /**
   * User logout clear localstorage
   */
  signout() {
    const favicon = document.getElementById('favicon-logo')!;
    favicon.setAttribute('href', 'assets/images/auth/favicon.ico');
    this._localStorage.clearStorage();
    window.location.href = '/signin';
  }

  /**
   * Get search result depend on route
   */
  getSearchResult() {
    if (!this.urlList.includes(this.routeUrl)) {
      this.searchform
        ?.get('searchTerm')
        ?.valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((data) => {
            if (data !== null) {
              return this._searchService.getSearchResult(this.routeUrl || this.router?.url, data);
            } else {
              return [];
            }
          })
        )
        .subscribe((res) => {
          console.log(res);
          this.cdr.detectChanges();
          this._searchService.setResults(res);
        });
    }
  }
}
