<div class="es-modal info-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 d-flex">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#A5A6EE" />
          <path
            d="M27.5 17C21.704 17 17 21.704 17 27.5C17 33.296 21.704 38 27.5 38C33.296 38 38 33.296 38 27.5C38 21.704 33.296 17 27.5 17ZM27.5 32.75C26.9225 32.75 26.45 32.2775 26.45 31.7V27.5C26.45 26.9225 26.9225 26.45 27.5 26.45C28.0775 26.45 28.55 26.9225 28.55 27.5V31.7C28.55 32.2775 28.0775 32.75 27.5 32.75ZM28.55 24.35H26.45V22.25H28.55V24.35Z"
            fill="white"
          />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#6365EF" stroke-width="8" />
        </svg>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>
  <div class="display-name" *ngIf="userDetails?.displayName">
    <label
      class="subscriber-details-text text-overflow-ellipsis modal-header-text"
      *ngIf="userDetails.displayName.length > 20"
      [tooltip]="userDetails.displayName"
      placement="top"
      delay="100"
      offset="0"
      for=""
    >
      {{ userDetails.displayName | titlecase }}
    </label>
    <label
      class="subscriber-details-text modal-header-text"
      *ngIf="userDetails.displayName.length <= 20"
      for=""
    >
      {{ userDetails.displayName | titlecase }}
    </label>
  </div>
  <div class="es-modal-body">
    <div class="row d-flex align-items-center rowHeight custom-border-bottom">
      <div class="col-4 pl-0">
        <label class="subscriber-label-text">First Name</label>
      </div>
      <div class="col-8">
        <label
          class="subscriber-details-text text-overflow-ellipsis"
          *ngIf="userDetails?.firstName.length > 20"
          [tooltip]="userDetails.firstName"
          placement="top"
          delay="100"
          offset="0"
          for=""
        >
          {{ userDetails?.firstName }}
        </label>
        <label class="subscriber-details-text" *ngIf="userDetails?.firstName.length <= 20" for="">
          {{ userDetails?.firstName }}
        </label>
      </div>
    </div>

    <div class="row d-flex align-items-center rowHeight custom-border-bottom">
      <div class="col-4 pl-0">
        <label class="subscriber-label-text" for="">Last Name</label>
      </div>
      <div class="col-8">
        <label
          class="subscriber-details-text text-overflow-ellipsis"
          *ngIf="userDetails?.lastName.length > 20"
          [tooltip]="userDetails?.lastName"
          placement="top"
          delay="100"
          offset="0"
          for=""
        >
          {{ userDetails?.lastName }}
        </label>
        <label for="" class="subscriber-details-text" *ngIf="userDetails?.lastName.length <= 20">
          {{ userDetails?.lastName }}
        </label>
      </div>
    </div>

    <div class="row d-flex align-items-center rowHeight custom-border-bottom">
      <div class="col-4 pl-0">
        <label for="" class="subscriber-label-text">Email Address</label>
      </div>
      <div class="col-8 d-flex align-items-center">
        <p class="mb-0 d-flex align-items-start w-100">
          <span class="max-w80 word-wrap text-overflow-ellipsis">
            <span class="text-truncate subscriber-details-text text-overflow-ellipsis mt-2">
              {{ userDetails?.email }}
            </span>
          </span>
          <span
            class="icon mt-2"
            *ngIf="userDetails?.email"
            (click)="copyToClipboard($event, userDetails?.email)"
            [tooltip]="copyText"
            placement="top"
            delay="100"
            offset="5"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1322_24072"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1322_24072)">
                <path
                  d="M4.16667 18.3332C3.70833 18.3332 3.31583 18.1701 2.98917 17.844C2.66306 17.5173 2.5 17.1248 2.5 16.6665V4.99984H4.16667V16.6665H13.3333V18.3332H4.16667ZM7.5 14.9998C7.04167 14.9998 6.64944 14.8368 6.32333 14.5107C5.99667 14.184 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.64944 1.82956 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8508 1.82956 16.1775 2.15567C16.5036 2.48234 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5036 14.184 16.1775 14.5107C15.8508 14.8368 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
          </span>
        </p>
      </div>
    </div>

    <div class="row d-flex align-items-center rowHeight custom-border-bottom">
      <div class="col-4 pl-0">
        <label for="" class="subscriber-label-text">Phone Number</label>
      </div>
      <div class="col-8">
        <label for="" class="subscriber-details-text">
          {{ userDetails?.countryCode }} {{ userDetails?.mobile }}
        </label>
      </div>
    </div>

    <div class="row d-flex align-items-center rowHeight">
      <div class="col-4 pl-0">
        <label for="" class="subscriber-label-text">Role</label>
      </div>
      <div class="col-8">
        <label for="" class="subscriber-details-text text-capitalize">
          {{ userDetails?.roles[0] }}
        </label>
      </div>
    </div>
  </div>
  <div class="es-modal-footer d-flex pt-4 mt-4">
    <button
      class="btn btn-secondary w-20 mr-30"
      [ngClass]="userDetails?.roles[0] === 'superAdmin' ? 'w-100' : ''"
      (click)="close()"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary ms-auto w-20"
      (click)="editUser()"
      *ngIf="userDetails?.roles[0] !== 'superAdmin'"
    >
      Edit User
    </button>
  </div>
</div>
