<div class="es-modal">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <p class="modal-header-text mb-0">{{ title }}</p>
    </div>
    <div class="es-btn-close-container">
      <button type="button" class="btn btn-sm btn-light es-btn-close" (click)="close()">X</button>
    </div>
  </div>
  <div class="es-modal-body">
    <form class="es-modal-form" [formGroup]="subscriberForm" novalidate (ngSubmit)="submit()">
      <div class="mb-3 row">
        <div class="col-6">
          <label for="firstName" class="form-label es-modal-form-label">First Name</label>
          <input
            type="text"
            class="form-control es-input"
            id="firstName"
            aria-describedby="firstName"
            formControlName="firstName"
          />
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.firstName.touched && f.firstName.pristine) || (f.firstName.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.email.errors && f.firstName.errors.required">
                First Name is required!
              </span>
            </span>
          </div>
        </div>
        <div class="col-6">
          <label for="lastName" class="form-label es-modal-form-label">Last Name</label>
          <input
            type="text"
            class="form-control es-input"
            id="lastName"
            aria-describedby="lastName"
            formControlName="lastName"
          />
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.lastName.touched && f.lastName.pristine) || (f.lastName.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.email.errors && f.lastName.errors.required">
                Last Name is required!
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label es-modal-form-label">Email Address</label>
        <input
          type="email"
          class="form-control es-input"
          id="email"
          aria-describedby="email"
          formControlName="email"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.email.valid && f.email.pristine) || (f.email.errors && submitted)"
          >
            <span class="small" *ngIf="f.email.errors && f.email.errors && f.email.errors.required">
              Email is required!
            </span>
          </span>
          <span class="help-block ng-invalid text-danger" *ngIf="f.email.errors && submitted">
            <span class="small" *ngIf="f.email.errors && f.email.errors.email">
              Enter valid email!
            </span>
          </span>
        </div>
      </div>
      <div class="mb-3">
        <label for="planId" class="form-label es-modal-form-label">Region</label>
        <select formControlName="regionId" class="form-control es-input" (change)="fetchPlans()">
          <option *ngFor="let region of regionList" [value]="region._id">{{ region.name }}</option>
        </select>
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.planId.touched && f.regionId.pristine) || (f.planId.errors && submitted)"
          >
            <span class="small" *ngIf="f.email.errors && f.regionId.errors.required">
              Region is required!
            </span>
          </span>
        </div>
      </div>
      <div class="mb-3">
        <label for="planId" class="form-label es-modal-form-label">Plan</label>
        <select formControlName="planId" class="form-control es-input" (change)="assignPlan()">
          <option *ngFor="let plan of planList" [value]="plan._id">{{ plan.name }}</option>
        </select>
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.planId.touched && f.regionId.pristine) || (f.planId.errors && submitted)"
          >
            <span class="small" *ngIf="f.email.errors && f.planId.errors.required">
              Plan is necessary!
            </span>
          </span>
        </div>
      </div>
      <div class="mb-3">
        <label for="endDate" class="form-label es-modal-form-label">Plan Details</label>
        <p class="mb-0 color-white">
          Data: {{ planDetails?.dataSize || 0 }} MB, Voice: {{ planDetails?.voice || 0 }} Minutes,
          SMS: {{ planDetails?.sms || 0 }}, Expires on: {{ f.endDate.value | date: 'medium' || 0 }}
        </p>
      </div>
      <button type="submit" class="btn btn-primary my-4 w-100">{{ buttonLabel }}</button>
    </form>
  </div>
  <div class="es-modal-footer"></div>
</div>
