import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from './shared/service';
import { ConfigurationService } from './shared/service/configuration.service';
import { EncryptDescryptService } from './shared/service/encrypt-descrypt.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'esim-management-portal';

  constructor(
    private configService: ConfigurationService,
    private localStorageService: LocalStorageService,
    private encryptDecryptService: EncryptDescryptService
  ) {}

  ngOnInit(): void {
    let host = window.location.hostname;

    if (host.includes('localhost')) {
      host = 'dev-app.esim.portal.glowingbud.xyz';
    }
    if (!this.localStorageService.getCacheConfig()) {
      this.configService.fetchRegionAndConfigurationUsingDomain(host).subscribe({
        next: (response: any) => {
          if (response.message === 'Success') {
            const { data, region } = response;
            const encryptedData = this.encryptDecryptService.encryptData(data);
            this.localStorageService.setCacheConfig(JSON.stringify(encryptedData));
            this.localStorageService.setRegion(region);
            this.configService.configDetails$.next(data);
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    } else if(this.localStorageService.getSelectedCustomer()) {
      const config = this.localStorageService.getCacheConfig()!;
      this.configService.getConfigurationSetting(config?.cacheId, config?.customerId)
        .subscribe((res: any) => {
          if (res && res.data) {
            const clientConfig = res.data.configuration;
  
            const encryptedData = this.encryptDecryptService.encryptData(res.data);
            this.localStorageService.setCacheConfig(JSON.stringify(encryptedData));
            this.configService.configDetails$.next(res.data);
            this.localStorageService.setConfigObservable(clientConfig);
            this.localStorageService.setSelectedParent(this.localStorageService.getCacheConfig()!);
          }
        })
    }
  }
}
