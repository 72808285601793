<div class="auth-parent-section dark-content">
  <div class="auth-main-body">
    <div class="row">
      <div class="col-lg-7 pe-0">
        <div class="auth-left-section">
          <div class="d-flex mb-4">
            <img class="img-fluid main-logo z-index-2" src="/assets/icons/logo.svg" alt="" />
          </div>
          <h1 class="welcome-heading mb-0 z-index-2">
            {{ err ? 'Oops, it seems like we have run into an error' : 'Welcome to Budding' }}
          </h1>
          <div class="my-4">
            <h4 class="label-heading mt-auto z-index-2" *ngIf="!err">Let's get started!</h4>
            <h4 class="forgot-password-label mt-auto z-index-2 d-flex" *ngIf="err">
              <img
                class="img-fluid m-auto me-1"
                src="/assets/images/auth/pointing-to-right.svg"
                alt=""
              />
              Click on
              <a href="" routerLink="/forgot-password" class="mx-1" aria-disabled="true"
                >Forgot Password</a
              >
              to reset it.
            </h4>

            <div class="ripple-position d-none-tab">
              <div>
                <div class="circles-parent">
                  <div class="solid-circle solid-circle-grey"></div>
                  <div class="circle3 common-circle common-circle-grey common-circle-1"></div>
                  <div class="circle2 common-circle common-circle-grey common-circle-1"></div>
                  <div class="circle1 common-circle common-circle-grey common-circle-1"></div>
                </div>
              </div>
            </div>
            <div class="d-flex text-end express-img-div">
              <img
                class="img-fluid m-auto"
                [src]="
                  '/assets/images/auth/' + (err ? 'forgot-password.svg' : 'pointing-to-right.svg')
                "
                alt=""
              />
            </div>
          </div>
          <div class="sign-in-vedio-container" *ngIf="!err">
            <video autoplay [muted]="true" loop>
              <source src="./../../../assets/video/sign-in.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ps-0 z-index-3">
        <div class="auth-right-section">
          <div class="mt-5">
            <p class="signin-text mb-0">Sign in .</p>
            <p class="signin-label-text mb-0">Sign-in to your account to get access to Budding!</p>
            <form
              class="signin-form-parent"
              [formGroup]="signinForm"
              novalidate
              (ngSubmit)="submit()"
            >
              <div class="form-inner-div">
                <label for="exampleInputEmail1" class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="name@company.com"
                  formControlName="email"
                />
                <div>
                  <span
                    class="help-block ng-invalid text-danger"
                    *ngIf="(!f.email.valid && !f.email.pristine) || (f.email.errors && submitted)"
                  >
                    <span class="small" *ngIf="f.email.errors.required"> Email is required! </span>
                  </span>
                  <span
                    class="help-block ng-invalid text-danger"
                    *ngIf="f.email.errors && submitted"
                  >
                    <span class="small" *ngIf="f.email.errors.email"> Enter valid email! </span>
                  </span>
                </div>
              </div>
              <div class="form-inner-div">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <div class="position-relative">
                  <input
                    [type]="showPassword ? 'text' : 'password'"
                    class="form-control"
                    [class.ng-dirty]="submitted && f.password?.errors?.required"
                    id="exampleInputPassword1"
                    [placeholder]="err ? 'Password incorrect' : 'Must be at least 8 characters'"
                    formControlName="password"
                  />
                  <p
                    class="help-block ng-invalid text-danger mb-0"
                    *ngIf="
                      (!f.password.valid && !f.password.pristine) ||
                      (f.password.errors && submitted)
                    "
                  >
                    <span class="small" *ngIf="f.password.errors.required">
                      Password is required!
                    </span>
                  </p>
                  <p class="show-hide-password mb-0">
                    <img
                      [src]="'/assets/images/auth/' + (showPassword ? 'eye.svg' : 'eye-slash.svg')"
                      (click)="showPassword = !showPassword"
                      alt=""
                    />
                  </p>
                </div>
              </div>
              <div class="form-inner-div text-danger small" *ngIf="errMsg">
                {{ errMsg }}
              </div>
              <div class="form-inner-div reset-pass-text">
                <a href="" routerLink="/forgot-password" class="" aria-disabled="true"
                  >Forgot Password ?</a
                >
              </div>
              <p class="terms-privacy-text">
                By signing in on Glowingbud platform, you agree to follow the
                <a target="_blank" rel="noopener" href="https://legal.glowingbud.com/saas"
                  >Terms & Conditions</a
                >
                and
                <a href="https://www.glowingbud.com/privacy-policy/" rel="noopener" target="_blank"
                  >Privacy Policy</a
                >
              </p>
              <button type="submit" class="btn auth-btn common-btn">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
