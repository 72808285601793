import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  crmConfig = this._localStorage.getCacheConfig()?.products?.crmConfig;
  serverUrl = this.crmConfig?.serverUrl ? this.crmConfig.serverUrl : environment.serverUrl;
  authToken: string = '';
  private customer = new Subject<any>();
  private customerTree = new Subject<any>();
  private selectedCustomerTree = new Subject<any>();
  private customerStatus = new Subject<any>();

  constructor(private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  childCustomers() {
    return this.http.get(`${this.serverUrl}/customers`);
  }

  /**
   * In use??
   */
  customers(itemsPerPage?: number, currentPage?: number) {
    if (itemsPerPage && currentPage) {
      return this.http.get(
        `${this.serverUrl}/customers?dateRange=year&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
      );
    }
    return this.http.get(`${this.serverUrl}/customers?dateRange=year`);
  }

  /**
   * GET Method: Customer by id
   * @param customerId
   * @returns
   */
  getSingleCustomer(customerId: any) {
    return this.http.get(`${this.serverUrl}/customers/${customerId}`);
  }

  getSubCustomer(subCustomerName: any) {
    return this.http.get(`${this.serverUrl}/customers/${subCustomerName}`);
  }

  /**
   * POST Method: save Customer
   * @param data
   * @returns
   */
  createCustomer(data: any) {
    return this.http.post(`${this.serverUrl}/customers`, data);
  }

  /**
   * PUT Method: update the customer details
   * @param id
   * @param data
   * @returns
   */
  updateCustomer(id: any, data: any) {
    return this.http.put(`${this.serverUrl}/customers/${id}`, data);
  }

  /**
   * DELETE Method: Customer by id
   * @param id
   * @returns
   */
  deleteCustomer(id: any) {
    return this.http.delete(`${this.serverUrl}/customers/${id}`);
  }

  // ??
  getFilteredCustomersList(
    dateRangeValue?: string,
    fromDate?: any,
    toDate?: any,
    itemsPerPage?: number,
    currentPage?: number
  ) {
    if (dateRangeValue) {
      if (fromDate && toDate) {
        return this.http.get(
          `${this.serverUrl}/customers?dateRange=${dateRangeValue}&fromDate=${fromDate}&toDate=${toDate}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
        );
      } else {
        return this.http.get(
          `${this.serverUrl}/customers?dateRange=${dateRangeValue}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
        );
      }
    } else {
      return this.http.get(`${this.serverUrl}/customers?dateRange=all`);
    }
  }

  sendCustomer(customer: any) {
    this.customer.next(customer);
  }

  getCustomer(): Observable<any[]> {
    return this.customer.asObservable();
  }

  sendCustomerTree(customerTree: any) {
    this.customerTree.next(customerTree);
  }

  getCustomerTree(): Observable<any[]> {
    return this.customerTree.asObservable();
  }

  getCustomerHierachy(customerId?: any) {
    if (customerId) {
      return this.http.get(`${this.serverUrl}/customers/hierarchy?customerId=${customerId}`);
    } else {
      return this.http.get(`${this.serverUrl}/customers/hierarchy`);
    }
  }

  updateCustomerAccessibility(data: any, customer: any) {
    return this.http.put(
      `${this.serverUrl}/customers/settings/accessibility?customerId=${customer}`,
      data
    );
  }

  updateCustomerConnectivity(data: any) {
    return this.http.put(`${this.serverUrl}/customers/settings/connectivity`, data);
  }

  sendSelectedCustomerTree(customerTree: any) {
    this.selectedCustomerTree.next(customerTree);
  }

  getSelectedCustomerTree() {
    return this.selectedCustomerTree.asObservable();
  }

  updateCustomerStatus(customer: any) {
    this.customerStatus.next(customer);
  }

  getCustomerStatus() {
    return this.customerStatus.asObservable();
  }
}
