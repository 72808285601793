<div class="es-modal add-edit-modal" *ngIf="!inProgress; else spinner">
  <div class="modal-header-flex">
    <div class="modal-header-text-container">
      <div class="mb-3 subscription-header-icon">
        <svg
          *ngIf="title === 'Add New Subscription'"
          class="m-auto"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.57082 0.257265C6.63992 -0.085755 9.81272 -0.085755 12.8818 0.257265C14.5945 0.448685 15.9762 1.7981 16.1774 3.51759C16.5443 6.65526 16.5443 9.82495 16.1774 12.9626C15.9762 14.6821 14.5945 16.0315 12.8818 16.2229C9.81272 16.5659 6.63992 16.5659 3.57082 16.2229C1.8581 16.0315 0.476337 14.6821 0.275227 12.9626C-0.0917425 9.82495 -0.0917425 6.65526 0.275227 3.51759C0.476337 1.7981 1.8581 0.448685 3.57082 0.257265ZM8.22632 3.24741C8.64052 3.24741 8.97632 3.58319 8.97632 3.99741V7.49006H12.469C12.8832 7.49006 13.219 7.82586 13.219 8.24006C13.219 8.65425 12.8832 8.99006 12.469 8.99006H8.97632V12.4827C8.97632 12.897 8.64052 13.2327 8.22632 13.2327C7.81212 13.2327 7.47632 12.897 7.47632 12.4827V8.99006H3.98371C3.56949 8.99006 3.23371 8.65425 3.23371 8.24006C3.23371 7.82586 3.56949 7.49006 3.98371 7.49006H7.47632V3.99741C7.47632 3.58319 7.81212 3.24741 8.22632 3.24741Z"
            fill="white"
          />
        </svg>

        <svg
          *ngIf="title === 'Edit Subscription'"
          class="m-auto"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 11.0837V14H3.09572L12.226 5.39897L9.13032 2.48271L0 11.0837ZM14.6201 3.14373C14.942 2.84044 14.942 2.35051 14.6201 2.04722L12.6883 0.227468C12.3664 -0.0758228 11.8463 -0.0758228 11.5243 0.227468L10.0136 1.6506L13.1094 4.56687L14.6201 3.14373Z"
            fill="#475467"
          />
        </svg>
      </div>
    </div>
    <div class="es-btn-close-container mt-0 mb-auto me-3">
      <a class="es-btn-close" (click)="close()"></a>
    </div>
  </div>

  <div>
    <p class="modal-header-text mb-0">{{ title }}</p>
    <p class="modal-sub-header-text mb-0 mt-2">
      {{
        title === 'Add New Subscription'
          ? 'Add a new subscription by filling in the required details below.'
          : 'See and edit the subscription as per your liking, your updated subcription will be refreshed within 5 minutes.'
      }}
    </p>
  </div>
  <div class="es-modal-body">
    <form
      class="es-modal-form"
      [formGroup]="subscriptionForm"
      novalidate
      (ngSubmit)="submit()"
      autocomplete="off"
    >
      <div class="divHeight d-flex justify-content-between">
        <div class="input-width imsi-input">
          <label for="region" class="form-label es-modal-form-label">Plan*</label>
          <div class="d-flex relative container-ng-select">
            <ng-select
              [items]="planList"
              [multiple]="false"
              [closeOnSelect]="true"
              bindLabel="name"
              bindValue="_id"
              placeholder="Choose a Plan"
              formControlName="planId"
              class="full-width"
              (change)="onPlanSelection($event)"
              (clear)="clearForm()"
            >
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input
                  id="item-{{ index }}"
                  type="radio"
                  class="custom-radio text-overflow-ellipsis"
                />
                <span
                  class="text-overflow-ellipsis w-80"
                  [tooltip]="item.name"
                  placement="top"
                  delay="100"
                  offset="12"
                  *ngIf="item?.name.length > spanTextWidth; else noTooltipData"
                  >{{ item.name }}</span
                >
                <ng-template #noTooltipData>
                  {{ item.name }}
                </ng-template>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input
                  id="item-{{ index }}"
                  type="radio"
                  class="custom-radio"
                  name="name"
                  [checked]="item$.selected"
                />
                <span
                  class="text-overflow-ellipsis w-80 position-relative"
                  style="z-index: 4"
                  [tooltip]="item.name"
                  placement="top"
                  delay="100"
                  offset="12"
                  *ngIf="item?.name.length > spanTextWidth; else noTooltipData"
                  >{{ item.name }}</span
                >
                <ng-template #noTooltipData>
                  {{ item.name }}
                </ng-template>
              </ng-template>
            </ng-select>
            <svg
              class="default"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="(f.planId.touched && f.planId.pristine) || (f.planId.errors && submitted)"
            >
              <span class="small" *ngIf="f.planId.errors.required"> Plan is required! </span>
            </span>
          </div>
        </div>
        <div class="input-width imsi-input" *ngIf="showHideRegion">
          <label for="regionId" class="form-label disable-color">Region</label>
          <input
            type="text"
            class="form-control es-input"
            id="regionId"
            aria-describedby="regionId"
            formControlName="regionId"
            [class.disabled]="true"
          />
        </div>
        <div class="input-width imsi-input" *ngIf="!showHideRegion">
          <label for="country" class="form-label es-modal-form-label">Country*</label>
          <div class="d-flex relative container-ng-select">
            <ng-select
              [items]="countryList"
              [multiple]="false"
              [closeOnSelect]="true"
              bindLabel="name"
              bindValue="name"
              placeholder="Choose Country"
              formControlName="country"
              class="full-width custom-select"
              (change)="(true)"
            >
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="radio" /> {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="radio" name="name" [checked]="item$.selected" />
                {{ item.name }}
              </ng-template>
            </ng-select>
            <svg
              class="default"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="f.country.value === null && submitted"
          >
            <span class="small" *ngIf="f.country.value === null"> Country is required! </span>
          </span>
        </div>
      </div>

      <div class="div-height">
        <label for="email" class="form-label es-modal-form-label">Email ID*</label>
        <input
          type="text"
          class="form-control es-input"
          id="email"
          aria-describedby="email"
          formControlName="email"
          [ngClass]="[f.email.valid ? 'border-purple' : '']"
          placeholder="one@example.com"
        />
        <div>
          <span
            class="help-block ng-invalid text-danger"
            *ngIf="(f.email.touched && f.email.pristine) || (f.email.errors && submitted)"
          >
            <span class="small" *ngIf="f.email.errors.required"> Email ID is required! </span>
            <span class="small" *ngIf="f.email.errors.email"> Please enter valid Email ID. </span>
          </span>
        </div>
      </div>

      <div class="div-height d-flex justify-content-between">
        <div class="input-width">
          <label for="startDate" class="form-label es-modal-form-label">Start Date*</label>
          <div class="d-flex input-icon">
            <mat-form-field
              appearance="outline"
              [ngClass]="[f.startDate.valid ? 'border-purple' : '']"
            >
              <input
                matInput
                [matDatepicker]="startDate"
                formControlName="startDate"
                placeholder="DD-MM-YYYY"
                (click)="startDate.open()"
                [min]="currentDate"
                (dateChange)="onDateChange($event)"
              />
              <mat-datepicker-toggle matIconSuffix [for]="startDate"> </mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.startDate.touched && f.startDate.pristine) || (f.startDate.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.startDate.errors.required">
                Start Date is required!
              </span>
            </span>
          </div>
        </div>

        <div class="input-width">
          <label for="endDate" class="form-label disable-color">Expiry Date</label>
          <div class="d-flex input-icon">
            <mat-form-field appearance="outline">
              <input
                matInput
                [matDatepicker]="endDate"
                formControlName="endDate"
                placeholder="DD-MM-YYYY"
                readonly
                id="endDate"
              />
              <mat-datepicker-toggle
                class="endDate"
                matIconSuffix
                [for]="endDate"
                [disabled]="true"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDate [disabled]="true"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="div-height d-flex justify-content-between">
        <div class="input-width">
          <label for="priceBundle" class="form-label disable-color">Price</label>
          <div class="d-flex">
            <input
              type="number"
              class="form-control es-input price-input disable-color"
              id="priceBundle"
              aria-describedby="priceBundle"
              formControlName="priceBundle"
              [disabled]="true"
            />
            <span class="form-label disable-color price-input-icon"> {{ currencyType }} </span>
          </div>
          <div class="mt-2">
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="
                (f.priceBundle.touched && f.priceBundle.pristine) ||
                (f.priceBundle.errors && submitted)
              "
            >
              <span class="small" *ngIf="f.priceBundle.errors.required"> Price is required! </span>
            </span>
          </div>
        </div>

        <div class="col-6 gap-2 input-width">
          <label for="data" class="form-label disable-color">Data</label>
          <input
            type="text"
            class="form-control es-input"
            id="data"
            aria-describedby="data"
            formControlName="data"
            [class.disabled]="true"
          />
          <div>
            <span
              class="help-block ng-invalid text-danger"
              *ngIf="(f.data.touched && f.data.pristine) || (f.data.errors && submitted)"
            >
              <span class="small" *ngIf="f.data.errors && f.data.errors.required">
                Data is required!
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-2 gx-2 d-flex justify-content-between">
        <button class="btn btn-secondary btn-width" (click)="close()">Cancel</button>
        <button type="submit" class="btn btn-primary ms-auto btn-width">Add Subscription</button>
      </div>
    </form>
  </div>
  <div class="es-modal-footer"></div>
</div>

<!-- Loading Spinner - Start -->
<ng-template #spinner class="loader-align">
  <app-loading-spinner></app-loading-spinner>
</ng-template>
<!-- Loading Spinner - End -->
