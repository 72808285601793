import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UsersService } from 'src/app/shared/service';
import { ThemeEditorService } from 'src/app/shared/service/theme-editor.service';

@Component({
  selector: 'app-preview-navbar',
  templateUrl: './preview-navbar.component.html',
  styleUrls: ['./preview-navbar.component.scss'],
})
export class PreviewNavbarComponent implements OnInit {
  isDarkTheme: boolean = false;
  userDetails: any;
  isCrmThemeEditor!: boolean;
  @Input() currentSlideTrs!: number;

  constructor(
    private themeEditorService: ThemeEditorService,
    private usersService: UsersService,
    private cdr: ChangeDetectorRef
  ) {
    usersService.getCurrentUser().subscribe((result) => {
      this.userDetails = result;
    });
  }

  ngOnInit(): void {
    this.themeEditorService.isDarkTheme$.subscribe((value) => {
      this.isDarkTheme = value;
    });

    this.themeEditorService.isCrmThemeEditor$.subscribe((value) => {
      this.isCrmThemeEditor = value;
    });
  }
}
