import { Injectable } from '@angular/core';
import { FeatureHeader } from '../../models/feature-header.model';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  featureHeaders: Array<FeatureHeader> = new Array<FeatureHeader>();

  constructor() {
    this.setFeatureHeaders();
  }

  setFeatureHeaders() {
    this.featureHeaders.push(new FeatureHeader('customer', 'Customers', 'Add New Customer'));
    this.featureHeaders.push(new FeatureHeader('user', 'Users', 'Add New User'));
    this.featureHeaders.push(new FeatureHeader('plan', 'Plans and Pricing', 'Add New Plan'));
    this.featureHeaders.push(
      new FeatureHeader('subscription', 'Subscription Management', 'Add New Subscription')
    );
    this.featureHeaders.push(
      new FeatureHeader('inventory', 'Inventory Management', 'Upload Inventory')
    );
    this.featureHeaders.push(new FeatureHeader('coupon', 'Coupons', 'Add New Coupon'));
    this.featureHeaders.push(new FeatureHeader('contactus', 'Contact Us', ''));
    this.featureHeaders.push(new FeatureHeader('support', 'Support', ''));
    this.featureHeaders.push(new FeatureHeader('adminSetting', 'Settings', ''));
    this.featureHeaders.push(new FeatureHeader('superAdminSetting', 'Settings', ''));
    this.featureHeaders.push(new FeatureHeader('revenueReport', 'Revenue Report', ''));
    this.featureHeaders.push(new FeatureHeader('subscriberReport', 'Subscribers Reports', ''));
    this.featureHeaders.push(new FeatureHeader('subscriptionReport', 'Subscription Reports', ''));
    this.featureHeaders.push(new FeatureHeader('dataUsage', 'Data Usage Report', ''));
    this.featureHeaders.push(new FeatureHeader('notifications', 'Notification Report', ''));
    this.featureHeaders.push(new FeatureHeader('products', 'Products', ''));
    this.featureHeaders.push(new FeatureHeader('products TRS', 'Products > TRS > Edit Theme', ''));
    this.featureHeaders.push(new FeatureHeader('adminDashboard', 'Dashboard', ''));
    this.featureHeaders.push(new FeatureHeader('agent', 'All Agents', 'Invite Agent'));
    this.featureHeaders.push(new FeatureHeader('loyalty', 'Loyalty Program', ''));
  }

  getFeatureHeader(featureName: string): FeatureHeader {
    const headerElement = this.featureHeaders.filter((header) => header.name === featureName)[0];

    return headerElement;
  }
}
