import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptDescryptService {
  constructor() {}

  encryptData(data: any) {
    const privateKey = environment.privateKey;
    const jsonString = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(jsonString, privateKey);
    return encrypted.toString();
  }

  decryptData(encryptedData: any) {
    const privateKey = environment.privateKey;
    const bytes = CryptoJS.AES.decrypt(encryptedData, privateKey);
    const jsonString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(jsonString);
  }
}
