import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  serverUrl = environment.serverUrl;

  constructor(private http: HttpClient) {}

  /**
   * POST Method: Sign in
   * @param userData
   * @returns
   */
  signin(userData: any) {
    return this.http.post(`${this.serverUrl}/auth/signin`, userData);
  }

  /**
   * POST Method: Sign up
   * @param userData
   * @returns
   */
  signup(userData: any) {
    return this.http.post(`${this.serverUrl}/auth/signin`, userData);
  }

  /**
   * POST Method: Forgot password
   * @param userData
   * @returns
   */
  forgotPassword(userData: any) {
    return this.http.post(`${this.serverUrl}/auth/forgot`, userData);
  }

  /**
   * GET Method: Resetting the password
   * @param token
   * @returns
   */
  checkLinkValidation(token: any) {
    return this.http.get(`${this.serverUrl}/auth/reset/${token}`);
  }

  /**
   * GET Method: to Validate the otp
   * @param userData
   * @returns
   */
  validateOTP(userData: any) {
    return this.http.get(`${this.serverUrl}/auth/reset/${userData.otp}/${userData.email}`);
  }

  /**
   * POST Method: Resetting the password
   * @param userData
   * @returns
   */
  resetPssword(userData: any) {
    return this.http.post(`${this.serverUrl}/auth/reset`, userData);
  }

  /**
   * POST Method: Generate otp
   * @param requestData
   * @returns
   */
  validateUser(requestData: any) {
    return this.http.post(`${this.serverUrl}/otp/generate`, requestData);
  }

  /**
   * POST Method: Verify use with otp
   * @param requestData
   * @returns
   */
  verifyUser(requestData: any) {
    return this.http.post(`${this.serverUrl}/otp/verify`, requestData);
  }
}
