import { Injectable } from '@angular/core';
import { AlertComponent } from '../dialog/alert/alert.component';
import { DialogService } from './dialog';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialogService: DialogService) {}

  /**
   * Error/Info Alert Component
   * @param message
   * @param statusCode
   */
  error(message: string | undefined, statusCode?: number) {
    let customTitle: string;

    if (statusCode && (statusCode == 401 || statusCode == 405)) {
      customTitle = 'Info';
    } else if (message == 'No data has been copied, so there is nothing to paste!') {
      customTitle = 'Error! No Data!';
    } else {
      customTitle = 'Error';
    }

    this.dialogService.openModal(AlertComponent, {
      cssClass: 'modal-sm',
      context: { title: customTitle, body: message, statusCode: statusCode },
    }).instance.close;
  }

  /**
   * Success Alert Component
   * @param message
   * @param type
   * @param customObj
   */
  success(message: string | undefined, type?: string, customObj?: any) {
    this.dialogService.openModal(AlertComponent, {
      cssClass: 'modal-sm',
      context: { title: 'Successful!', body: message, type: type, customObj: customObj },
    }).instance.close;
  }

  warning(message: string | undefined, type?: string, customObj?: any) {
    return this.dialogService.openModal(AlertComponent, {
      cssClass: 'modal-sm',
      context: { title: 'Warning!', body: message, type: type, customObj: customObj },
    }).instance.close;
  }
}
