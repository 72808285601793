import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimSpaces]',
})
export class TrimSpacesDirective {
  @Output() ngModelChange = new EventEmitter();

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  /**
   * Trim the input value on focus out of input component
   */
  @HostListener('focusout') onFocusOut() {
    (this.el.nativeElement as HTMLInputElement).value = (
      this.el.nativeElement as HTMLInputElement
    ).value.trim();
    this.ngModelChange.emit(this.el.nativeElement.value);
    this.control.control?.setValue(this.el.nativeElement.value);
  }
}

@Directive({
  selector: '[appAlphabetOnly]',
})
export class AlphabetOnlyDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const inputChar = event.key;
    const regex = /^[a-zA-Z]*$/;
    if (!regex.test(inputChar)) {
      event.preventDefault();
    }
  }
}
