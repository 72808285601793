import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appStatusColor]',
})
export class StatusColorDirective implements OnChanges {
  @Input('appStatusColor') textValue!: string;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['textValue']) {
      this.updateTextColor();
    }
  }

  /**
   * Apply the text color to the Contact & Support Request status based on the current status
   */
  private updateTextColor() {
    let color: string;

    switch (this.textValue) {
      case 'Pending':
        color = '#FFA600';
        break;
      case 'Resolved':
        color = '#149A4B';
        break;
      case 'In Progress':
        color = '#7F8D26';
        break;
      case 'Unresolved':
        color = '#FF323E';
        break;
      default:
        color = 'black';
        break;
    }

    this.elementRef.nativeElement.style.color = color;
  }
}
