import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AlertService } from '../../service';
import { DialogComponent } from '../../service/dialog';
import { SupportService } from '../../service/support.service';

@Component({
  selector: 'app-contact-support-info',
  templateUrl: './contact-support-info.component.html',
  styleUrls: ['./contact-support-info.component.scss'],
})
export class ContactSupportInfoComponent implements OnInit {
  dialogRef: DialogComponent;
  requestDetails: any;
  detailsRow: Array<any> = [
    { title: 'Ticket Number', key: 'ticket', customClass: '' },
    { title: 'Name', key: 'name', customClass: '' },
    { title: 'Email Address', key: 'email', customClass: '' },
    { title: 'Message', key: 'message', customClass: '' },
    { title: 'Status', key: 'status', customClass: '' },
  ];

  title!: string;
  copyText: string = 'Copy';
  inProgress: boolean = false;

  constructor(
    private viewContainer: ViewContainerRef,
    private supportService: SupportService,
    private alertService: AlertService
  ) {
    const _injector = this.viewContainer.injector;
    this.dialogRef = _injector.get<DialogComponent>(DialogComponent);
  }

  /**
   * @description Get Data from parent component
   */
  ngOnInit(): void {
    this.requestDetails = this.dialogRef.context?.data;
    this.title = this.dialogRef.context?.title;
    if (this.requestDetails?._id) {
      this.getRequestDetails();
    }
  }

  /**
   * @description Get contact detail info
   */
  getRequestDetails() {
    this.inProgress = true;
    this.supportService.getContactSupportRequestById(this.requestDetails?._id).subscribe({
      next: (result: any) => {
        if (result?._id) {
          this.requestDetails = result;
        }
        this.inProgress = false;
      },
      error: (err: any) => {
        this.alertService.error(err.error.message, err.status);
        this.inProgress = false;
      },
    });
  }

  /**
   * @description On status change
   *
   * @param value
   * @param data
   */
  onSelect(value: string, data: any) {
    const status = value;
    this.supportService.updateContactSupportRequestStatus(data._id, status).subscribe({
      next: (res: any) => {
        if (res) {
          this.dialogRef.close.emit(res);
        }
      },
      error: (err: any) => {
        this.alertService.error(err.error.message, err.status);
        this.inProgress = false;
      },
    });
  }

  /**
   * @description on close of modal emit event to parent component
   */
  close(): void {
    this.dialogRef.close.emit();
  }

  /**
   * @description Copy user email
   *
   * @param event
   * @param email
   * @returns
   */
  copyToClipboard(event: MouseEvent, email: string | undefined) {
    event.preventDefault();

    if (!email) {
      return;
    }
    navigator.clipboard.writeText(email);
  }
}
