import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ConfigurationService } from 'src/app/shared/service/configuration.service';
import { EncryptDescryptService } from 'src/app/shared/service/encrypt-descrypt.service';
import { LocalStorageService } from 'src/app/shared/service/local-storage.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  signinForm: any;
  showPassword: boolean = false;
  submitted = false;
  err = false;
  errMsg: string = '';
  configData: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private _localStorageService: LocalStorageService,
    private configurationService: ConfigurationService,
    private encryptDecryptService: EncryptDescryptService
  ) {
    configurationService.configDetails$.subscribe((data) => {
      this.configData = data;
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Initiate singin form
   */
  createForm() {
    this.signinForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      password: new UntypedFormControl(null, [Validators.required]),
    });
  }

  get f() {
    return this.signinForm.controls;
  }

  /**
   * Signin api call
   *
   * @returns
   */
  submit(): void {
    this.err = false;
    this.submitted = true;
    if (this.signinForm.invalid) {
      return;
    }

    const userData = {
      email: this.signinForm.get('email').value,
      password: this.signinForm.get('password').value,
    };
    if (this.configData) {
      this.signIn(userData);
    } else {
      this.retrieveConfigUsingEmail(userData);
    }
  }

  retrieveConfigUsingEmail(userData: any) {
    this.configurationService.fetchRegionAndConfigurationUsingEmail(userData.email).subscribe({
      next: (response: any) => {
        console.log(response);
        const { data, region } = response;
        const encryptedData = this.encryptDecryptService.encryptData(data);
        this._localStorageService.setCacheConfig(JSON.stringify(encryptedData));
        this._localStorageService.setRegion(region);
        this.configurationService.configDetails$.next(data);
        this.signIn(userData);
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  signIn(userData: any) {
    this.authService.signin(userData).subscribe({
      next: (res: any) => {
        this._localStorageService.setToken(res.token);
        window.location.href = '/';
      },
      error: (err) => {
        this.errMsg = err?.error?.message;
        this.err = true;
      },
    });
  }
}
